import { Page } from "utils/trpc";
import { NavigateAction } from "block-system/types";
import { config } from "@/config";

export async function navigateToPage(
  navigateAction: NavigateAction,
  pages: Page[],
  queryParamsData: Record<string, any>,
  currentWindowUrl = new URL(window.location.href)
) {
  const page = pages?.find((p: Page) => p.id === navigateAction.config?.pageId);
  if (!page) throw new Error("Missing page");

  const queryParams = new URLSearchParams(Object.entries(queryParamsData));
  const isEmbeddedPage = currentWindowUrl.pathname.includes("/_z/embed/page");
  if (navigateAction.config.sendQueryParams) {
    const url = `/${page.slug}?${queryParams.toString()}`;
    if (!isEmbeddedPage) {
      // TODO: INTRFCS-2690 window.location is a temporary fix. We need to find the route cause
      return window.location.assign(url);
    }

    return navigateToEmbeddedPage({
      page,
      queryParams,
      currentWindowUrl,
    });
  } else {
    if (!isEmbeddedPage) {
      // TODO: INTRFCS-2690 window.location is a temporary fix. We need to find the route cause
      return window.location.assign(`/${page.slug}`);
    }

    return navigateToEmbeddedPage({
      page,
      queryParams: new URLSearchParams(),
      currentWindowUrl,
    });
  }
}

function navigateToEmbeddedPage({
  page,
  queryParams,
  currentWindowUrl,
}: {
  page: Page;
  queryParams: URLSearchParams;
  currentWindowUrl: URL;
}) {
  const allowQueryParams =
    currentWindowUrl.searchParams.get("allowQueryParams");
  const baseUrl = config().NEXT_PUBLIC_INTERFACES_BASE_URL;

  const pageUrl = new URL(`/embed/page/${page.id}`, baseUrl);
  pageUrl.search = queryParams.toString();
  if (allowQueryParams) {
    pageUrl.searchParams.append("allowQueryParams", allowQueryParams);
  }

  // The pageUrl will have a different host, which is interfaces base url.
  // For external urls, it is preferred to use window.location instead of router.push
  window.location.assign(pageUrl);
}
