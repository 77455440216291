import { MouseEventHandler } from "react";
import Link from "next/link";
import { buttonVariants } from "@/block-system/brickz/components/ui/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardEmoji,
  CardFooter,
  CardTitle,
} from "@/block-system/brickz/components/ui/card";
import { cn } from "@/block-system/brickz/lib/utils";

export type LinksCardProps = {
  title: string;
  description?: string;
  linkText: string;
  emoji?: string;
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
};

export function ShadcnLinksCard({
  title,
  description,
  linkText,
  emoji,
  href,
  target,
  onClick,
}: LinksCardProps) {
  return (
    <Card className={cn("mx-0 w-auto min-w-[248px] max-w-[344px]")}>
      <CardContent>
        <div className="flex items-start gap-3">
          {emoji ? <CardEmoji>{emoji}</CardEmoji> : null}
          <div className="flex flex-col gap-0.5">
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
          </div>
        </div>
      </CardContent>
      <CardFooter className="p-4">
        <Link
          href={href}
          target={target}
          onClick={onClick}
          className={buttonVariants({
            variant: "outline",
            class: ["w-full", "bg-card", "border-border"],
          })}
        >
          {linkText}
        </Link>
      </CardFooter>
    </Card>
  );
}
