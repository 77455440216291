import { OpenExternalUrlAction } from "block-system/types";
import { sanitizeUrl } from "lib/hooks/useSafeUrl";

export function openExternalUrl(
  externalUrlAction: OpenExternalUrlAction,
  queryParamsData: Record<string, any>
) {
  let url = sanitizeUrl(externalUrlAction.config.url).sanitizedUrl;
  const target =
    externalUrlAction.config.opensInNewTab === false ? "_self" : "_blank";
  if (externalUrlAction.config.sendQueryParams) {
    url = `${url}?${new URLSearchParams(queryParamsData).toString()}`;
    window.open(url, target);
  } else {
    window.open(url, target);
  }
}
