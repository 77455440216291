import type { LinksCard as LinksCardType } from "./types";

import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";
import { create, copy, map } from "./operations";

export const LinksCard: LinksCardType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  isStandalone: false,
};
