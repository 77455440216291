import type { Links as LinksType } from "./types";

import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";
import { create, copy, map } from "./operations";

export const Links: LinksType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  isStandalone: true,
};
