import Skeleton from "react-loading-skeleton";
export function ChecklistSkeleton() {
  return (
    <ul
      className="flex flex-col gap-[20px]"
      data-testid="checklist-block-skeleton"
    >
      <li>
        <Skeleton height={70} />
      </li>
      <li>
        <Skeleton height={70} />
      </li>
      <li>
        <Skeleton height={70} />
      </li>
    </ul>
  );
}
