import { BlockCreateProps } from "../__shared__/types";
import { blockType } from "./constants";
import { ChecklistBlockSchema } from "./schema";
import { Checklist, ChecklistBlock } from "./types";

export function copy(block: ChecklistBlock): ChecklistBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: Checklist["create"] = (
  props?: BlockCreateProps<ChecklistBlock>
) => {
  return ChecklistBlockSchema.parse({
    type: blockType,
    config: props?.initialConfig ?? {},
  });
};

export const map: Checklist["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...ChecklistBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
