import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
import { Spinner } from "components/Spinner";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

export function BlockSpinner() {
  const interfacesTheme = useInterfacesTheme();
  const { isHidden } = useCurrentConsumerProject();
  return (
    <div
      className={cn(
        "mx-auto flex min-h-[250px] max-w-xl items-center justify-center p-12 shadow-subtle",
        {
          "rounded-xl bg-zi-white": !interfacesTheme,
          "rounded-large bg-card": interfacesTheme,
          "my-4 w-11/12": isHidden,
          "w-full": !isHidden,
        }
      )}
    >
      <Spinner />
    </div>
  );
}
