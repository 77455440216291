export interface PollingConfig {
  initialIntervalMs?: number;
  backoffFactor?: number;
  maxTries?: number;
  maxJitterMs?: number;
}

// Exponential backoff defaults. These meet the following criteria:
// - The first interval is 100ms.
// - Tries approximately 20 times in 1 minute.
const DEFAULT_CONFIG: Required<PollingConfig> = {
  initialIntervalMs: 100,
  backoffFactor: 1.258925412,
  maxTries: 20,
  maxJitterMs: 1000,
};

// Calculate the exponential backoff for polling the upload status.
export function getNextRefetch(
  count: number,
  config?: PollingConfig
): number | null {
  const { initialIntervalMs, backoffFactor, maxTries, maxJitterMs } = {
    ...DEFAULT_CONFIG,
    ...config,
  };

  // Stop polling after the max number of tries.
  if (count > maxTries) return null;

  // Calculate the exponential backoff.
  const calculatedInterval = initialIntervalMs * Math.pow(backoffFactor, count);

  // Add a random jitter of up to 25%, but no more than 1 second.
  const randomJitter =
    Math.min(maxJitterMs, calculatedInterval / 4) * Math.random();

  // Return the calculated interval with the random jitter, rounded down.
  return Math.floor(calculatedInterval + randomJitter);
}
