import { FileUploadWidget } from "@/block-system/brickz/components/ui/FileUploadWidget";
import { FileUploadWidget as FileUploadWidget_New } from "@/block-system/brickz/components/ui/FileUploadWidget_New";
import { config } from "@/config";
import {
  FileInfo,
  FilesUpload as FilesUploadType,
  FileUpload as FileUploadType,
  WidgetAPI,
} from "@uploadcare/react-widget";
import { allowedFileTypesConfigToArray } from "block-system/blocks/Form/Field/Editor/EditorFields/FileUploadFields";
import {
  useIsFileUploadsDisabled,
  useIsNewMultiUploaderEnabled,
} from "lib/context/split-context";
import { usePreviousCallback } from "lib/hooks";
import {
  usePollingUploadStatusQuery,
  usePollingUploadStatusQuery_New,
  useUploadSignatureQuery,
} from "lib/uploads/hooks";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormState } from "react-hook-form";
import {
  DISALLOWED_MIME_TYPES,
  FILE_EXTENSIONS_WHITELIST,
} from "server/services/uploads/FILE_UPLOAD_WHITELIST";

type FileUploadProps = {
  placeholder?: string;
  value?: FileInfo | null;
  allowedFileTypes?: string;
  onChange: (value?: FileInfo | FileInfo[]) => void;
  maxFileSize?: number;
  blockId: string;
  isEditing?: boolean;
  id: string;
  name: string;
  required: boolean;
  multiUploadConfig?: {
    allowedFileCount: number;
  };
  helpText?: React.ReactNode;
};

const FileUpload_Old = forwardRef<HTMLDivElement, FileUploadProps>(
  (props, ref) => {
    const fileUploadsDisabled = useIsFileUploadsDisabled();

    const widgetRef = useRef<WidgetAPI>(null);

    const [file, setFile] = useState<FileInfo | undefined>(undefined);
    const [fileSelected, setFileSelected] = useState(false);

    const { isSubmitSuccessful } = useFormState();

    useEffect(() => {
      if (isSubmitSuccessful && file) {
        setFile(undefined);
      }
    }, [file, isSubmitSuccessful]);

    const { data: uploadSignature, refetch: refetchUploadSignature } =
      useUploadSignatureQuery(
        {
          type: "formSubmission",
          blockId: props.blockId,
        },
        {
          enabled: !props.isEditing,
        }
      );

    const { data: uploadStatus, isError: isUploadStatusError } =
      usePollingUploadStatusQuery(file?.uuid);

    usePreviousCallback(uploadStatus, (prev, current) => {
      if (prev?.status !== "success" && current?.status === "success" && file) {
        const reformattedFile: FileInfo = {
          ...file,
          cdnUrl: current.url,
        };

        props.onChange(reformattedFile);
      }
    });

    const acceptTypes = useMemo(() => {
      const defaultAllowList = FILE_EXTENSIONS_WHITELIST.map(
        (ext) => `.${ext}`
      ).join(",");
      if (!props.allowedFileTypes) return defaultAllowList;
      const allowedFileTypes = allowedFileTypesConfigToArray(
        props.allowedFileTypes
      ).filter((ext) => ext !== "*");

      const mimeTypes = allowedFileTypes
        .filter((ext) => FILE_EXTENSIONS_WHITELIST.includes(ext))
        .map((ext) => `.${ext}`);

      return mimeTypes.length ? mimeTypes.join(",") : defaultAllowList;
    }, [props.allowedFileTypes]);

    const fileSizeValidator = useCallback(
      (fileInfo: FileInfo) => {
        if (
          !props.maxFileSize ||
          fileInfo.size === null ||
          fileInfo.sourceInfo?.source === "uploaded"
        ) {
          return;
        }

        if (fileInfo.size > props.maxFileSize) {
          throw new Error("fileMaximumSize");
        }
      },
      [props.maxFileSize]
    );

    const fileExtensionValidator = useCallback(
      (fileInfo: FileInfo) =>
        validateFile({ fileInfo, allowedFileTypes: props.allowedFileTypes }),
      [props.allowedFileTypes]
    );

    const onFileSelect = useCallback(
      (e: any) => {
        setFileSelected(e !== null);
        if (e !== null) {
          e.done((file: FileInfo) => {
            setFile(file);
          });
        } else {
          props.onChange(undefined);
        }
      },
      [props]
    );

    const reset = useCallback(() => {
      setFileSelected(false);
      onFileSelect(null);
      setFile(undefined);
      void refetchUploadSignature();
    }, [onFileSelect, refetchUploadSignature]);

    return (
      <FileUploadWidget
        {...props}
        ref={ref}
        file={file}
        fileSelected={fileSelected}
        widgetRef={widgetRef}
        uploadSignature={uploadSignature}
        uploadStatus={uploadStatus}
        isUploadStatusError={isUploadStatusError}
        acceptTypes={acceptTypes}
        fileSizeValidator={fileSizeValidator}
        fileExtensionValidator={fileExtensionValidator}
        onFileSelect={onFileSelect}
        reset={reset}
        fileUploadsDisabled={fileUploadsDisabled}
        publicKey={config().NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY}
      />
    );
  }
);

FileUpload_Old.displayName = "FileUpload";

const FileUpload_New = forwardRef<HTMLDivElement, FileUploadProps>(
  (props, ref) => {
    const fileUploadsDisabled = useIsFileUploadsDisabled();

    const widgetRef = useRef<WidgetAPI>(null);

    // We know we will get back an array because that is what we set with the onChange handler.
    // Types a bit wonky now. When we remove the old component, then NewFileUploadProps's value and onChange
    // will only deal with arrays of FileInfo.. Then the cast will be removed.
    const [files, setFiles] = useState<FileInfo[]>(
      (props.value as FileInfo[] | null) || []
    );

    const widgetKey = files?.map((f) => f.uuid).join("-") || null;

    const isMultiUpload = Boolean(props.multiUploadConfig);

    const maxFileCount = isMultiUpload
      ? (props?.multiUploadConfig?.allowedFileCount ?? 1)
      : 1;
    const remainingFileCount = maxFileCount - files.length;

    const { isSubmitSuccessful } = useFormState();

    useEffect(() => {
      if (isSubmitSuccessful && files.length > 0) {
        setFiles([]);
      }
    }, [files.length, isSubmitSuccessful]);

    const { data: uploadSignature, refetch: refetchUploadSignature } =
      useUploadSignatureQuery(
        {
          type: "formSubmission",
          blockId: props.blockId,
        },
        {
          enabled: !props.isEditing,
        }
      );

    const { data: uploadStatuses, isError: isUploadStatusError } =
      usePollingUploadStatusQuery_New(
        files.filter((f) => f.uuid).map((f) => f.uuid as string)
      );

    usePreviousCallback(uploadStatuses, (prev, current) => {
      if (!current || !files.length) {
        return props.onChange(undefined);
      }

      const previousSuccessfulFileCount = prev?.filter(
        (status) => status?.status === "success"
      ).length;

      const currentSuccessfulFiles = current.filter(
        (status) => status?.status === "success"
      );

      const moreFilesSuccessfulThanPrevious =
        currentSuccessfulFiles.length > (previousSuccessfulFileCount ?? 0);

      if (moreFilesSuccessfulThanPrevious) {
        const currentSuccessfulFilesWithUpdatedUrls = files.reduce(
          (acc: FileInfo[], file) => {
            const status = currentSuccessfulFiles.find(
              (s) => s.uuid === file.uuid
            );
            if (status) {
              acc.push({ ...file, cdnUrl: status.url });
            }
            return acc;
          },
          []
        );
        props.onChange(currentSuccessfulFilesWithUpdatedUrls);
      }
    });

    const acceptTypes = useMemo(() => {
      const defaultAllowList = FILE_EXTENSIONS_WHITELIST.map(
        (ext) => `.${ext}`
      ).join(",");
      if (!props.allowedFileTypes) return defaultAllowList;
      const allowedFileTypes = allowedFileTypesConfigToArray(
        props.allowedFileTypes
      ).filter((ext) => ext !== "*");

      const mimeTypes = allowedFileTypes
        .filter((ext) => FILE_EXTENSIONS_WHITELIST.includes(ext))
        .map((ext) => `.${ext}`);

      return mimeTypes.length ? mimeTypes.join(",") : defaultAllowList;
    }, [props.allowedFileTypes]);

    const fileSizeValidator = useCallback(
      (fileInfo: FileInfo) => {
        if (
          !props.maxFileSize ||
          fileInfo.size === null ||
          fileInfo.sourceInfo?.source === "uploaded"
        ) {
          return;
        }

        if (fileInfo.size > props.maxFileSize) {
          throw new Error("fileMaximumSize");
        }
      },
      [props.maxFileSize]
    );

    const fileExtensionValidator = useCallback(
      (fileInfo: FileInfo) =>
        validateFile({ fileInfo, allowedFileTypes: props.allowedFileTypes }),
      [props.allowedFileTypes]
    );
    const onFileSelect = useCallback(
      // For unknown reasons, uploadcare expects the on select handler to be able to handle single and multiple uploads
      // Even if you explicitly specify the widget to be a multiple uploader.
      async (e: FileUploadType | FilesUploadType | null) => {
        try {
          if (e === null) {
            setFiles([]);
            props.onChange(undefined);
            return;
          }

          if ("files" in e) {
            const results = await Promise.allSettled(e.files());
            const successfulFiles = results.reduce(
              (acc: FileInfo[], result) => {
                if (result.status === "fulfilled") {
                  acc.push(result.value);
                } else {
                  console.error(`File upload failed:`, JSON.stringify(result));
                }
                return acc;
              },
              []
            );

            if (successfulFiles.length > 0) {
              setFiles((prev) => [...prev, ...successfulFiles]);
            }
          } else {
            try {
              const fileInfo = await Promise.resolve(e);
              setFiles((prev) => [...prev, fileInfo]);
            } catch (singleFileError) {
              console.error("Single file upload failed:", singleFileError);
              throw singleFileError; // Re-throw to be caught by outer try-catch
            }
          }
        } catch (error) {
          console.error("Error in onFileSelect:", error);
          void refetchUploadSignature();
        }
      },
      [props, refetchUploadSignature]
    );

    const onRemoveFile = useCallback(
      (file: FileInfo) => {
        setFiles((prev) => prev.filter((f) => f.uuid !== file.uuid));
        void refetchUploadSignature();
      },
      [refetchUploadSignature]
    );

    return (
      <FileUploadWidget_New
        {...props}
        randomWidgetKey={widgetKey}
        ref={ref}
        files={files}
        widgetRef={widgetRef}
        uploadSignature={uploadSignature}
        uploadStatuses={uploadStatuses}
        isUploadStatusError={isUploadStatusError}
        acceptTypes={acceptTypes}
        fileSizeValidator={fileSizeValidator}
        fileExtensionValidator={fileExtensionValidator}
        onFileSelect={onFileSelect}
        onRemoveFile={onRemoveFile}
        fileUploadsDisabled={fileUploadsDisabled}
        publicKey={config().NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY}
        remainingFileCount={remainingFileCount}
        helpText={props.helpText}
      />
    );
  }
);

FileUpload_New.displayName = "FileUpload_New";

export const FileUpload = forwardRef<HTMLDivElement, FileUploadProps>(
  (props, ref) => {
    const isNewMultiUploaderEnabled = useIsNewMultiUploaderEnabled();
    if (isNewMultiUploaderEnabled) {
      return <FileUpload_New {...props} ref={ref} />;
    }

    return <FileUpload_Old {...props} ref={ref} />;
  }
);

FileUpload.displayName = "FileUpload";

export function validateFile({
  fileInfo,
  allowedFileTypes,
}: {
  fileInfo: FileInfo;
  allowedFileTypes: FileUploadProps["allowedFileTypes"];
}) {
  if (fileInfo.sourceInfo?.source === "uploaded") {
    return;
  }

  const ext = fileInfo.name?.split(".").pop()?.toLowerCase();
  const isDisabledMimeType = DISALLOWED_MIME_TYPES.includes(
    fileInfo.mimeType ?? ""
  );

  const computedAllowedFileTypes = allowedFileTypes
    ? allowedFileTypesConfigToArray(allowedFileTypes).filter(
        (ext) => ext !== "*"
      )
    : FILE_EXTENSIONS_WHITELIST;

  if (!ext || !computedAllowedFileTypes.includes(ext) || isDisabledMimeType) {
    throw new Error("fileType");
  }
}
