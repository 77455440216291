import { useState } from "react";
import { Step1SendEmail } from "./Step1SendEmail";
import { Step2CheckOTP } from "./Step2CheckOTP";

type Step = "send-email" | "check-otp";

export function ManagedConsumerAuthForm(props: {
  projectId: string;
  onAuthSuccess: () => void;
  captchaEnabled: boolean;
  isEditing: boolean;
}) {
  const [step, setStep] = useState<Step>("send-email");
  const [loginError, setLoginError] = useState<string | null>(null);

  const onFail = (msg: string) => {
    setStep("send-email");
    setLoginError(msg);
  };

  switch (step) {
    case "send-email":
      return (
        <Step1SendEmail
          projectId={props.projectId}
          captchaEnabled={props.captchaEnabled}
          loginError={loginError}
          next={() => {
            setStep("check-otp");
          }}
          isEditing={props.isEditing}
        />
      );

    case "check-otp":
      return (
        <Step2CheckOTP
          projectId={props.projectId}
          next={() => props.onAuthSuccess()}
          back={() => setStep("send-email")}
          onFail={onFail}
        />
      );

    default:
      const _exhaustiveCheck: never = step;
      return _exhaustiveCheck;
  }
}
