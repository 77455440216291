import { useCallback, useMemo } from "react";
import { AccountPlan } from "server/schemas/user";

type User = {
  isStaff?: boolean;
  planName?: AccountPlan;
};

export const isAdvancedPlan = (user: User | undefined) => {
  if (!user) return false;
  return user.planName === "advanced";
};

export const isPremiumPlan = (user: User | undefined) => {
  if (!user) return false;
  return user.planName === "premium";
};

export const isFreePlan = (user: User | undefined) => {
  if (!user) return false;
  return user.planName === "free" && !user.isStaff;
};

export function useAccountPlan(
  user:
    | {
        isStaff?: boolean;
        planName?: AccountPlan;
      }
    | undefined
) {
  const isAdvanced = useCallback(() => isAdvancedPlan(user), [user]);
  const isPremium = useCallback(() => isPremiumPlan(user), [user]);
  const isFree = useCallback(() => isFreePlan(user), [user]);

  const plan: AccountPlan | undefined = useMemo(() => {
    if (isAdvancedPlan(user)) return "advanced";

    if (isPremiumPlan(user)) return "premium";

    if (isFreePlan(user)) return "free";

    return "free";
  }, [user]);

  return {
    planName: plan,
    isAdvanced,
    isPremium,
    isFree,
  };
}
