import BlockWrapper from "block-system/components/BlockWrapper";
import { Button } from "./Button";
import { BlockProps } from "../../__shared__/types";
import type { ButtonBlock as ButtonBlockType } from "../types";
import { useProjectTheme } from "lib/hooks/useProjectTheme";

export const ButtonBlock = (props: BlockProps<ButtonBlockType>) => {
  const theme = useProjectTheme();
  const maxWidth = props.block.config.style?.width
    ? theme.app.width[props.block.config.style?.width]
    : theme.app.width.narrow;

  return (
    <BlockWrapper
      block={props.block}
      blockId={props.blockId}
      maxWidth={maxWidth}
      alignment={props.block.config.style?.alignment}
    >
      <Button {...props.block.config} blockId={props.blockId} />
    </BlockWrapper>
  );
};
