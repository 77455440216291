import { StripeConnectedAccountStatus } from "@prisma/client";

export function getStripeOnboardingReturnUrl(baseUrl: string) {
  return `${baseUrl}/stripe-connect/return`;
}

export function getStripeOnboardingRefreshUrl(baseUrl: string) {
  return `${baseUrl}/stripe-connect/refresh`;
}

export function getStripeUnlinkAccountUrl() {
  return "https://dashboard.stripe.com/settings/linked-accounts";
}

export function getStripeDashboardUrlForAccount({
  stripeAccountId,
}: {
  stripeAccountId: string;
}) {
  /**
   * We derived this URL from the Stripe dashboard.
   * 1. Go to the Stripe dashboard.
   * 2. Click on the "settings" cog in the top right.
   * 3. Click on "Settings" in the menu.
   * 4. Click on "Profile" under "Business settings".
   * 5. Scroll down to the "Accounts" section.
   * 6. In the table, scroll to the very right and notice the "..." menu.
   * 7. Click on that menu and select "Copy URL" from that menu.
   *
   * There is also a notion of "remediation links"
   * https://docs.stripe.com/connect/dashboard/remediation-links
   * But you can't generate those via API (see the page above).
   */
  return `https://dashboard.stripe.com/b/${stripeAccountId}`;
}

export function stripeConnectedAccountHasChargesEnabled(
  status: StripeConnectedAccountStatus
) {
  return (
    status === StripeConnectedAccountStatus.Enabled ||
    status === StripeConnectedAccountStatus.NeedsMoreInfo
  );
}
