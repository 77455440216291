import {
  ConditionalLogicGrouping,
  ConditionalLogicGroupingOperators,
  FieldBlockConditionalLogicItem,
} from "../../schema";

function getOperators(grouping: ConditionalLogicGrouping) {
  const operators: ConditionalLogicGroupingOperators = [];
  (["and", "or"] as const).forEach((operator) => {
    if (grouping[operator]) {
      operators.push(operator);
      grouping[operator]?.forEach((item) => {
        if (typeof item !== "number") {
          operators.push(...getOperators(item));
        }
      });
    }
  });
  return operators;
}

export function getOperatorsFromGrouping(
  grouping: ConditionalLogicGrouping
): ConditionalLogicGroupingOperators {
  // Reverse is needed here since we built the array from the bottom up
  return [...getOperators(grouping)].reverse();
}

export function groupConditions(
  conditions: FieldBlockConditionalLogicItem[],
  ops: ConditionalLogicGroupingOperators
) {
  if (conditions.length <= 1 || ops.length < 1) return;
  const groupings = ops.reduce((acc, op, index) => {
    if (index === 0) {
      return {
        [op]: [index, index + 1],
      };
    }
    return {
      [op]: [acc, index + 1],
    };
  }, {});
  return groupings;
}
