import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import { blockType } from "./constants";
import { MarkdownTextBlockSchema } from "./schema";
import { MarkdownText, MarkdownTextBlock } from "./types";

export function copy(block: MarkdownTextBlock): MarkdownTextBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: MarkdownText["create"] = (
  props?: BlockCreateProps<MarkdownTextBlock>
) => {
  const defaultConfig = {
    content:
      "# Sign up for a demo\n\nSubmit the form below. Once submitted, you'll receive a calendar invite for a 30-minute demonstration.",
    textAlign: "center",
    style: {
      width: "narrow",
      alignment: "center",
    },
  };

  return MarkdownTextBlockSchema.parse({
    type: blockType,
    config: {
      ...defaultConfig,
      ...props?.initialConfig,
    },
  });
};

export const map: MarkdownText["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...MarkdownTextBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
