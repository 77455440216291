import { cn } from "@/block-system/brickz/lib/utils";
import { ComponentBlockStyle } from "@/block-system/types";
import { cva } from "class-variance-authority";

export type LinksProps = {
  title?: string;
  children: React.ReactNode;
  width: ComponentBlockStyle["width"];
  isEditing: boolean;
};

export function ShadcnLinks({
  title,
  width = "medium",
  isEditing,
  children,
}: LinksProps) {
  return (
    <section className="flex flex-col gap-5">
      {title ? (
        <h2 className="text-3xl font-extrabold text-foreground">{title}</h2>
      ) : null}
      <div
        className={cn(widthVariants({ width }), {
          "ml-[-10px]": isEditing,
        })}
      >
        {children}
      </div>
    </section>
  );
}

const widthVariants = cva("grid w-full grid-cols-1 justify-center gap-5", {
  variants: {
    width: {
      narrow: "sm:grid-cols-[repeat(2,minmax(248px,344px))]",
      medium:
        "sm:grid-cols-[repeat(2,minmax(248px,344px))] lg:grid-cols-[repeat(3,minmax(248px,344px))]",
      wide: "sm:grid-cols-[repeat(2,minmax(248px,344px))] lg:grid-cols-[repeat(3,minmax(248px,344px))]",
      full: "sm:grid-cols-[repeat(2,minmax(248px,344px))] lg:grid-cols-[repeat(3,minmax(248px,344px))]",
    },
  },
  defaultVariants: {
    width: "medium",
  },
});
