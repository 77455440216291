import { RichTextStyleWrapper } from "block-system/components/RichTextStyleWrapper";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import rehypeHighlight from "rehype-highlight";

export function Markdown(props: {
  openInSameTab?: boolean;
  children: string;
  className?: string;
  components?: ReactMarkdownOptions["components"];
}) {
  return (
    <RichTextStyleWrapper>
      <ReactMarkdown
        className={props.className}
        linkTarget={props.openInSameTab ? "_self" : "_blank"}
        rehypePlugins={[rehypeHighlight]}
        components={{
          ...(props.components ?? {}),
        }}
      >
        {props.children}
      </ReactMarkdown>
    </RichTextStyleWrapper>
  );
}
