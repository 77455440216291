import { LayoutAllowedItemTypes, LayoutItem } from "../schema";
import { LayoutBlock } from "../types";
import { LayoutItemColumnSpan } from "../Block/constants";
import { getImagesForBlock } from "block-system/blocks/__shared__/images";

function isItemOfColSpan2(item: LayoutItem, maxColumnCount: number) {
  return Boolean(
    item.block && getLayoutItemColSpan(item.block.type, maxColumnCount) === 2
  );
}

export function getOrderedLayoutItems(block: LayoutBlock): LayoutItem[] {
  const layoutItemsWithoutPlaceholder: LayoutItem[] = block.children
    .map((item, index) => ({
      name: getImagesForBlock(item.type).thumbnail.name,
      block: item,
      index,
      order: block.config.ordering[item.config.id as string],
    }))
    .sort((a, b) => a.order - b.order);

  const layoutItems: LayoutItem[] = [];

  const hasBlockSpanningTwoColumns = layoutItemsWithoutPlaceholder.some(
    (layoutItem) => isItemOfColSpan2(layoutItem, block.config.columnCount)
  );

  const layoutItemsCount = hasBlockSpanningTwoColumns
    ? block.config.columnCount - 1
    : block.config.columnCount;

  for (let i = 0; i < layoutItemsCount; i++) {
    const item = layoutItemsWithoutPlaceholder.find(
      (item) =>
        item.order === i ||
        (item.order === block.config.columnCount &&
          block.config.columnCount === 2)
    );
    if (item) {
      layoutItems.push(item);
    } else {
      layoutItems.push({ name: "Empty", block: null, index: i, order: i });
    }
  }
  return layoutItems;
}

export function getLayoutItemColSpan(
  blockType: LayoutAllowedItemTypes,
  maxColumnCount: number
) {
  if (maxColumnCount === 3) {
    return LayoutItemColumnSpan[blockType].threeColumn;
  }
  return LayoutItemColumnSpan[blockType].twoColumn;
}

export const INVALID_LAYOUT_ITEM_INSERT_POSITION = -1;

export function getLayoutItemInsertPosition(
  block: LayoutBlock,
  insertBlockType: LayoutAllowedItemTypes,
  insertPosition: number
) {
  const currentOrdering = block.config.ordering;
  const currentOrderingIndices = Object.values(currentOrdering);
  const maxColumnCount = block.config.columnCount;

  if (
    insertPosition < 0 ||
    insertPosition >= maxColumnCount ||
    currentOrderingIndices.includes(insertPosition)
  ) {
    return INVALID_LAYOUT_ITEM_INSERT_POSITION;
  }

  const itemColSpan = getLayoutItemColSpan(insertBlockType, maxColumnCount);
  // If itemSize is 1, check if insertPosition is unoccupied
  if (itemColSpan === 1 && !currentOrderingIndices.includes(insertPosition)) {
    return insertPosition;
  }

  if (itemColSpan === 2) {
    // We need to get the column span of previous item (if it exists) to make sure
    // that the previous item does not occupy the insertPosition.
    const prevItemId = Object.entries(currentOrdering).find(
      ([, order]) => order === insertPosition - 1
    )?.[0];
    const prevItem = block.children.find(
      (item) => item.config.id === prevItemId
    );
    const isPrevItemColSpan2 = prevItem
      ? getLayoutItemColSpan(prevItem.type, maxColumnCount) === 2
      : false;

    const hasAvailableSpaceToTheRight =
      insertPosition + 1 < maxColumnCount &&
      !currentOrderingIndices.includes(insertPosition + 1) &&
      !isPrevItemColSpan2;

    const hasAvailableSpaceToTheLeft =
      insertPosition - 1 >= 0 &&
      !currentOrderingIndices.includes(insertPosition - 1);

    if (hasAvailableSpaceToTheRight) {
      return insertPosition;
    } else if (hasAvailableSpaceToTheLeft) {
      return insertPosition - 1;
    }
  }

  return INVALID_LAYOUT_ITEM_INSERT_POSITION;
}

export function getAllowedItemsForPosition(
  block: LayoutBlock,
  position: number
): LayoutAllowedItemTypes[] {
  const allowedItems = Object.keys(LayoutItemColumnSpan).filter((item) => {
    return (
      getLayoutItemInsertPosition(
        block,
        item as LayoutAllowedItemTypes,
        position
      ) !== INVALID_LAYOUT_ITEM_INSERT_POSITION
    );
  });
  return allowedItems as LayoutAllowedItemTypes[];
}
