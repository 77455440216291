import { filterUnsupportedTableFields } from "./lib/filterUnsupportedTableFields";
import { allowed } from "./lib/allowed";

import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";

import type { Table as TableType } from "./types";
import { create, copy, map } from "./operations";

export const Table: TableType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  filterUnsupportedTableFields,
  allowed,
  create,
  map,
  copy,
  isStandalone: true,
} as const;
