import { Badge } from "@/block-system/brickz/components/ui/badge";
import { Button } from "@/block-system/brickz/components/ui/Button";
import {
  Popover,
  PopoverTrigger,
} from "@/block-system/brickz/components/ui/popover";
import { cn } from "@/block-system/brickz/lib/utils";
import {
  Check as CheckIcon,
  ChevronDown as ChevronDownIcon,
  X as XIcon,
} from "lucide-react";
import {
  forwardRef,
  Fragment,
  useId,
  useState,
  useSyncExternalStore,
} from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { PopoverContent } from "./popover";

export type Option = {
  label: string;
  value: string;
};

export type Props = {
  value?: string | string[] | null;
  placeholder?: string;
  options: Option[];
  multiSelect?: boolean;
  isErrored?: boolean;
  onChange?: (newValue: string | string[] | null) => void;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  id?: string;
};

export const DropdownSelect = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      placeholder,
      options,
      multiSelect,
      isErrored,
      onChange,
      isDisabled,
      className,
      isRequired,
      id: providedId,
    },
    ref
  ) => {
    /**
     * Problem:
     * The `Select` components, by default, render their contents in a portal that is attached to the body.
     * This is problematic in the context of rendering those components inside modals.
     *
     * Solution:
     * Make the `Select` components render their contents inside a custom portal container.
     *
     * Problem:
     * Using `refs` to get the portal container does not work, because `refs` are assigned AFTER the component renders.
     *
     * Solution:
     * Use a `useSyncExternalStore` hook to get the portal container DOM element in a synchronous manner.
     *
     * This ID will be used in `useSyncExternalStore` in a given "Select" component.
     * We are rendering it here, to ensure element is defined before the "Select" component tries to render.
     */
    const containerId = useId();

    const generatedId = useId();
    const id = providedId ?? generatedId;

    return (
      <Fragment>
        <div id={containerId} />
        {multiSelect ? (
          <DropdownMultiSelect
            id={id}
            options={options}
            /**
             * We have to parse the value here to ensure we pass the right value type into the component.
             *
             * This is essential in situations where user toggles the "Allow multiple selections" toggle.
             * Toggling from "off" to "on" switches the `multiSelect` value to be `true`,
             * but it will NOT update the `value` we get as a prop to this component.
             *
             * This is because the configuration panel is disjointed from the place we render this component – the form block.
             * The form block uses `useForm` with `defaultValues`. These `defaultValues` are cached first time component mounts.
             * When the toggle changes, we have no way to "reset" those `defaultValues`.
             *
             */
            value={parseMultiSelectValue({ value })}
            onChange={(newValues) => {
              onChange?.(newValues);
            }}
            isErrored={isErrored}
            isDisabled={isDisabled}
            className={className}
            placeholder={placeholder}
            ref={ref}
            portalContainerId={containerId}
          />
        ) : (
          <DropdownSingleSelect
            id={id}
            options={options}
            /**
             * We have to parse the value here to ensure we pass the right value type into the component.
             *
             * This is essential in situations where user toggles the "Allow multiple selections" toggle.
             * Toggling from "on" to "of" switches the `multiSelect` value to be `false`,
             * but it will NOT update the `value` we get as a prop to this component.
             *
             * This is because the configuration panel is disjointed from the place we render this component – the form block.
             * The form block uses `useForm` with `defaultValues`. These `defaultValues` are cached first time component mounts.
             * When the toggle changes, we have no way to "reset" those `defaultValues`.
             *
             */
            value={parseSingleSelectValue({ value })}
            onChange={onChange}
            placeholder={placeholder}
            isRequired={isRequired}
            isErrored={isErrored}
            isDisabled={isDisabled}
            className={className}
            ref={ref}
            portalContainerId={containerId}
          />
        )}
      </Fragment>
    );
  }
);

DropdownSelect.displayName = "DropdownSelect";

function parseMultiSelectValue({ value }: { value: Props["value"] }) {
  if (Array.isArray(value)) {
    return value;
  }

  if (value == null) {
    return [];
  }

  if (typeof value === "string") {
    if (value.trim() === "") {
      return [];
    }

    return [value];
  }

  return [value];
}

function parseSingleSelectValue({ value }: { value: Props["value"] }) {
  const providedValue = Array.isArray(value) ? value[0] : value;
  /**
   * We have to use `""` here as fallback.
   * This will indicate to `SelectValue` to display the `placeholder` prop.
   */
  const emptySelectValue = "";
  if (!providedValue) {
    return emptySelectValue;
  }

  if (providedValue.trim() === "") {
    return emptySelectValue;
  }

  return providedValue;
}

interface MultiSelectProps {
  options: { label: string; value: string }[];
  value: string[];
  onChange?: (newValue: string[]) => void;
  className?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  placeholder?: string;
  portalContainerId: string;
  id: string;
  ariaLabel?: string;
  name?: string;
}

const DropdownMultiSelect = forwardRef<HTMLButtonElement, MultiSelectProps>(
  (
    {
      options,
      value: values,
      onChange,
      className,
      isDisabled,
      isErrored,
      placeholder,
      portalContainerId,
      ariaLabel,
      id,
      name,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const selectedValueLabels = values
      .map((value) => {
        const option = options.find((o) => o.value === value);
        return option?.label;
      })
      .filter((label) => label !== undefined);

    const handleUnselect = (item: string) => {
      onChange?.(values.filter((i) => i !== item));
    };

    const handleSelect = (item: string) => {
      const isAddingItem = !values.includes(item);
      if (isAddingItem) {
        onChange?.([...values, item]);
      } else {
        onChange?.(values.filter((i) => i !== item));
      }
    };

    const selectContainer = useSyncExternalStore(
      () => {
        return () => {};
      },
      () => {
        return document.getElementById(portalContainerId);
      },
      () => null
    );

    return (
      <Fragment>
        <Popover open={open} onOpenChange={setOpen} modal={false}>
          <PopoverTrigger asChild>
            <Button
              ref={ref}
              id={id}
              name={name}
              variant="outline"
              role="combobox"
              aria-label={ariaLabel}
              disabled={isDisabled}
              aria-expanded={open}
              className={cn(
                "w-full",
                "bg-transparent",
                "hover:bg-transparent",
                "border border-solid border-input",
                "data-[errored=true]:border-destructive",
                "disabled:bg-transparent",
                "px-3 py-2.5",
                {
                  /**
                   * Make sure to expand the height of the button when we render multiple badges.
                   */
                  "h-fit": values.length > 0,
                  "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
                    isDisabled,
                  "border-destructive": isErrored,
                }
              )}
              onClick={() => setOpen(!open)}
            >
              <div className={"flex w-full"}>
                <DropdownMultiSelectBadgeList
                  value={selectedValueLabels}
                  handleUnselect={handleUnselect}
                  placeholder={placeholder}
                />
                <ChevronDownIcon className="ml-auto h-4 w-4 text-foreground" />
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-full p-0"
            align={"start"}
            container={selectContainer}
          >
            <DropdownMultiSelectOptions
              className={className}
              options={options}
              handleSelect={handleSelect}
              value={values}
            />
          </PopoverContent>
        </Popover>
      </Fragment>
    );
  }
);

function DropdownMultiSelectBadgeList({
  value,
  handleUnselect,
  placeholder,
}: {
  value: string[];
  handleUnselect: (item: string) => void;
  placeholder: string | undefined;
}) {
  const hasItems = value.length > 0;
  const hasPlaceholder = placeholder != null;

  if (!hasItems) {
    if (!hasPlaceholder) {
      return null;
    }

    return (
      <span className="text-sm font-normal text-muted-foreground">
        {placeholder}
      </span>
    );
  }

  return (
    // Rendering an `ul` and `li` inside a `button` would be an invalid HTML.
    // That is why we are using a `div` here.
    <div className="flex flex-wrap gap-1">
      {value.map((item) => {
        return (
          <Badge
            key={item}
            /**
             * To make sure the `button` renders at 40 px height.
             */
            className={"h-[1.125rem] rounded-[2px] bg-foreground pl-2 pr-1"}
            onClick={() => handleUnselect(item)}
            data-testid="multiselect-selected-item"
          >
            {item}
            <span
              tabIndex={0}
              className="ml-2 outline-none ring-offset-background focus:ring-2 focus:ring-foreground focus:ring-offset-2"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUnselect(item);
                }
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleUnselect(item);
              }}
            >
              <XIcon className="h-3 w-3 hover:text-foreground" />
            </span>
          </Badge>
        );
      })}
    </div>
  );
}

function DropdownMultiSelectOptions({
  className,
  options,
  handleSelect,
  value,
}: {
  className?: string;
  options: { label: string; value: string }[];
  handleSelect: (item: string) => void;
  value: string[];
}) {
  return (
    <Command className={cn(className)}>
      <CommandInput placeholder="Search..." />
      <CommandEmpty>No options</CommandEmpty>
      <CommandList>
        <CommandGroup className="max-h-64 overflow-auto">
          {options.map((option) => {
            return (
              <CommandItem
                key={option.value}
                onSelect={() => handleSelect(option.value)}
              >
                <CheckIcon
                  className={cn(
                    "mr-2 h-4 w-4",
                    value.includes(option.value) ? "opacity-100" : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

DropdownMultiSelect.displayName = "DropdownMultiSelect";

type SingleSelectProps = {
  value: string;

  onChange?: (newValue: string | null) => void;
  options: { label: string; value: string }[];

  placeholder?: string;
  isErrored?: boolean;
  isRequired?: boolean;

  size?: "small" | "medium";
  ariaLabel?: string;

  isDisabled?: boolean;
  className?: string;

  portalContainerId: string;

  id: string;
};

export const DropdownSingleSelect = forwardRef<
  HTMLButtonElement,
  SingleSelectProps
>(
  (
    {
      value,
      onChange,
      options,
      placeholder,
      isErrored,
      isDisabled,
      className,
      portalContainerId,
      isRequired,
      ariaLabel,
      id,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const selectContainer = useSyncExternalStore(
      () => {
        return () => {};
      },
      () => {
        return document.getElementById(portalContainerId);
      },
      () => null
    );

    const emptyOptionValue = useId();
    const handleOnSelect = (newValue: string) => {
      const valueToPass = newValue === emptyOptionValue ? null : newValue;
      onChange?.(valueToPass);
      setOpen(false);
    };

    const hasValue = value != null && value != "" && value != emptyOptionValue;
    const renderClearSelection = hasValue && !isRequired;

    return (
      <Popover open={open} onOpenChange={setOpen} modal={false}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={id}
            variant="outline"
            role="combobox"
            disabled={isDisabled}
            aria-expanded={open}
            aria-label={ariaLabel}
            className={cn(
              "w-full",
              "bg-transparent",
              "hover:bg-transparent",
              "hover:text-inherit",
              "border border-solid border-input",
              "data-[errored=true]:border-destructive",
              "disabled:bg-transparent",
              "px-3 py-2.5",
              {
                "cursor-not-allowed opacity-50 [&_*]:cursor-not-allowed":
                  isDisabled,
                "border-destructive": isErrored,
              }
            )}
            onClick={() => setOpen(!open)}
          >
            <div className="flex items-center gap-2">
              <DropdownSingleSelectValue
                placeholder={placeholder}
                options={options}
                value={value}
              />
              <div className="ml-auto flex items-center gap-2">
                {renderClearSelection ? (
                  <ClearSelection
                    onClearSelection={() => {
                      return handleOnSelect(emptyOptionValue);
                    }}
                  />
                ) : null}
                <ChevronDownIcon className="h-4 w-4 text-foreground" />
              </div>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-full p-0"
          align={"start"}
          container={selectContainer}
        >
          <DropdownSingleSelectOptions
            options={options}
            className={className}
            value={value}
            handleOnSelect={handleOnSelect}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

const ClearSelection = ({
  onClearSelection,
}: {
  onClearSelection: () => void;
}) => {
  return (
    <div
      tabIndex={0}
      onClick={(event) => {
        event.stopPropagation();
        onClearSelection();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          onClearSelection();
        }
      }}
    >
      <span className="sr-only">Clear selection</span>
      <XIcon className="h-3 w-3 rounded-full bg-foreground p-0.5 text-slate-50" />
    </div>
  );
};

DropdownSingleSelect.displayName = "DropdownSingleSelect";

function DropdownSingleSelectValue({
  options,
  value,
  placeholder,
}: {
  options: Option[];
  value: string;
  placeholder?: string;
}) {
  const pickedValue = options.find((option) => option.value === value);
  if (pickedValue) {
    return <span className="truncate">{pickedValue.label}</span>;
  }

  if (placeholder == null) {
    return null;
  }

  return (
    <span className="text-sm font-normal text-muted-foreground">
      {placeholder}
    </span>
  );
}

function DropdownSingleSelectOptions({
  options,
  className,
  handleOnSelect,
  value,
}: {
  options: Option[];
  className?: string;
  value: SingleSelectProps["value"];
  handleOnSelect: (newValue: string) => void;
}) {
  return (
    <Command className={className}>
      <CommandInput placeholder="Search..." />
      <CommandList>
        <CommandEmpty>No options</CommandEmpty>
        <CommandGroup className="max-h-64 overflow-auto">
          {options.map((option) => {
            return (
              <DropdownSingleSelectOption
                key={option.value}
                value={value}
                option={option}
                onSelect={handleOnSelect}
              />
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

function DropdownSingleSelectOption({
  value,
  option,
  onSelect,
}: {
  value: string;
  option: Option;
  onSelect: (newValue: string) => void;
}) {
  const checked = option.value === value;

  return (
    <CommandItem
      value={option.value}
      keywords={[option.label]}
      onSelect={() => {
        onSelect(option.value);
      }}
    >
      <CheckIcon
        className={cn("mr-2 h-4 w-4", {
          "opacity-100": checked,
          "opacity-0": !checked,
        })}
      />
      {option.label}
    </CommandItem>
  );
}
