import { getPageUrl } from "lib/route-helpers";
import { PageWithContent } from "server/routers/pages";
import { NavigationPageItem } from "server/schemas/pages";
import { ParsedProject } from "server/schemas/projects";

export function getNavigationPageItemForPage(
  page: Omit<PageWithContent, "content" | "createdAt" | "updatedAt">,
  project?: Pick<ParsedProject, "homepageId" | "slug" | "customDomain">,
  isEmbed?: boolean
): NavigationPageItem {
  const url = isEmbed
    ? `/_z/embed/page/${page.id}`
    : getPageUrl(project?.slug as string, page.slug, project?.customDomain);

  return {
    id: page.id,
    isHomepage: project?.homepageId === page.id,
    title: page.title ?? page.name,
    url,
  };
}

export function navigationItemsForPages(
  pages: Omit<PageWithContent, "content" | "createdAt" | "updatedAt">[],
  project?: Pick<ParsedProject, "homepageId" | "slug" | "customDomain">,
  isEmbed?: boolean
): Record<string, NavigationPageItem> {
  return pages.reduce(
    (acc, page) => ({
      ...acc,
      [page.id]: getNavigationPageItemForPage(page, project, isEmbed),
    }),
    {}
  );
}
