import { hasAccessToFeature } from "lib/utils/features";
import { PaidFeature } from "server/auth/types";

export const checkFeature = (
  feature: PaidFeature,
  user:
    | {
        isStaff?: boolean;
        paidFeatureAccess?: PaidFeature[];
      }
    | undefined
) => {
  if (!user) return false;
  return hasAccessToFeature(feature, user.paidFeatureAccess);
};

export function useFeatureAccessCheck(
  feature: PaidFeature,
  user:
    | {
        isStaff?: boolean;
        paidFeatureAccess?: PaidFeature[];
      }
    | undefined
) {
  return checkFeature(feature, user);
}
