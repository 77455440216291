import { forwardRef } from "react";

import { TextInput, TextInputProps } from "./TextInput";

type UrlInputProps = Omit<TextInputProps, "type" | "inputMode">;

const UrlInput = forwardRef<HTMLInputElement, UrlInputProps>((props, ref) => {
  return <TextInput ref={ref} {...props} inputMode="url" type="text" />;
});

UrlInput.displayName = "UrlInput";

export { UrlInput };
