import { Button } from "../types";
import { z } from "zod";
import type { Operation } from "server/auth/types";

export const allowed: Button["allowed"] = (block) => {
  if (!block.config.id) return [];

  const operations: Operation[] = [
    {
      type: "mutation",
      path: "buttons.triggerClick",
      validator: z.object({
        buttonId: z.literal(block.config.id),
      }),
    },
  ];

  return operations;
};
