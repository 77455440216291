import BlockWrapper from "block-system/components/BlockWrapper";
import { useTheme } from "lib/theme";
import type { BlockProps } from "../../__shared__/types";
import type { MediaBlock as MediaBlockType } from "../types";
import { Media } from "./Media";
import { usePageContext } from "lib/context/page-context";

export function MediaBlock(props: BlockProps<MediaBlockType>) {
  const { config } = props.block;
  const theme = useTheme();
  const { isEditing } = usePageContext();
  const maxWidth = config.style?.width
    ? theme.app.width[config.style?.width]
    : theme.app.width.narrow;

  return (
    <BlockWrapper
      blockId={props.blockId}
      block={props.block}
      alignment={config.style?.alignment}
      maxWidth={maxWidth}
    >
      <Media {...props.block.config} isEditing={isEditing} />
    </BlockWrapper>
  );
}
