import { BlockCreateProps } from "../__shared__/types";
import { blockType } from "./constants";
import { TableBlockSchema } from "./schema";
import { Table, TableBlock } from "./types";

export function copy(block: TableBlock): TableBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: Table["create"] = (
  props?: BlockCreateProps<TableBlock>
) => {
  return TableBlockSchema.parse({
    type: blockType,
    config: {
      title: props?.initialConfig?.title || "My table",
      canSearchRecords: true,
      canCreateRecords: true,
      canUpdateRecords: true,
      canDeleteRecords: false,
      style: {
        width: "wide",
        alignment: "center",
      },
      ...(props?.initialConfig ?? {}),
    },
  });
};

export const map: Table["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...TableBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
