import Script from "next/script";
import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { config } from "@/config";
import { CustomRecaptchaActions } from "utils/captcha/schemas";

type RecaptchaContextOpts = { recaptchaApi: Promise<ReCaptchaV2.ReCaptcha> };
let resolveApi: (api: ReCaptchaV2.ReCaptcha) => void;
const getDefaultOpts = () => {
  const recaptchaApi = new Promise<ReCaptchaV2.ReCaptcha>((resolve) => {
    resolveApi = resolve;
  });
  return { recaptchaApi };
};

const RecaptchaContext = createContext<RecaptchaContextOpts>(getDefaultOpts());

export const RecaptchaProvider = (props: { children: React.ReactNode }) => {
  /**
   * The `typeof window === "undefined"` check causes hydration mismatches.
   * See https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution-9
   */
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return <Fragment>{props.children}</Fragment>;
  }

  const onLoad = () => {
    window.grecaptcha?.enterprise.ready(() => {
      resolveApi(window.grecaptcha?.enterprise);
    });
  };

  if (!window.___grecaptcha_cfg) {
    /*
    This config property is not part of the documented public API, so it may unfortunately change in future
    captcha versions, but it was the only way that seemed to work to load recaptcha using multiple
    keys. We need multiple keys because, when creating a key in the Google Gloud admin, you need
    to define whether it's for visible (checkbox) captchas or invisible (background) captchas.

    To document how I came to this solution, for future debugging purposes:

    The site key is meant to be passed to `https://www.google.com/recaptcha/enterprise.js` via the `render`
    query param. However, this param doesn't accept array entries (e.g. ?render=x&render=y) or even comma-separated
    entries (e.g. ?render=x,y). It expects only one param to be defined. This meant I needed to dig into the code
    to see what was being done with the param. First, I loaded the script with the site key param included, so I could
    see what was being done with it. That URL looked like
    `https://www.google.com/recaptcha/enterprise.js?render=SITE_KEY_HERE`

    Searching the code for `render` gave me `cfg['render']=cfg['render']||[]).push('SITE_KEY_HERE');`.
    I searched for how `cfg` was defined, and found `w = window`, `C = '___grecaptcha_cfg'`, and
    `cfg = w[C] = w[C] || {}`. This told me that I needed to set window.__grecaptcha_cfg to the array of
    site keys that I required.
    */
    window.___grecaptcha_cfg = {};
  }

  window.___grecaptcha_cfg.render = [
    config().NEXT_PUBLIC_HIDDEN_RECAPTCHA_SITE_KEY,
    config().NEXT_PUBLIC_VISIBLE_RECAPTCHA_SITE_KEY,
    "explicit",
  ];

  return (
    <>
      <Script
        async
        src={"https://www.google.com/recaptcha/enterprise.js"}
        onLoad={onLoad}
      />
      {props.children}
    </>
  );
};

const PREVIOUS_CALLS_SAMPLE_SIZE = 5;
export const useExecuteRecaptcha = () => {
  const [captcha, setCaptcha] = useState<string>();
  const captchaStatus = useRef<"invalid" | "loading" | "ready" | "error">(
    "invalid"
  );
  const previousCallsSample = useRef<Array<number>>([]);

  const { recaptchaApi } = useContext(RecaptchaContext);

  const generate = ({
    action,
    onSuccess,
  }: {
    action: CustomRecaptchaActions;
    onSuccess?: (token: string) => void;
  }) => {
    if (captchaStatus.current !== "invalid") {
      return;
    }

    captchaStatus.current = "loading";

    recaptchaApi
      .then((api) => {
        if (previousCallsSample.current.length >= PREVIOUS_CALLS_SAMPLE_SIZE) {
          const [oldestCall, ...otherCalls] = previousCallsSample.current;

          // Consumers need to handle the logic that determines when to request a new token.
          // If they don't properly handle it, this function gets caught in a loop, so we
          // need a basic check that nothing funny is going on. If generate is called with
          // the proper checks, this shouldn't happen.
          if (Date.now() - oldestCall < 1000) {
            throw new Error(
              "Cancelling request due to excessive reCAPTCHA calls."
            );
          }

          previousCallsSample.current = [...otherCalls];
        }

        previousCallsSample.current.push(Date.now());

        return api.execute(config().NEXT_PUBLIC_HIDDEN_RECAPTCHA_SITE_KEY, {
          action,
        });
      })
      .then((token) => {
        captchaStatus.current = "ready";
        setCaptcha(token);
        onSuccess?.(token);
      })
      .catch(() => {
        captchaStatus.current = "error";
        setCaptcha(undefined);
      });
  };

  const invalidate = () => {
    captchaStatus.current = "invalid";
  };

  return {
    generate,
    status: captchaStatus.current,
    captcha,
    invalidate,
  };
};

export const useRenderRecaptcha = () => {
  const { recaptchaApi } = useContext(RecaptchaContext);

  const render = async (opts: {
    componentRef: HTMLDivElement;
    onChange?: (validationKey: string) => void;
    onRendered?: (key: number) => void;
  }) => {
    try {
      const idx = (await recaptchaApi).render(opts.componentRef, {
        sitekey: config().NEXT_PUBLIC_VISIBLE_RECAPTCHA_SITE_KEY,
        callback: opts.onChange ?? (() => null),
      });

      if (opts.onRendered) {
        opts.onRendered(idx);
      }
    } catch (e) {
      const { message } = e as { message: string };

      // this particular error is more of a warning than an error -- it failing to render it a
      // subsequent time doesn't break anything, and we can safely ignore it
      if (
        typeof message !== "string" ||
        !message.startsWith("reCAPTCHA has already been rendered")
      ) {
        throw e;
      }
    }
  };

  const reset = async (recaptchaWidgetIndex?: number) =>
    (await recaptchaApi).reset(recaptchaWidgetIndex);

  return {
    render,
    reset,
  };
};
