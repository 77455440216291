export function IconStripe({ size }: { size?: number }) {
  return (
    <svg
      width={size ?? 30}
      height={size ?? 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6154_7082)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H30V30H0V0Z"
          fill="#635BFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.8301 11.6625C13.8301 10.9575 14.4076 10.68 15.3676 10.68C16.7476 10.68 18.4876 11.1 19.8676 11.8425V7.5825C18.3601 6.9825 16.8751 6.75 15.3751 6.75C11.6926 6.75 9.24756 8.67 9.24756 11.88C9.24756 16.8825 16.1401 16.0875 16.1401 18.2475C16.1401 19.08 15.4126 19.35 14.4001 19.35C12.8926 19.35 10.9726 18.735 9.45006 17.9025V22.215C11.1376 22.9425 12.8401 23.25 14.4001 23.25C18.1726 23.25 20.7676 21.3825 20.7676 18.135C20.7376 12.735 13.8301 13.695 13.8301 11.6625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6154_7082">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
