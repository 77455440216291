import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { DATA_URL_PATTERN, matchSafeUrl } from "utils/regex";

export function sanitizeUrl(url: string) {
  if (!url || url === "about:blank") {
    return {
      sanitizedUrl: "about:blank",
      isRelativeUrl: false,
    };
  }

  url = url.trim();

  const safeUrlMatch = matchSafeUrl(url);
  const dataUrlMatch = url.match(DATA_URL_PATTERN);

  if (safeUrlMatch?.hasDomainWithoutProtocol) {
    url = `http://${url}`;
  }

  const isRelativeUrl =
    !safeUrlMatch?.hasProtocol &&
    !safeUrlMatch?.hasDomainWithoutProtocol &&
    !dataUrlMatch;

  return {
    sanitizedUrl: safeUrlMatch.isMatch || dataUrlMatch ? url : "about:blank",
    isRelativeUrl,
  };
}

export function useSafeUrl(
  url: string,
  change?: (payload: { url: string }) => void
) {
  const { sanitizedUrl, isRelativeUrl } = sanitizeUrl(url);

  const [isRelative, setIsRelative] = useState(isRelativeUrl);
  const [candidateUrl, setCandidateUrl] = useState(sanitizedUrl);

  useEffect(() => {
    setCandidateUrl(url);
    setIsRelative(isRelativeUrl);
  }, [url, isRelativeUrl]);

  return useMemo(
    () => ({
      onBlur() {
        change?.({ url: sanitizeUrl(candidateUrl).sanitizedUrl });
      },
      onChange(event: ChangeEvent<HTMLInputElement>) {
        setCandidateUrl(event.currentTarget.value);
      },
      value: candidateUrl,
      isRelative,
    }),
    [candidateUrl, change, isRelative]
  );
}
