import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd";
import { Icon } from "@zapier/design-system";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { Fragment, ReactNode } from "react";
import { cn } from "utils/cn";
import { ScrollWrapper } from "../../../../components/ScrollWrapper";

export function Kanban(props: {
  disableScroll?: boolean;
  onDragEnd: OnDragEndResponder;
  children: ReactNode;
}) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <ScrollWrapper disableScroll={props.disableScroll}>
        {props.children}
      </ScrollWrapper>
    </DragDropContext>
  );
}

Kanban.Column = function Column(props: {
  droppableId: string;
  title?: ReactNode;
  isDisabled?: boolean;
  isDropDisabled?: boolean;
  disableScroll?: boolean;
  onClickCreate?: () => void;
  children?: ReactNode;
}) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <Droppable
      droppableId={props.droppableId}
      isDropDisabled={props.isDropDisabled}
    >
      {(droppableProvided, droppableSnapshot) => {
        return (
          <div
            ref={droppableProvided.innerRef}
            className={cn(
              [
                "flex max-h-[600px] w-72 flex-shrink-0 flex-grow-0 basis-auto flex-col flex-nowrap rounded px-3 pb-3 pt-4",
                "transition-colors",
                { "": droppableSnapshot.isDraggingOver },
              ],
              {
                "bg-muted/50": interfacesTheme,
                "bg-muted": interfacesTheme && droppableSnapshot.isDraggingOver,
                "bg-[rgba(0,0,0,0.05)]": !interfacesTheme,
                "bg-[rgba(0,0,0,0.1)]":
                  !interfacesTheme && droppableSnapshot.isDraggingOver,
              }
            )}
            {...droppableProvided.droppableProps}
          >
            {props.title ? (
              <div
                className={cn("mx-2 mb-4 flex-none text-[15px] font-medium", {
                  "text-muted-foreground": interfacesTheme,
                  "text-zi-pageBackground-800": !interfacesTheme,
                })}
              >
                {props.title}
              </div>
            ) : null}
            <div
              className={cn([
                "-my-1 flex-1",
                {
                  "overflow-hidden": !!props.disableScroll,
                  "overflow-y-auto": !props.disableScroll,
                },
              ])}
            >
              {props.children}
              {droppableProvided.placeholder}
            </div>
            {props.onClickCreate !== undefined ? (
              <Fragment>
                <div className="h-3" />
                <button
                  className={cn(
                    [
                      "flex h-10 w-full flex-row flex-nowrap items-center px-3",
                      "cursor-pointer text-[15px]",
                      "opacity-0 transition-opacity hover:opacity-100",
                    ],
                    {
                      "rounded-medium bg-primary text-primary-foreground":
                        interfacesTheme,
                      "rounded-md bg-zi-pageBackground-200 text-zi-pageBackground-700":
                        !interfacesTheme,
                    }
                  )}
                  disabled={props.isDisabled}
                  onClick={props.onClickCreate}
                >
                  <Icon name="formAdd" size={20} />
                  <div className="w-2" />
                  <span>Add card</span>
                </button>
              </Fragment>
            ) : (
              <div className="h-10" />
            )}
          </div>
        );
      }}
    </Droppable>
  );
};

Kanban.Card = function Card(props: {
  draggableId: string;
  index: number;
  isDragDisabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
}) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <Draggable
      draggableId={props.draggableId}
      index={props.index}
      isDragDisabled={props.isDragDisabled}
    >
      {(draggableProvided, draggableSnapshot) => {
        return (
          <div
            className="py-1"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <div
              className={cn([
                "px-4 py-3 shadow-sm transition-all",
                { "rotate-[4deg] shadow-xl": draggableSnapshot.isDragging },
                { "cursor-pointer": !!props.onClick },
                {
                  "rounded-small bg-card text-card-foreground hover:brightness-95 dark:hover:brightness-105":
                    interfacesTheme,
                  "rounded bg-white hover:bg-zi-pageBackground-50":
                    !interfacesTheme,
                },
              ])}
              onClick={props.onClick}
            >
              {props.children}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

Kanban.CardTitle = function CardTitle(props: { children: ReactNode }) {
  return (
    <div className="text-base font-normal leading-[1.5]">{props.children}</div>
  );
};

Kanban.CardBody = function CardBody(props: { children: ReactNode }) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      className={cn("mb-[5px] mt-[10px] text-sm", {
        "text-card-foreground-subtle": interfacesTheme,
        "text-zi-secondaryText opacity-80": !interfacesTheme,
      })}
    >
      {props.children}
    </div>
  );
};
