import { cn } from "utils/cn";

export function MediaZeroState({ title }: { title: string }) {
  return (
    <div
      className={cn("flex h-full w-full items-center justify-center bg-muted")}
    >
      <p className="text-[34px] font-semibold leading-10 tracking-[1px]">
        {title}
      </p>
    </div>
  );
}
