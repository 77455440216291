import { blockType } from "./constants";
import { DividerBlockSchema } from "./schema";
import { Divider, DividerBlock } from "./types";

export const create: Divider["create"] = () => {
  return DividerBlockSchema.parse({
    type: blockType,
    config: {
      spacing: "medium",
      width: "medium",
    },
  });
};

export const map: Divider["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...DividerBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};

export function copy(block: DividerBlock): DividerBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}
