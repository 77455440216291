import { forwardRef, useCallback, useMemo } from "react";
import { Option } from "./types";
import {
  DropdownSelect,
  Props as DropdownSelectProps,
} from "@/block-system/brickz/components/ui/DropdownSelect";

export type Props = Omit<DropdownSelectProps, "value" | "onChange"> & {
  value?: string | string[] | Option | Option[];
  onChange?: (newValue: Option | Option[] | null) => void;
  id?: string;
};

export const DynamicDropdownSelect = forwardRef<any, Props>(
  (
    { value, placeholder, multiSelect, isErrored, onChange, options, id },
    ref
  ) => {
    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (value: Option | Option[]) => {
            onChange(value);
          }
        : undefined;
    }, [onChange]);

    const getMappedOptionFromValue = useCallback(
      (value: unknown): Option | undefined => {
        if (typeof value === "string") {
          return options.find((option) => option.value === value);
        } else if (value && typeof value === "object" && "value" in value) {
          return options.find((option) => option.value === value.value);
        }
        return undefined;
      },
      [options]
    );

    const parsedValue = useMemo<string | string[] | undefined>(() => {
      if (!value) {
        return undefined;
      }

      if (multiSelect) {
        let parsedValue = value;

        if (typeof value === "string") {
          parsedValue = value.split(",");
        }

        return (parsedValue as Array<string | Option>)
          .reduce((acc, value) => {
            const opt = getMappedOptionFromValue(value);
            if (opt) acc.push(opt);
            return acc;
          }, [] as Option[])
          .map((option) => option.value);
      }

      return getMappedOptionFromValue(value)?.value;
    }, [getMappedOptionFromValue, multiSelect, value]);

    return (
      <DropdownSelect
        id={id}
        ref={ref}
        isErrored={isErrored}
        placeholder={placeholder}
        value={parsedValue}
        multiSelect={multiSelect}
        options={options}
        onChange={(selectedOption) => {
          if (!handleChange || !selectedOption) return;
          if (multiSelect && Array.isArray(selectedOption)) {
            handleChange(
              selectedOption.reduce((acc, value) => {
                const opt = getMappedOptionFromValue(value);
                if (opt) acc.push(opt);
                return acc;
              }, [] as Option[])
            );
          } else if (!Array.isArray(selectedOption)) {
            const opt = getMappedOptionFromValue(selectedOption);
            if (opt) {
              handleChange(opt);
            }
          }
        }}
      />
    );
  }
);

DynamicDropdownSelect.displayName = "DynamicDropdownSelect";
