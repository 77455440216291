import React, { ReactNode } from "react";
import { trpc } from "utils/trpc";

type DatasourcesMap = Record<string, any>;

const defaultValue: DatasourcesMap = {};

export const DatasourcesContext =
  React.createContext<DatasourcesMap>(defaultValue);

export function useDatasourcesContext() {
  return React.useContext(DatasourcesContext);
}

export const DatasourcesProvider = React.memo(
  (props: { children: ReactNode }) => {
    const [datasources, setDatasources] = React.useState<DatasourcesMap>({});

    const { mutateAsync: fetchDatasource } = trpc.datasources.run.useMutation();

    /**
     * TODO: Figure out how to prevent the same datasource from being fetched multiple times.
     * This pattern works if the datasource were going to get fetched _later_, but the blocks
     * attempt to call it immediately, so it gets called multiple times before it's cached.
     */
    const getDatasource = React.useCallback(
      async (id: string, input?: Record<string, any>) => {
        // TODO: base64 encode this hash, or something
        const hash = `${id}-${JSON.stringify(input)}`;

        if (datasources[hash]) return datasources[hash];

        // TODO: [INTRFCS-1513] Create an endpoint to get sample data for the datasource
        // and use that if we are in the builder context.

        // if not, fetch it with fetchDatasource
        const datasource = await fetchDatasource({ id, input });
        setDatasources({ ...datasources, [hash]: datasource });

        return datasource;
      },
      [datasources, setDatasources, fetchDatasource]
    );

    return (
      <DatasourcesContext.Provider value={{ getDatasource }}>
        {props.children}
      </DatasourcesContext.Provider>
    );
  }
);

DatasourcesProvider.displayName = "DatasourcesContextProvider";
