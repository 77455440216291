import { matchUrlRegex } from "utils/url-helpers";
import { SUPPORTED_VIDEO_PROVIDERS } from "./constants";
import {
  LOOM_URL_REGEX,
  VIMEO_URL_REGEX,
  YOUTUBE_URL_REGEX,
  ZAPPY_VIDEO_URL_REGEX,
} from "utils/regex";

export function isSupporttedVideoProvider(url: string) {
  return SUPPORTED_VIDEO_PROVIDERS.some((provider) => url.includes(provider));
}

export function getProviderFromUrl(url: string) {
  return SUPPORTED_VIDEO_PROVIDERS.find((provider) => url.includes(provider));
}

export function isValidYoutubeUrl(url: string) {
  return matchUrlRegex(url, YOUTUBE_URL_REGEX);
}

export function isValidVimeoUrl(url: string) {
  return matchUrlRegex(url, VIMEO_URL_REGEX);
}

export const isValidLoomUrl = (url: string) => {
  return matchUrlRegex(url, LOOM_URL_REGEX);
};

export const isValidZappyVideoUrl = (url: string) => {
  return ZAPPY_VIDEO_URL_REGEX.test(url);
};

export function isValidVideoProviderUrl(url: string) {
  const provider = getProviderFromUrl(url);
  if (!provider) return false;

  switch (provider) {
    case "youtube":
    case "youtu.be":
      return isValidYoutubeUrl(url);
    case "vimeo":
      return isValidVimeoUrl(url);
    case "loom":
      return isValidLoomUrl(url);
    case "zappy":
      return isValidZappyVideoUrl(url);
    default:
      const _exhaustiveCheck: never = provider;
      return _exhaustiveCheck;
  }
}
