import {
  Button as ShadcnButton,
  ButtonProps as ShadcnButtonProps,
} from "@/block-system/brickz/components/ui/Button";
import {
  Button,
  ButtonProps as LegacyButtonProps,
} from "@/block-system/components/Button";
import { useShadcnComponentsEnabled } from "@/block-system/brickz/lib/shadcn";
import React from "react";

/**
 * This component is only here to "scope down" changes
 * related to ShadCN to forms-related components.
 *
 * It should be deleted once we start migrating all the buttons in the application.
 */
export const FormButton = React.forwardRef<
  HTMLButtonElement,
  LegacyButtonProps | ShadcnButtonProps
>((props, ref) => {
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();

  if (shadcnComponentsEnabled) {
    return (
      <ShadcnButton
        className="relative w-full"
        {...(props as ShadcnButtonProps)}
        ref={ref}
      />
    );
  }

  return <Button {...(props as LegacyButtonProps)} ref={ref} />;
});

FormButton.displayName = "Button";
