import { ReactNode, createContext, useContext } from "react";
import type { SplitCheck } from "utils/trpc";

const BuilderFlagsContext = createContext<SplitCheck | null>(null);

export function BuilderFlagsProvider({
  children,
  builderFlags,
}: {
  children: ReactNode;
  builderFlags: SplitCheck;
}) {
  return (
    <BuilderFlagsContext.Provider value={builderFlags}>
      {children}
    </BuilderFlagsContext.Provider>
  );
}

export function useBuilderFlags() {
  const context = useContext(BuilderFlagsContext);
  if (context === null) {
    throw new Error(
      "useBuilderFlags must be used within a BuilderFlagsProvider"
    );
  }
  return context;
}
