import { FormBlock } from "block-system/blocks/Form/Form/schema";
import { FieldBlock } from "../../types";
import {
  FieldBlockConditionalLogicItem,
  FieldBlockConditionalLogicOperator,
} from "../../schema";
import { resetDateSecondsAndMillis } from "./resetDateSecondsAndMillis";
import { isValid } from "date-fns";
import { format } from "date-fns-tz";
import {
  booleanFieldTypes,
  numericFieldTypes,
  stringFieldTypes,
} from "./conditional-logic";

export const conditionalLogicOperatorItems: {
  label: string;
  value: FieldBlockConditionalLogicOperator;
}[] = [
  { label: "Is exactly", value: "exact" },
  { label: "Contains (case sensitive)", value: "contains" },
  { label: "Contains", value: "icontains" },
  { label: "Is one of", value: "in" },
  { label: "Starts with (case sensitive)", value: "startswith" },
  { label: "Is greater than or equal to", value: "gte" },
  { label: "Is less than or equal to", value: "lte" },
  { label: "Is greater than", value: "gt" },
  { label: "Is less than", value: "lt" },
  { label: "Is in range", value: "range" },
  { label: "Is empty", value: "isEmpty" },
  { label: "Is not empty", value: "isNotEmpty" },
];

export function getDefaultValueForFieldOperator(field: FieldBlock) {
  if (
    field.config.inputType === "checkbox" ||
    field.config.inputType === "yes-no"
  ) {
    return false;
  }

  if (field.config.inputType === "date-picker") {
    const date = resetDateSecondsAndMillis(new Date());
    return date.toISOString();
  }

  if (field.config.inputType === "file-upload") {
    return true;
  }

  return "";
}

export function getAllowedOperatorsForField(
  field: FieldBlock
): FieldBlockConditionalLogicOperator[] {
  switch (field.config.inputType) {
    case "number":
    case "currency":
      return ["exact", "gt", "gte", "lte", "lt", "isEmpty", "isNotEmpty"];
    case "checkbox":
    case "yes-no":
      return ["exact"];
    case "dropdown":
      return ["exact", "in", "isEmpty", "isNotEmpty"];
    case "date-picker":
      return ["exact", "gte", "gt", "lte", "lt"];
    case "file-upload":
      return ["exact"];
    default:
      return [
        "exact",
        "in",
        "contains",
        "icontains",
        "startswith",
        "isEmpty",
        "isNotEmpty",
      ];
  }
}

export function getAllowedOperators(
  fieldId: string,
  fields: FieldBlock[]
): { label: string; value: FieldBlockConditionalLogicOperator }[] {
  const fieldBlock = fields.find((f) => f.config.id === fieldId);
  if (!fieldBlock) return [];
  const allowedOperators = getAllowedOperatorsForField(fieldBlock);
  return conditionalLogicOperatorItems.filter((op) =>
    allowedOperators.includes(op.value)
  );
}

export function removeConditionsOnFieldRemoval(
  field: FieldBlock,
  formBlock: FormBlock
) {
  const updatedChildren = formBlock.children
    .map((child) => {
      child.config.conditionalLogic = child.config.conditionalLogic.filter(
        (condition) => condition.fieldId !== field.config.id
      );

      return child;
    })
    .filter((child) => child.config.id !== field.config.id);

  return { ...formBlock, children: updatedChildren };
}

export function isCompatibleConditionalLogicConversion(
  currentType: FieldBlock["config"]["inputType"],
  newType: FieldBlock["config"]["inputType"]
) {
  if (currentType === newType) return true;

  if (newType === "date-picker" || newType === "file-upload") return false;

  if (booleanFieldTypes.includes(newType))
    return booleanFieldTypes.includes(currentType);

  if (numericFieldTypes.includes(newType))
    return numericFieldTypes.includes(currentType);

  if ([...stringFieldTypes, "dropdown"].includes(newType))
    return [...stringFieldTypes, "dropdown", "date-picker"].includes(
      currentType
    ); // Conversion from date-picker to other string field is supported since we save the date as a string

  return true;
}

export function getConditionTitle(
  condition: FieldBlockConditionalLogicItem,
  fields: FieldBlock[]
) {
  const field = fields.find((f) => f.config.id === condition.fieldId);
  if (!field) return "";

  const operator = conditionalLogicOperatorItems.find(
    (op) => op.value === condition.operator
  );
  if (!operator) return "";

  let label = field.config.label;
  if (field.config.inputType === "checkbox") {
    label = field.config.text;
  }

  let value = condition.value;

  if (field.config.inputType === "checkbox") {
    value = condition.value === true ? "checked" : "unchecked";
  } else if (field.config.inputType === "yes-no") {
    value = condition.value === true ? "yes" : "no";
  } else if (
    typeof condition.value === "string" &&
    field.config.inputType === "date-picker"
  ) {
    if (!isValid(new Date(condition.value)))
      condition.value = new Date().toISOString();
    if (field.config.includeTime) {
      value = format(new Date(condition.value), "yyyy-MM-dd HH:mm:ss");
    } else {
      value = format(new Date(condition.value), "yyyy-MM-dd");
    }
  }

  return `When ${label} ${operator.label.toLowerCase()} '${value}'`;
}
