export const strings = {
  NAME: "Embed",

  DESCRIPTION:
    "Easily display content from third-party applications using an iframe.",

  SOURCE_FIELD_LABEL: "Source",
  URL_FIELD_LABEL: "Website URL",
  CODE_FIELD_LABEL: "HTML",
  TITLE_FIELD_LABEL: "Title",
  WIDTH_FIELD_LABEL: "Width",
  HEIGHT_FIELD_LABEL: "Height (px)",
} as const;
