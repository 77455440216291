import { Icon } from "@zapier/design-system";
import { ReactNode, useEffect, useRef, useState } from "react";
import { cn } from "utils/cn";
import { useEmbed } from "lib/context/embed-context";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

const Shadow: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div"> & {
    position: "left" | "right";
    color?: "white";
  }
> = ({ position, color, ...props }) => {
  const interfacesTheme = useInterfacesTheme();
  const { noBackground } = useEmbed();
  const colors = {
    white: {
      start: {
        interfaces: "left-0 from-background to-transparent",
        legacy: "left-0 from-zi-white to-transparent",
      },
      end: {
        interfaces: "right-0 from-transparent to-background",
        legacy: "right-0 from-transparent to-zi-white",
      },
    },
    default: {
      start: {
        interfaces: "left-0 from-background to-transparent",
        legacy: "left-0 from-zi-pageBackground to-transparent",
      },
      end: {
        interfaces: "right-0 from-transparent to-background",
        legacy: "right-0 to-zi-pageBackground from-transparent",
      },
    },
  };
  const shadowColor = colors[color ?? "default"];
  const shadowColorStart =
    shadowColor.start[interfacesTheme ? "interfaces" : "legacy"];
  const shadowColorEnd =
    shadowColor.end[interfacesTheme ? "interfaces" : "legacy"];

  return (
    <div
      {...props}
      className={cn(
        "pointer-events-none absolute inset-y-0 flex w-8 items-center justify-center bg-gradient-to-r transition-opacity",
        {
          "text-muted-foreground": interfacesTheme,
          "text-zi-gray": !interfacesTheme,
          [shadowColorStart]: position === "left" && !noBackground,
          "left-0 from-transparent to-transparent":
            position === "left" && noBackground,
          [shadowColorEnd]: position === "right" && !noBackground,
          "right-0 from-transparent to-transparent":
            position === "right" && noBackground,
        },
        props.className
      )}
    />
  );
};

export function ScrollWrapper(props: {
  children: ReactNode;
  disableScroll?: boolean;
  shadowColor?: "white";
}) {
  const ref = useRef<HTMLDivElement>(null);

  const [overflowsLeft, setOverflowsLeft] = useState(false);
  const [overflowsRight, setOverflowsRight] = useState(false);

  const checkOverflow = () => {
    if (!ref.current) return;
    const el = ref.current;
    setOverflowsLeft(el.scrollLeft > 0);
    setOverflowsRight(el.scrollLeft < el.scrollWidth - el.offsetWidth);
  };

  useEffect(checkOverflow, [props.children]);

  return (
    <div className="relative">
      <div
        data-disable-scroll={!!props.disableScroll}
        className="flex w-full flex-row flex-nowrap items-start gap-x-3 overflow-x-auto pb-2.5 data-[disable-scroll=true]:overflow-x-hidden"
        ref={ref}
        onScroll={checkOverflow}
      >
        {props.children}
      </div>
      <Shadow
        style={{ opacity: overflowsLeft ? 1 : 0 }}
        position="left"
        color={props.shadowColor}
      >
        <Icon name="arrowBigLeft" size={26} />
      </Shadow>
      <Shadow
        style={{ opacity: overflowsRight ? 1 : 0 }}
        position="right"
        color={props.shadowColor}
      >
        <Icon name="arrowBigRight" size={26} />
      </Shadow>
    </div>
  );
}
