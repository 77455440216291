import { isNull } from "lodash";
import React from "react";
import { Page } from "utils/trpc";

const CurrentPageContext = React.createContext<Page | null>(null);

export function useCurrentPage() {
  const page = React.useContext(CurrentPageContext);

  if (isNull(page)) {
    throw new Error("useCurrentPage must be used within a CurrentPageProvider");
  }

  return page;
}

export function CurrentPageProvider({
  children,
  page,
}: {
  children: React.ReactNode;
  page: Page;
}) {
  return (
    <CurrentPageContext.Provider value={page}>
      {children}
    </CurrentPageContext.Provider>
  );
}
