import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { HTMLProps } from "react";
import { cn } from "utils/cn";

export function Wrapper(props: HTMLProps<HTMLDivElement>) {
  const { className, ...rest } = props;
  const interfacesTheme = useInterfacesTheme();

  return (
    <div
      className={cn(
        "mx-auto flex w-full max-w-xl rounded-xl bg-zi-white p-12 shadow-subtle",
        {
          "rounded-xl bg-zi-white": !interfacesTheme,
          "rounded-large bg-card": interfacesTheme,
        },
        className
      )}
      {...rest}
    />
  );
}
