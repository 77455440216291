import { Alignment } from "block-system/blocks/__shared__/components/AlignmentField/schema";
import { styled } from "lib/theme";
import { ReactNode } from "react";
import { LayoutItem } from "./LayoutItem";
import { LayoutItemZeroState } from "./LayoutItemZeroState";

const LayoutWrapper = styled.div<{ $columnTemplate: string }>`
  display: grid;
  align-items: center;
  gap: 1rem;
  ${({ $columnTemplate }) => `grid-template-columns: ${$columnTemplate};`}
`;

type Props = {
  items: {
    child: ReactNode;
    alignment: Alignment;
    colspan: number;
  }[];
  columnCount: number;
};

export function LayoutParent({ items, columnCount }: Props) {
  // Three column vs 2 column layout
  const gridTemplateColumns = new Array(columnCount).fill("1fr").join(" ");

  return (
    <div
      className="grid items-center gap-4"
      style={{
        gridTemplateColumns,
      }}
    >
      {items.map((item, index) => (
        <LayoutItem item={item} key={index} />
      ))}
    </div>
  );
}

export function LayoutZeroState() {
  return (
    <LayoutWrapper $columnTemplate="1fr 1fr 1fr">
      <LayoutItemZeroState />
      <LayoutItemZeroState />
      <LayoutItemZeroState />
    </LayoutWrapper>
  );
}
