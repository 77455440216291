import { isValid as isValidDate, format as formatDate } from "date-fns";
import { FormBlock } from "../schema";

type FormData = Record<string, any>;

export function getFormDataAndQueryParams(form: FormBlock, data: FormData) {
  const fields = form.children;
  const formData: FormData = {};
  const queryParamsData: Record<string, string> = {};
  for (const key in data) {
    const fieldBlock = fields.find((field) => field.config.name === key);

    if (!fieldBlock) {
      console.error(
        `Cannot find field block with name: ${key}, form id: ${form.config.id}`
      );
      continue;
    }

    if (!fieldBlock.config.id)
      throw new Error(`Form: ${form.config.id} Field block is missing id`);

    if (fieldBlock.config.readOnly) {
      continue;
    }

    formData[fieldBlock.config.id] = data[fieldBlock.config.name];

    if (
      fieldBlock.config.inputType === "date-picker" &&
      isValidDate(data[fieldBlock.config.name])
    ) {
      let formattedDate = new Date(data[fieldBlock.config.name]).toISOString();
      if (!fieldBlock.config.includeTime) {
        formattedDate = formatDate(data[fieldBlock.config.name], "yyyy-MM-dd");
      }
      formData[fieldBlock.config.id] = formattedDate;
    }

    let urlParam = data[fieldBlock.config.name];

    if (
      fieldBlock.config.inputType === "dropdown" &&
      fieldBlock.config.tableId
    ) {
      if (fieldBlock.config.multiSelect)
        urlParam = data[fieldBlock.config.name]
          ?.map((item: { label: string; value: string }) => item.value)
          .join(",");
      else urlParam = data[fieldBlock.config.name]?.value;
    }

    queryParamsData[fieldBlock.config.name] = urlParam;
  }

  return [formData, queryParamsData];
}
