import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

/**
 * Wrap anything that renders rich text (e.g. ReactMarkdown) in this component
 * to apply styles to their standard HTML tags.
 *
 * Tags you can style: a, blockquote, br, code, em, h1, h2, h3, h4, h5, h6, hr,
 * img, li, ol, p, pre, strong, and ul
 */
export const RichTextStyleWrapper: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "custom-richtext",
        {
          "interfaces-theme": interfacesTheme,
        },
        props.className
      )}
    />
  );
};
