import { useRenderRecaptcha } from "lib/hooks/RecaptchaProvider";
import { useEffect, useRef } from "react";
import { CustomRecaptchaActions } from "utils/captcha/schemas";

type RecaptchaProps = {
  onChange?: (validationKey: string) => void;
  onRendered?: (key: number) => void;
  action: CustomRecaptchaActions;
};

export const ReCAPTCHA = ({ action, onChange, onRendered }: RecaptchaProps) => {
  const { render: renderRecaptcha } = useRenderRecaptcha();
  const recaptchaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (recaptchaRef?.current) {
      void renderRecaptcha({
        componentRef: recaptchaRef?.current,
        onChange,
        onRendered,
      });
    }
  }, [onChange, onRendered, renderRecaptcha]);

  return (
    <>
      <div data-action={action} ref={recaptchaRef}></div>
    </>
  );
};
