export const ALLOWED_TEMPLATE_KEY_NAMESPACES = ["params", "currentUser"];

export function buildTemplateKeyRegex() {
  const allowedNamespaces = [
    ...ALLOWED_TEMPLATE_KEY_NAMESPACES,
    "c[a-z0-9]{8,}", // Add a regex for CUIDs for datasource record IDs.
  ].join("|");
  const regex = `{{ *(${allowedNamespaces})\.([a-zA-Z0-9__@.\/#&+-]+)(?:\\|default:"([^\n"]+?)")? *}}`;
  return new RegExp(regex, "g");
}
