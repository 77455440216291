import { cn } from "utils/cn";
import { ALIGNMENT_TO_CLASSNAMES, MAX_WIDTH_TO_CLASSNAMES } from "./constants";
import { BlockWrapperProps } from "./types";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
export function PublishedBlockWrapper(props: BlockWrapperProps) {
  const { isHidden } = useCurrentConsumerProject();

  return (
    <div className={cn("relative w-full", { grow: isHidden }, props.className)}>
      <div
        className={cn(
          "relative",
          ALIGNMENT_TO_CLASSNAMES[props.alignment ?? "center"],
          MAX_WIDTH_TO_CLASSNAMES[props.maxWidth ?? "100%"],
          { "min-h-svh": isHidden }
        )}
      >
        <div
          className={cn("relative scroll-mx-0 scroll-my-10", {
            "min-h-svh": isHidden,
          })}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
