import img from "assets/images/content-block-thumbnails/link-cards.png";
import { blockType } from "./constants";
import { strings } from "./strings";
import { BlockImages } from "block-system/blocks/__shared__/types";

export const LinksCardImages: BlockImages = {
  thumbnail: {
    name: strings.NAME,
    image: img,
    type: blockType,
  },
};
