import type { SupportedCurrencies } from "block-system/blocks/Form/Field/types";

export function renderCurrencyIcon(currencyFormat: SupportedCurrencies) {
  switch (currencyFormat) {
    case "usd1":
    case "usd2":
      return "$";
    case "euro1":
      return "€";
    case "pound1":
      return "£";
    case "yen1":
      return "¥";
    default:
      const _exhaustiveCheck: never = currencyFormat;
      return _exhaustiveCheck;
  }
}
