import { Icon } from "@zapier/design-system";
import { styled, keyframes } from "lib/theme";
import {
  defaultCustomizableThemeColors,
  defaultThemeStyles,
} from "lib/theme/themes/app-theme";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background-color: ${defaultCustomizableThemeColors.pageBackground};

  & > * {
    color: ${defaultCustomizableThemeColors.secondaryText};
    text-align: center;
    font-family: ${defaultThemeStyles.fontFamily} !important;
  }

  & > h1 {
    font-size: 42px;
    line-height: 50px;
    font-weight: 600;
  }

  & > p {
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;
  }

  & > div:first-of-type {
    background-color: ${defaultCustomizableThemeColors.secondaryText};
    border-radius: 100%;
    animation: ${spin} 10s linear infinite !important;
    color: ${defaultCustomizableThemeColors.pageBackground} !important;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function MaintenancePageComponent() {
  return (
    <Wrapper>
      <div>
        <Icon name="navCogFill" size={60} color="currentColor" />
      </div>
      <h1>Down for maintenance</h1>
      <p>
        We&apos;ll be back online in a few minutes.
        <br />
        Thank you for your patience.
      </p>
    </Wrapper>
  );
}
