import { useCurrentPages } from "lib/context/current-pages-context";
import { ButtonBlock } from "../types";
import {
  BUTTON_SIZE_STYLES,
  BUTTON_WIDTH_STYLES,
  ButtonAction,
} from "../schema";
import { useRouter } from "next/router";
import { navigateToPage } from "block-system/blocks/__shared__/lib/actions/navigation";
import { openExternalUrl } from "block-system/blocks/__shared__/lib/actions/openExternalUrl";

import { trpc } from "utils/trpc";
import { cn } from "utils/cn";
import { NotificationAction } from "block-system/types";
import toast from "react-hot-toast";
import { SuccessNotification } from "block-system/components/Notification";
import { InterfacesTheme, useInterfacesTheme } from "lib/theme/ThemeProvider";

function getButtonTailwindStyles(
  config: ButtonBlock["config"],
  interfacesTheme?: InterfacesTheme | null
) {
  return cn(
    "hover:shadow-md",
    {
      "rounded-small bg-primary text-primary-foreground hover:brightness-110":
        interfacesTheme && config.type === "primary",
      "rounded-small border-2 border-solid border-foreground bg-background text-foreground hover:brightness-110":
        interfacesTheme && config.type === "secondary",
      "rounded-[3px] bg-zi-primary text-white hover:brightness-110":
        !interfacesTheme && config.type === "primary",
      "rounded-[3px] border-2 border-solid bg-transparent text-black hover:bg-zi-primary-50":
        !interfacesTheme && config.type === "secondary",
    },
    BUTTON_SIZE_STYLES[config.size].padding,
    BUTTON_SIZE_STYLES[config.size].height,
    BUTTON_WIDTH_STYLES[config.width]
  );
}

function getCustomColorStyles(config: ButtonBlock["config"]) {
  if (config.colorType !== "custom") return undefined;
  return {
    backgroundColor: config.customColor?.background,
    color: config.customColor?.textColor,
  };
}

export const Button = (props: ButtonBlock["config"] & { blockId: string }) => {
  const interfacesTheme = useInterfacesTheme();
  const styles = getButtonTailwindStyles(props, interfacesTheme);
  const pages = useCurrentPages();
  const router = useRouter();

  const { mutateAsync: registerButtonClick, isPending: isTriggeringClick } =
    trpc.buttons.triggerClick.useMutation();

  const handleButtonAction = async (action: ButtonAction) => {
    if (!props.actions.length) return;
    const type = action.type;

    switch (type) {
      case "navigate":
        return await navigateToPage(action, pages, router);
      case "openExternalUrl":
        return openExternalUrl(action);
      case "notification":
        return showSuccessNotification(action);
      case "zap":
        if (props.id) return await registerButtonClick({ buttonId: props.id });
        break;
      default:
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
    }
  };

  const handleButtonClick = () => {
    if (!props.actions.length) return;
    props.actions.forEach((action) => {
      void handleButtonAction(action);
    });
  };

  return (
    <ButtonPresentational
      {...props}
      styles={styles}
      handleButtonAction={handleButtonClick}
      disabled={isTriggeringClick}
    />
  );
};

export function ButtonPresentational(
  props: ButtonBlock["config"] & {
    blockId: string;
    styles: string;
    handleButtonAction: () => void;
    disabled: boolean;
  }
) {
  return (
    <div
      style={{
        textAlign: props.alignment,
      }}
    >
      <button
        className={props.styles}
        style={getCustomColorStyles(props)}
        onClick={props.handleButtonAction}
        disabled={props.disabled}
        data-testid={`button-block-${props.blockId}`}
      >
        {props.label}
      </button>
    </div>
  );
}

function showSuccessNotification(notificationAction: NotificationAction) {
  toast.custom(
    (t) => (
      <SuccessNotification
        toast={t}
        message={notificationAction.config.message}
        handleClose={toast.dismiss}
      />
    ),
    { position: notificationAction.config.position ?? "top-center" }
  );
}
