import { PaidFeature } from "server/auth/types";
import { Account } from "server/schemas/user";

export const isTeamOrCompanyAccount = (account?: Account) => {
  return Boolean(
    account?.is_team_account ||
      account?.is_company_account ||
      account?.is_unlimited
  );
};

export const hasAccessToFeature = (
  feature: PaidFeature,
  allowedFeatures?: PaidFeature[]
) => {
  return allowedFeatures?.includes(feature) ?? false;
};
