import type { Field as FieldType } from "./types";
import { create, copy, map } from "./operations";

import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";

export const Field: FieldType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  isStandalone: false,
};
