import { useEffect, useRef } from "react";

export function usePrevious<T>(value: T) {
  // Copied from https://usehooks.com/usePrevious/

  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function usePreviousCallback<T>(
  value: T,
  callback: (previous: T | undefined, current: T) => void
) {
  const valueRef = useRef<T>();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    callbackRef.current(valueRef.current, value);
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
}
