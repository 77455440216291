export function IconOptionSquareUnchecked() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 22.75H22.75V3.25H3.25V22.75ZM5.41667 5.41667H20.5833V20.5833H5.41667V5.41667Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
    </svg>
  );
}
