import { BlockAlert } from "@/block-system/brickz/components/ui/BlockAlert";
import { Field } from "@/block-system/brickz/components/ui/Field";
import { FocusWrapper } from "block-system/components/FocusWrapper";
import { BlockId } from "block-system/types";
import Blocks from "components/Blocks";
import { ReCAPTCHA } from "components/ReCAPTCHA";
import { AlertCircle } from "lucide-react";
import React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { FormData } from ".";
import { FormBlock as FormBlockType } from "../schema";
import { FormButton } from "@/block-system/components/forms/FormButton";
import { FormContainer } from "@/block-system/components/forms/FormContainer";

export function Form({
  onSubmit,
  isDisabled,
  config,
  fieldBlocksToRender,
  blockId,
  actionIsFocused,
  setRecaptchaToken,
  setRecaptchaKey,
  isEditing,
  isPaidUser,
}: {
  onSubmit: SubmitHandler<FormData>;
  isDisabled: boolean;
  config: FormBlockType["config"];
  fieldBlocksToRender: FormBlockType["children"];
  blockId: BlockId;
  actionIsFocused: boolean;
  setRecaptchaToken: (token: string) => void;
  setRecaptchaKey: (key: number) => void;
  isEditing: boolean;
  isPaidUser: boolean;
}) {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useFormContext();

  // Customers have requested that pressing enter in a form field should not submit the form
  const preventFormSubmissionOnEnter = (
    e: React.KeyboardEvent<HTMLFormElement>
  ) => {
    const target = e.target;
    if (e.key === "Enter" && target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  };

  const renderPhishingWarning = !isPaidUser;

  return (
    <form
      aria-label="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={preventFormSubmissionOnEnter}
    >
      <FormContainer as="fieldset" disabled={isDisabled}>
        <Blocks blocks={fieldBlocksToRender} bare parentId={blockId} />

        {config.captchaEnabled ? (
          <Field
            error={errors.captcha?.message?.toString()}
            label="Captcha (required)"
            renderInput={() => (
              <ReCAPTCHA
                action="SUBMIT_FORM_BLOCK"
                onChange={(token) => {
                  setRecaptchaToken(token);
                }}
                onRendered={(key) => {
                  setRecaptchaKey(key);
                }}
              />
            )}
          />
        ) : null}

        {renderPhishingWarning ? (
          <BlockAlert
            data-testid={"form-phishing-warning"}
            title={isEditing ? "Upgrade to remove this message" : undefined}
            Icon={AlertCircle}
            description="Please avoid sharing sensitive data such as passwords or credit card info in this form. This is not a secure login or billing page to share that data."
          />
        ) : null}

        <FocusWrapper isFocused={actionIsFocused} focusedBlockType="Button">
          <FormButton type="submit" isLoading={isSubmitting}>
            {config.submitText}
          </FormButton>
        </FocusWrapper>
      </FormContainer>
    </form>
  );
}
