import { FieldBlock } from "../../types";
import { isValid } from "date-fns";
import { isNumeric } from "utils/isNumeric";
import {
  FieldBlockConditionalLogicItem,
  FieldBlockConditionalLogicItemSchema,
} from "../../schema";
import { getAllowedOperatorsForField } from "./field";
import {
  isValidEmptyValueForCondition,
  isValidValueForCondition,
} from "./condition";

export function isValidValueForFieldType(
  value: unknown,
  type: FieldBlock["config"]["inputType"]
) {
  switch (type) {
    case "number":
    case "currency":
      return (
        value === undefined ||
        typeof value === "number" ||
        (typeof value === "string" && isNumeric(value))
      );
    case "dropdown":
      return (
        value === undefined ||
        typeof value === "string" ||
        (Array.isArray(value) && value.every((v) => typeof v === "string"))
      );
    case "checkbox":
    case "yes-no":
      return typeof value === "boolean";
    case "date-picker":
      return isValid(new Date(value as Date));
    case "file-upload":
      return typeof value !== "boolean" && typeof value !== "number";
    default:
      return value === null || value === undefined || typeof value === "string";
  }
}
export function getConditionalLogicValidationError(
  condition: FieldBlockConditionalLogicItem,
  supportedFields: FieldBlock[]
) {
  const fieldBlock = supportedFields.find(
    (f) => f.config.id === condition.fieldId
  );
  if (!fieldBlock) return "Field not found";

  const parseResult = FieldBlockConditionalLogicItemSchema.safeParse(condition);
  if (parseResult.success === false) {
    return parseResult.error.issues[0].message;
  }

  const allowedOperators = getAllowedOperatorsForField(fieldBlock);

  if (!allowedOperators.includes(condition.operator)) {
    return "Invalid operator for field";
  }

  if (
    !isValidEmptyValueForCondition(condition, fieldBlock) &&
    !isValidValueForCondition(condition.value, fieldBlock)
  ) {
    return "Invalid value for condition";
  }

  return ""; // no error message
}
