import React, { useEffect, useRef, useState } from "react";

import { cn } from "utils/cn";
import { MarkdownTextBlock } from "../types";
import { mergeRefs } from "lib/utils/mergeRefs";
import { Markdown } from "block-system/components/Markdown";

type MarkdownTextProps = MarkdownTextBlock["config"];

export const MarkdownText = React.forwardRef<HTMLDivElement, MarkdownTextProps>(
  function MarkdownText(props, forwardedRef) {
    const ref = useRef<HTMLDivElement>(null);
    const mergedRef = mergeRefs(forwardedRef, ref);

    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    useEffect(() => {
      if (ref.current) {
        setIsEmpty(!ref.current.innerText);
      }
    }, [props.content]);

    return (
      <div
        ref={mergedRef}
        className={cn({
          "text-center": props.textAlign === "center",
          "pointer-events-none absolute opacity-0": isEmpty,
        })}
      >
        <Markdown openInSameTab={props.openLinksInSameTab}>
          {props.content}
        </Markdown>
      </div>
    );
  }
);
