// Side nav drawer focus
// -----------------------------------------------------------------------------

export const SETTINGS_DRAWER_TYPES = [
  "settings-general",
  "settings-theme",
  "settings-branding",
  "settings-customDomain",
  "settings-tracking",
  "settings-access",
  "settings-navigation",
  "settings-billing",
] as const;

export type SettingsType = (typeof SETTINGS_DRAWER_TYPES)[number];

export const SIDE_NAV_DRAWER_TYPES = [
  "zaps",
  "integrations",
  ...SETTINGS_DRAWER_TYPES,
] as const;

export type SideNavDrawerType = (typeof SIDE_NAV_DRAWER_TYPES)[number];

export function isSideNavDrawerType(str: string): str is SideNavDrawerType {
  return SIDE_NAV_DRAWER_TYPES.includes(str as SideNavDrawerType);
}

export type SideNavDrawerFocus = {
  sideNavDrawer: SideNavDrawerType;
};

// Block focus
// -----------------------------------------------------------------------------

export type BlockId = string;

export type FormAutomationFocusStateData =
  | {
      panel: "automation" | "automation-new";
    }
  | {
      panel: "automation-edit-blockAction";
      blockActionIndex: number;
    }
  | {
      panel: "automation-edit-zap";
      zapId: string;
    };

export type FormFocusStateData =
  | {
      panel: "fields" | "data" | "new-field";
    }
  | FormAutomationFocusStateData;

export type FieldFocusStateData = { panel: "field" | "logic" };

export type FilterFocusStateData =
  | { panel: "filter"; filterId: number }
  | { panel: "filter-new" };

export type TableFocusStateData =
  | { panel: "setup" | "data" }
  | FilterFocusStateData;

export type MediaFocusStateData = { panel: "setup" };

export type ButtonFocusStateData =
  | { panel: "setup" | "actions" }
  | { panel: "actions-edit"; blockActionIndex?: number };

export type ChecklistFocusStateData =
  | {
      panel: "content" | "data" | "automation" | "automation-new";
    }
  | {
      panel: "automation-edit-zap";
      zapId: string;
    }
  | FilterFocusStateData;

export type StripePaymentActionsFocusStateData =
  | {
      panel: "stripe-actions" | "stripe-actions-new";
    }
  | {
      panel: "stripe-actions-edit-blockAction";
      blockActionIndex: number;
    }
  | {
      panel: "stripe-actions-edit-zap";
      zapId: string;
    };

export type StripePaymentFocusStateData =
  | StripePaymentActionsFocusStateData
  | {
      panel: "content";
    };

export type FocusStateData =
  | FormFocusStateData
  | FieldFocusStateData
  | TableFocusStateData
  | MediaFocusStateData
  | ButtonFocusStateData
  | ChecklistFocusStateData
  | StripePaymentFocusStateData;

export type BlockFocus = {
  blockId: BlockId;
  data?: FocusStateData;
};

export type FormFocusState = {
  blockId: BlockId;
  data: FormFocusStateData;
};

// Combined focus types
// -----------------------------------------------------------------------------

export type FocusState = BlockFocus | SideNavDrawerFocus | null;

export type SetFocusState = (focusState: FocusState) => void;

// Utilities
// -----------------------------------------------------------------------------

export function blockIsFocused(
  focusState: FocusState | undefined
): focusState is BlockFocus {
  return !!focusState && "blockId" in focusState;
}

export function assertBlockIsFocused(
  focusState: FocusState | undefined
): asserts focusState is BlockFocus {
  if (!blockIsFocused(focusState))
    throw new Error("focusState is not BlockFocus");
}

export function sideNavDrawerIsFocused(
  focusState: FocusState | undefined
): focusState is SideNavDrawerFocus {
  return !!focusState && "sideNavDrawer" in focusState;
}
