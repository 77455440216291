import { Wrapper } from "./components";
import { EditorCallout, Props as EditorCalloutProps } from "./EditorCallout";

export function InformativeBlockErrorState(props: EditorCalloutProps) {
  return (
    <Wrapper className="flex-col items-center p-16">
      <EditorCallout {...props} />
    </Wrapper>
  );
}
