import type { StripePayment as StripePaymentType } from "./types";
import {
  blockNameToType,
  blockType,
  blockTypeDbName,
  blockTypeToName,
} from "./constants";
import { create, copy, map } from "./operations";
import { allowed } from "./lib/allowed";
import { strings } from "block-system/blocks/StripePayment/strings";

export const StripePayment: StripePaymentType = {
  strings,
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  allowed,
  isStandalone: true,
} as const;
