import { trpc } from "utils/trpc";
import { Option, UseChoicesProps } from "./types";

export function useChoices({
  tableId,
  tableFieldId,
  tablesFieldKey,
}: UseChoicesProps): { data: Option[]; isLoading: boolean } {
  /**
   * We are not using Suspense here because From block might render on the server.
   * If we used Suspense here, the fetch would fail, because we do not handle Suspense fetches on the server.
   */
  const getChoicesData = trpc.tableFields.getChoices.useQuery(
    {
      fieldId: tableFieldId,
      tableId: tableId,
    },
    {
      enabled: tableFieldId !== undefined && tablesFieldKey !== undefined,
      /**
       * The number is arbitrary.
       * We have added it in attempt to reduce calls to the Tables API.
       */
      staleTime: 20_000,
      select: (data) => {
        const choices = data.data;
        return mapChoices(choices);
      },
      throwOnError: true,
    }
  );

  /**
   * We are not using Suspense here because From block might render on the server.
   * If we used Suspense here, the fetch would fail, because we do not handle Suspense fetches on the server.
   */
  const getChoicesByFieldIdData =
    trpc.tableRecords.getChoicesByFieldId.useQuery(
      {
        fieldId: tableFieldId,
        tableId: tableId,
      },
      {
        enabled: tableFieldId !== undefined && tablesFieldKey === undefined,
        /**
         * The number is arbitrary.
         * We have added it in attempt to reduce calls to the Tables API.
         */
        staleTime: 20_000,
        select: (data) => {
          const choices = data.data;
          return mapChoices(choices);
        },
        throwOnError: true,
      }
    );

  if (tablesFieldKey !== undefined) {
    const { isInitialLoading: isLoading, data = [] } = getChoicesData;
    return { isLoading, data };
  }

  const { isInitialLoading: isLoading, data = [] } = getChoicesByFieldIdData;
  return { isLoading, data };
}

function mapChoices(
  choices: {
    value: string;
    label: string;
    color?: string | undefined;
  }[]
): Option[] {
  return (
    choices
      .map(
        (choice) =>
          ({
            label: choice.label,
            value: choice.value,
          }) as Option
      )
      // Filter out empty choices. Tables API defaults label to "No label" for empty choices
      .filter((choice) => choice.label !== "" && choice.label !== "No label")
      .sort((a, b) => a.label.localeCompare(b.label))
  );
}
