import { useState, useEffect } from "react";
import { trpc } from "utils/trpc";
import { UPLOAD_SIGNATURE_LIFETIME_MS } from "./constants";
import { getNextRefetch, PollingConfig } from "./getNextRefetch";

// Upload Signature Query
// -----------------------------------------------------------------------------

export function useUploadSignatureQuery(
  args: Parameters<typeof trpc.uploads.getSignature.useQuery>[0],
  // NOTE: I wanted to allow all the options from `useQuery` to be passed in
  // like this:
  //   options?: Parameters<typeof trpc.uploads.getSignature.useQuery>[1]
  // But for some reason that makes the return value `unkown`.
  options?: { enabled?: boolean }
) {
  return trpc.uploads.getSignature.useQuery(args, {
    staleTime: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
    refetchInterval: UPLOAD_SIGNATURE_LIFETIME_MS / 2,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    throwOnError: false,
    ...options,
  });
}

// Upload Status Query
// -----------------------------------------------------------------------------

export function usePollingUploadStatusQuery(uuid: string | undefined | null) {
  const query = trpc.uploads.getStatus.useQuery(
    { uuid: uuid as Exclude<typeof uuid, undefined | null> },
    {
      enabled: !!uuid,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      throwOnError: false,
    }
  );

  const [refetchCount, setRefetchCount] = useState(0);

  // Reset the refetch count when the uuid changes. This indicates that the user
  // is uploading a new file.
  useEffect(() => {
    if (uuid) {
      setRefetchCount(0);
    }
  }, [uuid]);

  // Poll the upload status if the upload is pending.
  useEffect(() => {
    if (query.isSuccess && query.data?.status === "pending") {
      const nextRefetch = getNextRefetch(refetchCount);

      if (nextRefetch === null) return;

      const timeout = setTimeout(() => {
        void query
          .refetch()
          .then(() => {
            setRefetchCount((prev) => prev + 1);
          })
          .catch(() => {
            setRefetchCount((prev) => prev + 1);
          });
      }, nextRefetch);

      return () => clearTimeout(timeout);
    }
  }, [query, query.data?.status, query.isSuccess, refetchCount]);

  return query;
}

// Upload Status Query: This new version supports multi-file uploads and is introduced as part of
// this split flag : "interfaces_multi_file_upload". This should replace the old version when cleaning up the feature flag.
// Even single file uploads should use this new version.
// -----------------------------------------------------------------------------

export function usePollingUploadStatusQuery_New(
  uuids: string[],
  config?: PollingConfig
) {
  const query = trpc.uploads.getStatus_New.useQuery(
    { uuids },
    {
      enabled: uuids.length > 0,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      throwOnError: false,
      refetchInterval: (query) => {
        const hasPendingUploads = query.state?.data?.some(
          (status) => status?.status === "pending"
        );
        if (!hasPendingUploads) {
          return false;
        }

        const allDataFetchesAttempted =
          query.state.dataUpdateCount +
          query.state.errorUpdateCount +
          query.state.fetchFailureCount;

        return getNextRefetch(allDataFetchesAttempted, config) ?? false;
      },
    }
  );

  return query;
}
