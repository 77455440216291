export function IconOptionCircleChecked() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0001 2.16667C7.03091 2.16667 2.16675 7.03084 2.16675 13C2.16675 18.9692 7.03091 23.8333 13.0001 23.8333C18.9692 23.8333 23.8334 18.9692 23.8334 13C23.8334 7.03084 18.9692 2.16667 13.0001 2.16667ZM13.0001 21.6667C8.22258 21.6667 4.33341 17.7775 4.33341 13C4.33341 8.22251 8.22258 4.33334 13.0001 4.33334C17.7776 4.33334 21.6667 8.22251 21.6667 13C21.6667 17.7775 17.7776 21.6667 13.0001 21.6667Z"
        fill="currentColor"
      />
      <path
        d="M13.0002 18.4167C15.9917 18.4167 18.4168 15.9916 18.4168 13C18.4168 10.0085 15.9917 7.58334 13.0002 7.58334C10.0086 7.58334 7.5835 10.0085 7.5835 13C7.5835 15.9916 10.0086 18.4167 13.0002 18.4167Z"
        fill="currentColor"
      />
    </svg>
  );
}
