export const strings = {
  NAME: "Layout",

  DESCRIPTION: "Arrange your components in a two or three column layout.",

  TITLE_FIELD_LABEL: "Title",
  COLUMN_COUNT_FIELD_LABEL: "Columns",
  CHILDREN_FIELD_LABEL: "Components",
  LAYOUT_ITEM_VERTICAL_ALIGNMENT_FIELD_LABEL: "Position",
} as const;
