import { Badge } from "@/block-system/brickz/components/ui/badge";
import { Label } from "@/block-system/brickz/components/ui/Label";
import { cn } from "@/block-system/brickz/lib/utils";
import { LockIcon, SplitIcon, TriangleAlertIcon } from "lucide-react";
import React, { useId, type ReactNode } from "react";

type RenderInputOptions = {
  "aria-describedby"?: string;
  id: string;
  isDisabled: boolean;
  isErrored: boolean;
  isRequired: boolean;
};

type RenderLabelProps = {
  htmlFor: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  label: string;
  requiredText?: React.ReactNode;
};

export type FieldProps = {
  /**
   * Optional error message for the field. If it exists, the field will be
   * considered to have an error.
   */
  error?: string;
  /**
   * The number of help text lines to show (defaults to 1). Any extra text will be
   * hidden behind a "more" button.
   */
  helpTextLineClamp?: number;
  inputId?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label: string;
  renderHelpText?: () => React.ReactNode;
  renderInput: (opts: RenderInputOptions) => React.ReactNode;
  renderLabel?: (labelProps: RenderLabelProps) => React.ReactNode;
  requiredText?: React.ReactNode;
  hideLabel?: boolean;
};

export function Field({
  error,
  inputId,
  isDisabled,
  isRequired,
  label,
  renderHelpText,
  renderInput,
  renderLabel,
  requiredText,
  hideLabel,
}: FieldProps) {
  const customId = useId();
  const id = inputId ?? customId;

  const describedById = `${id}-description`;
  const helpText = renderHelpText && renderHelpText();

  return (
    <div className="flex flex-col gap-y-2">
      {!hideLabel && (
        <div>
          {renderLabel ? (
            renderLabel({
              htmlFor: id,
              isDisabled: isDisabled,
              isErrored: !!error,
              isRequired: isRequired,
              label,
              requiredText,
            })
          ) : (
            <FieldLabel
              htmlFor={id}
              isDisabled={isDisabled}
              isErrored={!!error}
              isRequired={isRequired}
              requiredText={requiredText}
            >
              {error ? (
                <TriangleAlertIcon size={16} className={"mb-[1px]"} />
              ) : null}
              <span>{label}</span>
            </FieldLabel>
          )}
        </div>
      )}
      {renderInput({
        "aria-describedby": helpText || error ? describedById : undefined,
        id,
        isDisabled: !!isDisabled,
        isErrored: !!error,
        isRequired: !!isRequired,
      })}
      {error ? <FieldError>{error}</FieldError> : null}
      {helpText ? <HelpText>{helpText}</HelpText> : null}
    </div>
  );
}

type FieldLabelProps = {
  children: React.ReactNode;
  htmlFor: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  requiredText?: React.ReactNode;
};

export const FieldLabel = ({
  children,
  htmlFor,
  isErrored,
  isRequired,
  requiredText,
}: FieldLabelProps) => {
  return (
    <Label
      className={cn(
        "flex flex-wrap items-center justify-start gap-1 text-sm text-card-foreground",
        {
          "text-destructive": isErrored === true,
        }
      )}
      htmlFor={htmlFor}
    >
      {children}
      {isRequired ? (
        requiredText ? (
          <FieldLabelRequiredText>{requiredText}</FieldLabelRequiredText>
        ) : (
          <FieldLabelRequiredText />
        )
      ) : null}
    </Label>
  );
};

export const ConditionallyRenderedFieldLabel = ({
  label,
  ...props
}: RenderLabelProps) => {
  return (
    <div className={"flex flex-col items-start gap-1"}>
      <FieldLabel {...props}>
        <span>{label}</span>
      </FieldLabel>
      <Badge className={"flex gap-1 bg-primary text-xs"}>
        <SplitIcon size={12} className={"rotate-180"} />
        <span>Conditionally rendered</span>
      </Badge>
    </div>
  );
};

export const ReadOnlyFieldLabel = ({
  label,
  ...labelProps
}: RenderLabelProps) => {
  return (
    <div className={"flex flex-col items-start gap-1"}>
      <FieldLabel {...labelProps}>
        <span>{label}</span>
      </FieldLabel>
      <Badge className={"flex gap-1 bg-primary text-xs"}>
        <LockIcon size={12} />
        <span>Read only</span>
      </Badge>
    </div>
  );
};

const FieldLabelRequiredText = ({
  children = "required",
}: {
  children?: React.ReactNode;
}) => {
  return (
    // `aria-hidden` to prevent it from being read redundantly.
    // The associated `input` field should indicate whether it's
    // required to screen readers.
    <span
      className={cn(
        "ml-0.5 font-normal before:content-['('] after:content-[')']",
        "text-card-foreground-subtle"
      )}
      aria-hidden="true"
    >
      {children}
    </span>
  );
};

export function FieldError(props: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={cn("font-sans text-sm text-destructive", props.className)}
    />
  );
}

export function HelpText({ children }: { children: ReactNode }) {
  return (
    <div
      className={cn(
        "text-sm text-card-foreground-subtle [&_a:visited]:text-inherit [&_a]:underline"
      )}
    >
      {children}
    </div>
  );
}
