import { usePageContext } from "lib/context/page-context";
import dynamic from "next/dynamic";
import { BlockWrapperProps } from "./types";
import { PublishedBlockWrapper } from "./PublishedBlockWrapper";

const EditingBlockWrapper = dynamic(() => import("./EditingBlockWrapper"), {
  ssr: false,
  loading() {
    return null;
  },
});

export default function BlockWrapper(props: BlockWrapperProps) {
  const { isEditing } = usePageContext();

  return !isEditing ? (
    <PublishedBlockWrapper {...props} />
  ) : (
    <EditingBlockWrapper {...props} />
  );
}
