import { set } from "lodash";
import { useMemo } from "react";
import { useConsumer } from "./useConsumer";
import { default as getByPath } from "lodash/get";

export const CURRENT_USER_EMAIL_VARIABLE = "currentUser.email";
export const CURRENT_USER_NAME_VARIABLE = "currentUser.name";
export const CURRENT_USER_AVATAR_VARIABLE = "currentUser.avatar";
export const CURRENT_USER_ID_VARIABLE = "currentUser.id";

const ALLOWED_KEYS = [
  CURRENT_USER_EMAIL_VARIABLE,
  CURRENT_USER_NAME_VARIABLE,
  CURRENT_USER_AVATAR_VARIABLE,
  CURRENT_USER_ID_VARIABLE,
];

export function useConsumerDatasource() {
  const currentUser = useConsumer();

  return useMemo(() => {
    if (!currentUser) return {};
    return ALLOWED_KEYS.reduce((acc, key) => {
      return set(acc, key, getByPath({ currentUser }, key));
    }, {});
  }, [currentUser]);
}
