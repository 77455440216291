import { blockType } from "./constants";
import { EmbedBlockSchema } from "./schema";
import { Embed, EmbedBlock } from "./types";

export function copy(block: EmbedBlock): EmbedBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: Embed["create"] = () => {
  const initialData: EmbedBlock = {
    type: blockType,
    config: {
      source: "code",
      url: "",
      code: "",
      title: "",
      height: "350",
      width: "medium",
    },
  };

  return EmbedBlockSchema.parse(initialData);
};

export const map: Embed["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...EmbedBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
