import * as React from "react";

type BaseProps = Omit<React.ComponentProps<"textarea">, "onChange" | "value">;

import { cn } from "@/block-system/brickz/lib/utils";

export type TextAreaProps = BaseProps & {
  value?: string;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  disableResize?: boolean;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      disableResize,
      onChange,
      isErrored,
      isDisabled,
      disabled,
      isRequired: _isRequired,
      ...props
    },
    ref
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
      event
    ) => {
      onChange?.(event.currentTarget.value);
    };

    const inputDisabled = disabled || isDisabled;

    return (
      <textarea
        className={cn(
          "flex rounded-md bg-transparent px-3 py-2.5 ring-offset-background",
          "min-h-20 w-full",
          "border border-input",
          "text-sm",
          "placeholder:text-muted-foreground",
          "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
          {
            "resize-none": disableResize,
            "resize-y": !disableResize,
            "border-destructive": isErrored,
            "cursor-not-allowed opacity-50": inputDisabled,
          },
          className
        )}
        onChange={handleChange}
        value={props.value}
        ref={ref}
        disabled={inputDisabled}
        {...props}
        // Auto resize textarea: https://github.com/shadcn-ui/ui/issues/2863#issuecomment-2404276488
        onInput={(e) => {
          if (disableResize) {
            return;
          }
          const target = e.target as HTMLTextAreaElement;
          target.style.height = "0px";
          target.style.height = target.scrollHeight + "px";
        }}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
