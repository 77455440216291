import { has, get, clone } from "lodash";
import { buildTemplateKeyRegex } from "./buildTemplateKeyRegex";

export const REGEXP = buildTemplateKeyRegex();

export function parseHandlebars(
  content: string,
  datasource: Record<string, any>
): string {
  if (content === "") {
    return content;
  }

  return content.replace(REGEXP, (...args) => {
    const queryKey = args[1] + "." + args[2];
    const defaultValue = args[3];

    let value: string | undefined;
    // Check if queryKey is present on the query object to prevent access to
    // internal object properties.
    if (has(datasource, queryKey)) {
      const rawValue = get(datasource, queryKey);
      value = Array.isArray(rawValue) ? rawValue.join(", ") : rawValue;
    }

    return value ?? defaultValue ?? "";
  });
}

export function parseHandlersInObject<
  T extends Record<string, any> | undefined,
>(
  config: T,
  datasource: Record<string, any>,
  supportedFields?: (string | number | symbol)[]
): T {
  if (config === undefined) return config;

  return Object.entries(config).reduce((acc, [key, value]) => {
    if (
      (!supportedFields && typeof value === "string") ||
      (supportedFields && supportedFields.includes(key as keyof T))
    ) {
      if (typeof value !== "string") {
        throw new Error("only string fields can be dynamic");
      }

      return {
        ...acc,
        [key]: parseHandlebars(value, datasource),
      };
    }
    return acc;
  }, clone(config));
}
