import { useIsShadcnEnabled } from "@/lib/context/split-context";
import { useInterfacesTheme } from "@/lib/theme/ThemeProvider";

/**
 * This hook lives in a separate file to avoid circular dependencies.
 * As soon as we remove the `useInterfacesTheme` hook from here,
 * we can move it to `split-context.tsx`.
 */
export function useShadcnComponentsEnabled() {
  const shadcnEnabled = useIsShadcnEnabled();
  const interfacesTheme = useInterfacesTheme();

  return interfacesTheme != null && shadcnEnabled === true;
}
