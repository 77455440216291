import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";

import { type Chatbot as ChatbotType } from "./types";
import { create, copy, map } from "./operations";

export const Chatbot: ChatbotType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  isStandalone: true,
} as const;
