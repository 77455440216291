import {
  Alignment,
  HorizontalAlignment,
  VerticalAlignment,
} from "block-system/blocks/__shared__/components/AlignmentField/schema";
import { ReactNode } from "react";

export function LayoutItem({
  item,
}: {
  item: { child: ReactNode; alignment: Alignment; colspan: number };
}) {
  const childAlignment = getFlexHorizontalAlignmentClass(
    item.alignment.horizontal
  );
  const selfAlignment = getGridVerticalAlignmentClass(item.alignment.vertical);

  return (
    <div
      className="layout-block-item flex justify-center [&_iframe]:w-full"
      style={{
        gridColumn: `span ${item.colspan}`,
        alignItems: childAlignment,
        alignSelf: selfAlignment,
      }}
    >
      {item.child}
    </div>
  );
}

function getFlexHorizontalAlignmentClass(alignment: HorizontalAlignment) {
  switch (alignment) {
    case "center":
      return "center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "center";
  }
}

function getGridVerticalAlignmentClass(alignment: VerticalAlignment) {
  switch (alignment) {
    case "top":
      return "start";
    case "center":
      return "center";
    case "bottom":
      return "end";
    default:
      return "center";
  }
}
