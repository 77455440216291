import { Divider } from "./Divider";
import { Field } from "./Form/Field";
import { Form } from "./Form/Form";
import { Kanban } from "./Kanban";
import { Links } from "./Links/Links";
import { LinksCard } from "./Links/LinksCard";
import { MarkdownText } from "./MarkdownText";
import { Media } from "./Media";
import { Table } from "./Table";
import { Embed } from "./Embed";
import { Chatbot } from "./Chatbot";
import { Layout } from "./Layout";
import { ContentBlock, ContentBlockTypes } from "block-system/types";
import { Block } from "./__shared__/types";
import { ButtonBlockDefinition } from "./Button";
import { Checklist } from "./Checklist";
import { StripePayment } from "./StripePayment";
import { LoginForm } from "./LoginForm";

export {
  Divider,
  Field,
  Form,
  Kanban,
  Links,
  LinksCard,
  MarkdownText,
  Media,
  Table,
  Embed,
  Chatbot,
  Checklist,
  ButtonBlockDefinition,
  Layout,
  StripePayment,
  LoginForm,
};

/**
 * We use the below map to also show the items in the Add Content sidepanel and also the
 * items in the Page zero state.
 * Ref: components/PageEditor/Layout/SideNav/Drawer/AddContent/thumbnails.ts
 * Ref: components/PageZeroState/PageZeroState.tsx
 *
 * The value of `null` for sidePanelOrder and zeroStateOrder means that the block
 * should not be shown in the sidepanel or zero state.
 */
export const ContentBlockTypesMap: Record<
  ContentBlockTypes,
  Block<ContentBlock> & {
    modalItemOrder: number | null;
    zeroStateOrder: number | null;
  }
> = {
  "stripe-payment-block": {
    ...StripePayment,
    modalItemOrder: 0,
    zeroStateOrder: 0,
  },
  "markdown-text-block": {
    ...MarkdownText,
    modalItemOrder: 1,
    zeroStateOrder: 1,
  },
  "form-block": { ...Form, modalItemOrder: 2, zeroStateOrder: 2 },
  "table-listing-block": { ...Table, modalItemOrder: 3, zeroStateOrder: 4 },
  "kanban-block": { ...Kanban, modalItemOrder: 4, zeroStateOrder: 5 },
  "chatbot-block": { ...Chatbot, modalItemOrder: 5, zeroStateOrder: 6 },
  "links-block": { ...Links, modalItemOrder: 6, zeroStateOrder: 7 },
  "media-block": { ...Media, modalItemOrder: 7, zeroStateOrder: 8 },
  "embed-block": { ...Embed, modalItemOrder: 8, zeroStateOrder: 3 },
  "button-block": {
    ...ButtonBlockDefinition,
    modalItemOrder: 9,
    zeroStateOrder: 9,
  },
  "layout-block": { ...Layout, modalItemOrder: 10, zeroStateOrder: 10 },
  "divider-block": { ...Divider, modalItemOrder: 11, zeroStateOrder: 11 },
  "checklist-block": {
    ...Checklist,
    modalItemOrder: 12,
    zeroStateOrder: 12,
  },
  "field-block": { ...Field, modalItemOrder: null, zeroStateOrder: null },
  "links-card-block": {
    ...LinksCard,
    modalItemOrder: null,
    zeroStateOrder: null,
  },
  "login-form-block": {
    ...LoginForm,
    modalItemOrder: null,
    zeroStateOrder: null,
  },
};

export const blockNamesToTypes = {
  ...Divider.blockNameToType,
  ...Field.blockNameToType,
  ...Form.blockNameToType,
  ...Kanban.blockNameToType,
  ...Links.blockNameToType,
  ...LinksCard.blockNameToType,
  ...MarkdownText.blockNameToType,
  ...Media.blockNameToType,
  ...Table.blockNameToType,
  ...Embed.blockNameToType,
  ...ButtonBlockDefinition.blockNameToType,
  ...Checklist.blockNameToType,
  ...Layout.blockNameToType,
  ...StripePayment.blockNameToType,
  ...LoginForm.blockNameToType,
} as const;

export const blockTypesToNames = {
  ...Divider.blockTypeToName,
  ...Field.blockTypeToName,
  ...Form.blockTypeToName,
  ...Kanban.blockTypeToName,
  ...Links.blockTypeToName,
  ...LinksCard.blockTypeToName,
  ...MarkdownText.blockTypeToName,
  ...Media.blockTypeToName,
  ...Table.blockTypeToName,
  ...Embed.blockTypeToName,
  ...ButtonBlockDefinition.blockTypeToName,
  ...Checklist.blockTypeToName,
  ...Layout.blockTypeToName,
  ...StripePayment.blockTypeToName,
  ...LoginForm.blockTypeToName,
} as const;
