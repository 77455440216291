import React from "react";

type EmbedState = {
  isEmbed?: boolean;
  isPopup?: boolean;
  noBackground?: boolean;
};

const EmbedContext = React.createContext<EmbedState | undefined>(undefined);

export function useEmbed() {
  const embed = React.useContext(EmbedContext);

  return (
    embed ?? {
      isEmbed: undefined,
      isPopup: undefined,
      noBackground: undefined,
    }
  );
}

export function EmbedProvider({
  children,
  embed,
}: {
  children: React.ReactNode;
  embed?: EmbedState;
}) {
  return (
    <EmbedContext.Provider value={embed}>{children}</EmbedContext.Provider>
  );
}
