import { BlockActionType as DbBlockActionType } from "@prisma/client";
import { BLOCK_ACTION_TYPES } from "./constants";
import { BlockActionType } from "block-system/types/actions";

export function blockActionTypeDbToJson(
  input: DbBlockActionType
): BlockActionType {
  for (const [dbType, jsonType] of Object.entries(BLOCK_ACTION_TYPES)) {
    if (input === dbType) {
      return jsonType;
    }
  }
  throw new Error(`Unknown block action type: ${input}`);
}
