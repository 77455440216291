import { useShadcnComponentsEnabled } from "@/block-system/brickz/lib/shadcn";
import { LinksProps, ShadcnLinks } from "./ShadcnLinks";

export function Links(props: LinksProps) {
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();

  if (shadcnComponentsEnabled) {
    return <ShadcnLinks {...props} />;
  }
  return <LegacyLinks {...props} />;
}

export function LegacyLinks({ title, children }: LinksProps) {
  return (
    <div>
      {title ? (
        <>
          <div className="text-3xl font-extrabold text-foreground">{title}</div>
          <div className="h-5" />
        </>
      ) : null}
      <div className="ml-[-10px] grid grid-cols-[repeat(auto-fit,_346px)] justify-center gap-8">
        {children}
      </div>
    </div>
  );
}
