import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/block-system/brickz/lib/utils";
import { forwardRef, useId } from "react";
import { Label } from "./Label";

type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> & {
  isErrored?: boolean;
};

export const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, isErrored, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-3.5 w-3.5 shrink-0 rounded-[2px] border border-solid border-input focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
      className,
      {
        "border-destructive": isErrored,
      }
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="h-3.5 w-3.5" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

type BaseProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "onChange" | "value"
>;

export type CheckboxInputProps = BaseProps & {
  id?: string;
  value?: boolean;
  label?: string;
  text: string;
  defaultChecked?: boolean;
  onChange?: (value: boolean) => void;
  name: string;
  isErrored?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
};

const CheckboxInput = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxInputProps
>(
  (
    {
      value,
      onChange,
      text,
      label,
      isDisabled = false,
      disabled = false,
      /**
       * TypeScript does not check excess properties, so we have to ignore this one.
       * We have annotated it in the type so we can destructure it.
       */
      isRequired: _isRequired,
      id: providedId,
      defaultChecked,
      isErrored = false,
      isRequired = false,
      ...props
    },
    ref
  ) => {
    const handleChange = (checked: boolean) => {
      return onChange?.(checked);
    };

    const checkboxDisabled = disabled || isDisabled;
    const checkboxErrored = isErrored;

    const customId = useId();
    const id = providedId ?? customId;

    return (
      <div
        className={cn("flex gap-2", {
          "items-top": !!label,
          "items-center": !label,
        })}
      >
        <Checkbox
          {...props}
          id={id}
          ref={ref}
          disabled={checkboxDisabled}
          checked={value ?? defaultChecked}
          onCheckedChange={handleChange}
          isErrored={checkboxErrored}
        />
        <div className="text-3.5 grid gap-1.5 text-card-foreground">
          <CheckboxInputLabel
            label={label}
            text={text}
            checkboxId={id}
            isErrored={isErrored}
            isRequired={isRequired}
          />
        </div>
      </div>
    );
  }
);

CheckboxInput.displayName = "CheckboxInput";

function CheckboxInputLabel({
  label,
  text,
  checkboxId,
  isErrored,
  isRequired,
}: {
  label?: string;
  text: string;
  checkboxId: string;
  isErrored: boolean;
  isRequired?: boolean;
}) {
  if (!label) {
    return (
      <Label
        htmlFor={checkboxId}
        data-errored={isErrored}
        className="data-[errored=true]:text-destructive"
      >
        <span className="text-sm font-medium">{text}</span>
        {isRequired ? (
          <span className="ml-0.5 text-sm font-normal text-card-foreground-subtle">
            {" "}
            (required)
          </span>
        ) : null}
      </Label>
    );
  }

  return (
    <Label
      htmlFor={checkboxId}
      data-errored={isErrored}
      className="-mt-1 data-[errored=true]:text-destructive"
    >
      <div>
        <span className="text-sm font-medium">{label}</span>
        {isRequired ? (
          <span className="ml-0.5 text-sm font-normal text-card-foreground-subtle">
            {" "}
            (required)
          </span>
        ) : null}
      </div>
      {text ? (
        <span className="text-sm font-normal text-card-foreground-subtle">
          {text}
        </span>
      ) : null}
    </Label>
  );
}

export { CheckboxInput };
