import {
  CURRENT_USER_EMAIL_VARIABLE,
  CURRENT_USER_ID_VARIABLE,
  CURRENT_USER_NAME_VARIABLE,
} from "block-system/blocks/MarkdownText/hooks/useConsumerDatasource";
import { FieldTypes } from "./types";

const dynamicPlaceholders = [
  `{{${CURRENT_USER_NAME_VARIABLE}}}`,
  `{{${CURRENT_USER_EMAIL_VARIABLE}}}`,
  `{{${CURRENT_USER_ID_VARIABLE}}}`,
];

export function isTextFieldType(fieldType: FieldTypes) {
  return fieldType === "text" || fieldType === "textarea";
}

export function isEmailFieldType(fieldType: FieldTypes) {
  return fieldType === "email";
}

export function isDynamicVariable(value: string) {
  if (dynamicPlaceholders.includes(value)) return true;
  return false;
}

export function isAllowedDynamicDefaultValueForField(
  type: FieldTypes,
  value: string
) {
  if (!isDynamicVariable(value)) return false;

  if (isTextFieldType(type)) {
    return true;
  }

  if (
    isEmailFieldType(type) &&
    value === `{{${CURRENT_USER_EMAIL_VARIABLE}}}`
  ) {
    return true;
  }

  return false;
}
