export function IconOptionSquareChecked() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 22.75H22.75V3.25H3.25V22.75ZM19.1858 10.5192L11.9167 17.7775L7.35583 13.2275L8.89417 11.6892L11.9167 14.7225L17.6475 8.98083L19.1858 10.5192Z"
        fill="currentColor"
      />
    </svg>
  );
}
