import { useCallback } from "react";
import * as immutable from "object-path-immutable";

/**
 * useCrudableList
 *
 * Have you ever found yourself with a list that gets updated with a single
 * onChange function and thought, "Oh this again :facepalm:". Well facepalm no
 * more! Provide this hook with your list and your onChange handler, and it will
 * provide you with all the callbacks you need to immutably create, reorder,
 * update, and destory items in your list.
 *
 */
export function useCrudableList<Item>(
  items: Item[],
  {
    onChange,
    onDelete,
    onUpdate,
    onAdd,
  }: {
    onChange: (items: Item[]) => Promise<void>;
    onDelete?: (item: Item) => Promise<void>;
    onUpdate?: (item: Item) => Promise<void>;
    onAdd?: (item: Item) => Promise<void>;
  }
) {
  /**
   * Adds a new item to the list at the specified index. If no index is
   * provided, the new item is appended to the end of the list.
   */
  const addItem = useCallback(
    async (item: Item, index?: number) => {
      if (index !== undefined && (0 > index || index >= items.length))
        throw new Error("No item at that index");

      if (onAdd) await onAdd(item);
      await onChange(
        immutable.insert(items, undefined, item, index ?? items.length)
      );
    },
    [items, onChange, onAdd]
  );

  /**
   * Moves an item from one index to another.
   */
  const reorderItem = useCallback(
    async (from: number, to: number) => {
      if (0 > from || from >= items.length)
        throw new Error("No item at that index");

      if (0 > to || to >= items.length)
        throw new Error("No item at that index");

      const item: Item = items[from];

      let newItems: Item[] = items;
      newItems = immutable.del(newItems, [from]);
      newItems = immutable.insert(newItems, undefined, item, to);

      await onChange(newItems);
    },
    [items, onChange]
  );

  /**
   * Updates an item in place.
   */
  const updateItem = useCallback(
    async (item: Item, index: number) => {
      if (0 > index || index >= items.length)
        throw new Error("No item at that index");

      if (onUpdate) await onUpdate(item);
      await onChange(immutable.set(items, [index], item));
    },
    [items, onChange, onUpdate]
  );

  /**
   * Deletes an item at the specified index.
   */
  const deleteItem = useCallback(
    async (index: number) => {
      const item = immutable.get(items, [index], null);
      if (!item) throw new Error("No item at that index");
      if (onDelete) await onDelete(item);
      await onChange(immutable.del(items, [index]));
    },
    [items, onChange, onDelete]
  );

  return {
    items,
    addItem,
    reorderItem,
    updateItem,
    deleteItem,
  } as const;
}
