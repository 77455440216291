import { z, ZodSchema } from "zod";

import type { Operation } from "server/auth/types";

import type { Checklist, ChecklistBlock } from "../types";

export const allowed: Checklist["allowed"] = (block) => {
  if (!block.config.tableId) {
    return [];
  }

  const operations: Operation[] = [
    {
      type: "query",
      path: "blockChecklist.getFields",
      validator: z.object({
        checklistId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
      }),
    },
    {
      type: "query",
      path: "blockChecklist.queryRecords",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        checklistId: z.literal(block.config.id),
      }),
    },
  ];

  const canCreateListItems = block.config.canCreateListItems;
  const canEditListItems = block.config.canEditListItems;
  const canDeleteListItems = block.config.canDeleteListItems;
  const canViewListItemDetails = block.config.canViewDetails;

  if (canViewListItemDetails) {
    operations.push({
      type: "query",
      path: "blockChecklist.getListItemDetails",
      validator: z.object({
        checklistId: z.literal(block.config.id),
        recordId: z.string(),
      }),
    });
  }

  if (canCreateListItems) {
    operations.push({
      type: "mutation",
      path: "blockChecklist.createRecord",
      validator: z.object({
        checklistId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
        body: z.object({
          new_records: z
            .object({
              data: fieldInEditableFields(block.config),
            })
            .array(),
          change_options: z.record(z.unknown()).optional(),
        }),
      }),
    });
  }

  if (canEditListItems) {
    operations.push({
      type: "mutation",
      path: "blockChecklist.updateRecord",
      validator: z.object({
        checklistId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
        body: z.object({
          updated_records: z
            .object({
              data: fieldInEditableFields(block.config),
            })
            .array(),
          change_options: z.record(z.unknown()).optional(),
        }),
      }),
    });
  }

  if (canDeleteListItems) {
    operations.push({
      type: "mutation",
      path: "blockChecklist.deleteRecord",
      validator: z.object({
        checklistId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
      }),
    });
  }

  if (canViewListItemDetails || canCreateListItems) {
    /**
     * The `tableFields.getChoices` query fires
     * when user has a dynamic dropdown field (dropdown with linked table) attached to the checklist block.
     */
    operations.push({
      type: "query",
      path: "tableFields.getChoices",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        fieldId: fieldInDetailFields(block.config),
      }),
    });

    operations.push({
      type: "query",
      path: "tableFields.getChoicesByFieldId",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        fieldId: fieldInDetailFields(block.config),
      }),
    });
  }

  return operations;
};

function fieldInEditableFields(config: ChecklistBlock["config"]): ZodSchema {
  const editableFieldDataIds: string[] = [];

  const titleField = config.listItemTitleFieldId;
  if (titleField) {
    editableFieldDataIds.push(`f${titleField}`);
  }

  const completedField = config.listItemCompletedFieldId;
  if (completedField) {
    editableFieldDataIds.push(`f${completedField}`);
  }

  const labelFieldId = config.listItemLabelFieldId;
  if (labelFieldId) {
    editableFieldDataIds.push(`f${labelFieldId}`);
  }

  const dueDateFieldId = config.listItemDueDateFieldId;
  if (dueDateFieldId) {
    editableFieldDataIds.push(`f${dueDateFieldId}`);
  }

  return z.record(z.unknown()).refine((data) => {
    return Object.keys(data).every((dataFieldId) =>
      editableFieldDataIds.includes(dataFieldId)
    );
  });
}

function fieldInDetailFields(config: ChecklistBlock["config"]) {
  return z.number().refine((fieldId) => {
    return config.listItemDetailFields.includes(fieldId);
  });
}
