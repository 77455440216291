import { useTheme } from "lib/theme";
import { buildTheme } from "lib/theme/ThemeProvider";
import { useCallback, useMemo } from "react";
import { ProjectAppearance } from "server/schemas/projects";

export const useProjectTheme = ({
  projectAppearance = {},
}: {
  projectAppearance?: ProjectAppearance;
} = {}) => {
  const theme = useTheme();

  const userTheme = useMemo(() => {
    return buildTheme({ projectAppearance, currentTheme: theme });
  }, [projectAppearance, theme]);

  return userTheme;
};

export const useGetProjectTheme = () => {
  const theme = useTheme();

  return useCallback(
    (projectAppearance: ProjectAppearance) => {
      return buildTheme({ projectAppearance, currentTheme: theme });
    },
    [theme]
  );
};
