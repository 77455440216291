import { isProduction, isSandbox, isStaging } from "utils/environment";
import { config } from "@/config";
import { SideNavDrawerType } from "block-system/types";
import { AccountPlan } from "../server/schemas/user";

export type TabSlug =
  | "general"
  | "access_and_users"
  | "appearance"
  | "navigation"
  | "custom_domain"
  | "branding"
  | "tracking"
  | "plans_billing";

export const BASE_PATH = "/interfaces";

export function getInterfacesUrl() {
  return config().NEXT_PUBLIC_INTERFACES_BASE_URL;
}

type CustomDomain = string | null | undefined;

export function getPageUrl(
  projectSlug: string,
  pageSlug: string,
  customDomain?: CustomDomain
) {
  let url = publishedPagePath({
    projectSlug,
    pageSlug,
  });
  if (customDomain) {
    url = customDomainPagePath({
      customDomain,
      pageSlug,
    });
  }
  return url;
}

export function customDomainPagePath(props: {
  customDomain: CustomDomain;
  pageSlug: string;
}): string {
  const protocol =
    isProduction || isStaging || isSandbox ? "https://" : "http://";

  return `${protocol}${props.customDomain}/${props.pageSlug}`;
}
export function customDomainProjectPath(props: {
  customDomain: CustomDomain;
}): string {
  const protocol =
    isProduction || isStaging || isSandbox ? "https://" : "http://";

  return `${protocol}${props.customDomain}`;
}

export function publishedPagePath(props: {
  projectSlug: string;
  pageSlug: string;
}): string {
  const protocol =
    isProduction || isStaging || isSandbox ? "https://" : "http://";

  const domain = config().NEXT_PUBLIC_BASE_DOMAIN;
  return `${protocol}${props.projectSlug}.${domain}/${props.pageSlug}`;
}

export function publishedProjectPath(props: { projectSlug: string }): string {
  const protocol =
    isProduction || isStaging || isSandbox ? "https://" : "http://";

  const domain = config().NEXT_PUBLIC_BASE_DOMAIN;
  return `${protocol}${props.projectSlug}.${domain}`;
}

export function publishedProjectUrl(args: {
  projectSlug: string;
  customDomain: CustomDomain;
  customDomainConfigured: boolean | undefined;
}): string {
  if (args.customDomain && args.customDomainConfigured)
    return customDomainProjectPath({ customDomain: args.customDomain });
  return publishedProjectPath({ projectSlug: args.projectSlug });
}

export function projectsPath(props: { accountId?: number }): string {
  const queryString = props.accountId ? `?accountId=${props.accountId}` : "";
  return `${BASE_PATH}${queryString}`;
}

export function projectPath(props: { projectId: string }): string {
  return `${BASE_PATH}/project/${props.projectId}`;
}

export function projectUrl(props: { projectId: string }): string {
  return config().NEXT_PUBLIC_INTERFACES_BASE_URL + projectPath(props);
}

export function chatbotUrl(chatbotId: string): string {
  const chatbotsBase = config().NEXT_PUBLIC_ZAPIER_CHATBOTS_URL;

  return `${chatbotsBase}/${chatbotId}`;
}

export function projectSettingsPath(props: {
  projectId: string;
  tab?: TabSlug;
}): string {
  const queryString = props.tab ? `?tab=${props.tab}` : "";
  return `${projectPath(props)}/settings${queryString}`;
}

export function projectConsumerManagementPath(props: {
  projectId: string;
}): string {
  return `${projectSettingsPath(props)}/users`;
}

export function projectConsumerDomainsManagementPath(props: {
  projectId: string;
}): string {
  return `${projectSettingsPath(props)}/domains`;
}

export function pagePath(props: {
  pageId: string;
  sideNavDrawer?: SideNavDrawerType;
}) {
  const queryString = props.sideNavDrawer
    ? `?sideNavDrawer=${props.sideNavDrawer}`
    : "";
  return `${BASE_PATH}/page/${props.pageId}${queryString}`;
}

export function webComponentAssetsPath(): string {
  return `${
    config().NEXT_PUBLIC_INTERFACES_BASE_URL
  }/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js`;
}

// External
// -----------------------------------------------------------------------------

export function getEditZapUrl(props: {
  rootNodeId: string;
  accountId?: number;
}) {
  return `${config().NEXT_PUBLIC_ZAPIER_API_BASE_URL}/editor/${
    props.rootNodeId
  }?context=${props.accountId}`;
}

export function getTablesIndexUrl(): string {
  return `${config().NEXT_PUBLIC_ZAPIER_TABLES_UI_BASE_URL}/app/tables`;
}

export function getEditTableUrl(props: { tableId: string }): string {
  return `${config().NEXT_PUBLIC_ZAPIER_TABLES_UI_BASE_URL}/app/tables/t/${
    props.tableId
  }`;
}

export const reportBugUrl =
  "https://npsup.zapier.app/contact-us?product=Interfaces&feedback=Bug";

export const featureRequestUrl =
  "https://npsup.zapier.app/contact-us?product=Interfaces&feedback=Feature%20Request";

export const officeHoursUrl =
  "https://zapier-interfaces.zapier.app/weekly-office-hours";

export function getHelpUrl(): string {
  return "https://zapier-interfaces.zapier.app/home";
}

export function getCallUrl(): string {
  return "https://zapier-interfaces.zapier.app/schedule";
}

/**
 * Modeled after the `getZapierBaseUrl` function in the Tables UI codebase,
 * modified to use `config().NEXT_PUBLIC_ZAPIER_API_BASE_URL`.
 * @see https://gitlab.com/zapier/team-fpv/tablesui/-/blob/staging/packages/tablesui/src/api/getZapierBaseUrl.ts#L4
 * @returns The base URL for the Zapier API, or an empty string if we're in development mode
 */
export const getZapierBaseUrl = () => {
  const baseUrl = config().NEXT_PUBLIC_ZAPIER_API_BASE_URL;
  if (
    baseUrl === "https://zapier.com" ||
    baseUrl === "https://zapier-staging.com"
  ) {
    return baseUrl;
  }

  // let our local development proxy kick in to fake out the CORS cookies
  return "";
};

export function getZapierHomeUrl() {
  return `${getZapierBaseUrl()}/app/home`;
}

export const getZapierBillingUrl = () => {
  return `${getZapierBaseUrl()}/app/planbuilder/add-ons`;
};

export const getZapierPricingUrl = () => {
  return `${getZapierBaseUrl()}/app/pricing`;
};

export const getInterfacesBillingUrl = (redirectUrl?: string) => {
  return `${getZapierBillingUrl()}?product=interfaces&redirectUrl=${redirectUrl ?? ""}`;
};

export const getInterfacesFreeBillingUrl = (redirectUrl?: string) => {
  return `${getZapierBillingUrl()}?interfaces=interfaces-free-v1&redirectUrl=${
    redirectUrl ?? ""
  }`;
};

export const getInterfacesPremiumBillingUrl = (redirectUrl?: string) => {
  return `${getZapierBillingUrl()}?interfaces=interfaces-premium-v1&redirectUrl=${
    redirectUrl ?? ""
  }`;
};

export const getInterfacesAdvancedBillingUrl = (redirectUrl?: string) => {
  return `${getZapierBillingUrl()}?interfaces=interfaces-advanced-v1&redirectUrl=${
    redirectUrl ?? ""
  }`;
};

export const getInterfacesBillingUrlForPlan = (
  planName?: AccountPlan,
  redirectUrl?: string
) => {
  if (planName === "advanced") {
    return getInterfacesAdvancedBillingUrl(redirectUrl);
  } else if (planName === "premium") {
    return getInterfacesPremiumBillingUrl(redirectUrl);
  } else if (planName === "free") {
    return getInterfacesFreeBillingUrl(redirectUrl);
  } else {
    return getInterfacesBillingUrl(redirectUrl);
  }
};

export const getInterfacesBillingRedirectUrl = () => {
  return `${getInterfacesUrl()}/upgrade-callback`;
};
