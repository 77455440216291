import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";

import { allowed } from "./lib/allowed";

import type { Kanban as KanbanType } from "./types";
import { create, copy, map } from "./operations";
import { filterUnsupportedKanbanFields } from "./Editor/filterUnsupportedKanbanFields";

export const Kanban: KanbanType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  allowed,
  create,
  map,
  copy,
  filterUnsupportedKanbanFields,
  isStandalone: true,
} as const;
