import cuid from "cuid";

export function slugify(str: string, append?: number): string {
  const slug =
    str
      .trim()
      .normalize("NFKD")
      .toLowerCase()
      .replace(/[^\w\s-]/g, "")
      .replace(/[-\s]+/g, "-")
      // Prevent trailing dashes
      .replace(/\-+$/g, "") || cuid();

  if (append !== undefined) {
    return `${slug}${append}`;
  } else {
    return slug;
  }
}

export function slugifyChangeEvent<
  T extends {
    target: { value: string };
  },
>(e: T): T {
  const newValue: string = e.target.value
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[-\s]+/g, "-")
    .replace(" ", "-")
    .replace(/^-$/, "");

  return {
    ...e,
    target: {
      ...e.target,
      value: newValue,
    },
  };
}

export const slugContainsDenyList: string[] = [
  "www",
  "zapier",
  "login",
  "auth",
];

export const slugExactMatchDenyList: string[] = [
  "services",
  "team",
  "org",
  "company",
  "enterprise",
  "staging",
];

export function slugContainsReservedWord(slug: string): boolean {
  const lowerCaseSlug = slug.toLowerCase();
  if (slugExactMatchDenyList.includes(lowerCaseSlug)) {
    return true;
  }

  return slugContainsDenyList.some((word) => {
    return lowerCaseSlug.includes(word);
  });
}
