import paymentImg from "../../../assets/images/content-block-thumbnails/stripe-payment.png";
import { blockType } from "./constants";
import { BlockImages } from "../__shared__/types";
import { strings } from "block-system/blocks/StripePayment/strings";

export const StripePaymentImages: BlockImages = {
  thumbnail: {
    name: strings.NAME,
    image: paymentImg,
    type: blockType,
  },
};
