import { NavigationLink } from "server/schemas/pages";

export function defaultOpensInNewtab(navLinktype: NavigationLink["type"]) {
  switch (navLinktype) {
    case "page":
      return false;
    case "url":
      return true;
    default:
      const _exhaustiveCheck: never = navLinktype;
      return _exhaustiveCheck;
  }
}
