import { get, set, uniq } from "lodash";
import { useRouter } from "next/router";
import { useMemo, useState, useEffect } from "react";
import { useConsumerDatasource } from "../../block-system/blocks/MarkdownText/hooks/useConsumerDatasource";
import { useDatasourcesContext } from "lib/context/datasources-provider";
import {
  ALLOWED_TEMPLATE_KEY_NAMESPACES,
  buildTemplateKeyRegex,
} from "lib/utils/buildTemplateKeyRegex";

export function useContentDatasource(datasourceIds: string[] = []) {
  const [datasourcesData, setDatsourcesData] = useState({});
  const [externalDatasourcesLoaded, setExternalDatasourcesLoaded] = useState(
    datasourceIds.length === 0
  );

  const consumerDatasource = useConsumerDatasource();
  const router = useRouter();
  const routerParams = useMemo(
    () =>
      Object.keys(router.query).reduce(
        (acc, key) => set(acc, `params.${key}`, router.query[key]),
        {}
      ),
    [router.query]
  );

  const { getDatasource } = useDatasourcesContext();

  const staticInputs = useMemo(
    () => ({
      ...consumerDatasource,
      ...routerParams,
    }),
    [consumerDatasource, routerParams]
  );

  useEffect(() => {
    void Promise.all(
      datasourceIds.map((id) => {
        return getDatasource(id, staticInputs).then((data: any) => {
          setDatsourcesData((prev) => ({ ...prev, [id]: data }));
        });
      })
    ).then(() => setExternalDatasourcesLoaded(true));
  }, [getDatasource, datasourceIds, staticInputs]);

  return useMemo(() => {
    return {
      data: {
        ...consumerDatasource,
        ...routerParams,
        ...datasourcesData,
      },
      externalDatasourcesLoaded,
    };
  }, [
    consumerDatasource,
    routerParams,
    datasourcesData,
    externalDatasourcesLoaded,
  ]);
}

export function getDatasourceDependencies(
  config: Record<string, any>,
  fieldPaths: string[]
): string[] {
  return (
    fieldPaths
      .reduce((acc, fieldPath) => {
        const value = get(config, fieldPath);
        if (typeof value !== "string") return acc;

        // get all the matches from value, then extract the key (first characters before the .) and add it to the accumulator

        const matches = value.match(buildTemplateKeyRegex());
        if (!matches) return acc;

        const getKeyFromMatch = (match: string) =>
          match.replace(/^{{\s*/, "").split(".")[0];

        const dependencies = matches.map(getKeyFromMatch);
        return uniq([...acc, ...dependencies]);
      }, [] as string[])
      // Filter out any non-cuid keys. Using z.string().cuid() didn't work because their regex
      // for it is very loose, and matches `currentUser`.
      .filter((id) => !ALLOWED_TEMPLATE_KEY_NAMESPACES.includes(id))
  );
}
