import { z } from "zod";

import type { ZapOutput as Zap } from "server/schemas/zaps";
import { FormBlockActionSchema } from "block-system/blocks/Form/Form/schema";
import { StripeActionSchema } from "block-system/blocks/StripePayment/schema";

// A union of all actions that can be stored in a block config
// -----------------------------------------------------------------------------

export const BlockActionSchema = z.union([
  FormBlockActionSchema,
  StripeActionSchema,
]);
export type BlockAction = z.infer<typeof BlockActionSchema>;

export type BlockActionType = BlockAction["type"];

/**
 * Since zod either doesn't support extracting all the possible values of `type` from
 * our various blocks, or makes it deceptively difficult, we have to define them
 * explicitly here, and use some craziness below to ensure type safety if there happens
 * to be one missing from this list.
 */
export const BlockActionTypes: Record<BlockActionType, boolean> = {
  navigate: true,
  notification: true,
  openExternalUrl: true,
};

export const BlockActionTypeSchema = z.enum([
  "navigate",
  "notification",
  "openExternalUrl",
]);

const blockActionTypes = Object.values(BlockActionTypeSchema.Values);
for (const [blockActionType] of Object.entries(BlockActionTypes)) {
  if (!blockActionTypes.includes(blockActionType as BlockActionType))
    throw new Error(`Must add ${blockActionType} to BlockActionTypeSchema`);
}

// Zap actions
// i.e. An action-styled data structure representing a zap.
// -----------------------------------------------------------------------------

export type ZapAction = {
  type: "zap";
  zap: Zap;
};

// All actions
// To be consumed by reusable Actions components
// -----------------------------------------------------------------------------

export type Action = BlockAction | ZapAction;

export type ActionType = Action["type"];
