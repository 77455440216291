import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
import {
  ColorsCSS as Colors,
  Shadows,
  Typography,
  ZapierLogo,
} from "@zapier/design-system";
import { useFeatureAccessCheck } from "lib/hooks/useFeatureAccessCheck";
import { styled } from "lib/theme";
import {
  useInterfacesTheme,
  type InterfacesTheme,
} from "lib/theme/ThemeProvider";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { cn } from "utils/cn";

const Wrapper = styled.div<{
  $position: "absolute" | "fixed" | "relative";
  $alignment: "left" | "center" | "right";
}>`
  position: ${({ $position }) => $position};
  display: flex;
  justify-content: ${({ $alignment }) => $alignment ?? "left"};
  ${({ $position }) =>
    $position !== "relative" &&
    `
  right: 20px;
  bottom: 20px;
  `}
`;

const Tag = styled.a<{
  $isLight: boolean;
  $interfacesTheme?: InterfacesTheme | null;
}>`
  height: 26px;
  padding: 0 8px;

  font-family: ${Typography.base.fontFamily};
  font-weight: 500;
  font-size: 13px;
  border-radius: 4px;
  text-decoration: none;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  transition: all 0.14159s;

  ${({ $isLight, $interfacesTheme, theme }) =>
    $isLight
      ? `
  color: ${$interfacesTheme ? "hsl(var(--foreground))" : theme.app.colors.text};
  filter: grayscale(1);
  `
      : `
  color: ${Colors.neutral400};
  background: ${Colors.earth};
  `};

  &:hover {
    transform: scale(1.05);

    ${({ $isLight }) =>
      $isLight
        ? `
    filter: grayscale(0);
    `
        : `
    color: ${Colors.white};
    box-shadow: ${Shadows.elevation6};`}
  }

  span {
    margin-left: 5px;
  }
`;

function PoweredByZapierBody(props: {
  position: "absolute" | "fixed" | "fixed-portal" | "relative";
  alignment?: "left" | "center" | "right";
  hasChatbot?: boolean;
  variant?: "dark" | "light";
}) {
  const cssPosition =
    props.position === "fixed" || props.position === "fixed-portal"
      ? "fixed"
      : props.position;

  const project = useCurrentConsumerProject();
  const hasHideZapierButtonAccess = useFeatureAccessCheck("hideZapierButton", {
    ...project.creator,
    paidFeatureAccess: project.paidFeatureAccess,
  });

  const interfacesTheme = useInterfacesTheme();

  const renderContent = () => {
    const url = props.hasChatbot
      ? "https://zapier.com/ai/chatbot?utm_source=zapier&utm_medium=referral&utm_campaign=zapier-gbl-pnua-link-interface_built_on_zapier_button"
      : "https://zapier.com/interfaces?utm_source=zapier&utm_medium=referral&utm_campaign=zapier-gbl-pnua-link-interface_built_on_zapier_button";
    return (
      <Wrapper $position={cssPosition} $alignment={props.alignment ?? "left"}>
        <Tag
          href={url}
          target="_blank"
          $isLight={props.variant === "light"}
          $interfacesTheme={interfacesTheme}
        >
          Built on
          <span>
            <ZapierLogo
              height={14}
              width={52}
              variant={
                props.variant === "light"
                  ? "wordmark-horizontal"
                  : "wordmark-horizontal-inverse"
              }
            />
          </span>
        </Tag>
      </Wrapper>
    );
  };

  if (
    (hasHideZapierButtonAccess || project.isHidden) &&
    project.hideZapierButton
  )
    return null;

  if (props.position === "fixed-portal" && typeof document !== "undefined") {
    return ReactDOM.createPortal(renderContent(), document.body);
  }

  return renderContent();
}

export default function PoweredByZapier({
  withinContainer,
  ...props
}: {
  position: "absolute" | "fixed" | "fixed-portal" | "relative";
  alignment?: "left" | "center" | "right";
  hasChatbot?: boolean;
  variant?: "dark" | "light";
  withinContainer?: boolean;
  occupySpaceWhenEmpty?: boolean;
}) {
  if (withinContainer) {
    return (
      <Suspense>
        <PoweredByZapierContainer
          occupySpaceWhenEmpty={props.occupySpaceWhenEmpty}
        >
          <PoweredByZapierBody {...props} />
        </PoweredByZapierContainer>
      </Suspense>
    );
  }

  return (
    <Suspense>
      <PoweredByZapierBody {...props} />
    </Suspense>
  );
}

const PoweredByZapierContainer: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div"> & { occupySpaceWhenEmpty?: boolean }
> = ({ occupySpaceWhenEmpty, ...props }) => {
  const interfacesTheme = useInterfacesTheme();

  return (
    <div
      {...props}
      className={cn(
        "border-t-solid border-t py-1 [&_a]:font-sans [&_a]:text-xs [&_a]:font-semibold [&_svg]:h-3",
        {
          "empty:hidden": !occupySpaceWhenEmpty,
          "empty:h-4 empty:shrink-0 empty:py-0": occupySpaceWhenEmpty,
          "border-t-zi-pageBackground bg-zi-pageBackground": !interfacesTheme,
          "border-t-background bg-background": interfacesTheme,
        },
        props.className
      )}
    />
  );
};
