export function IconAppCode({ size = 40 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1102 7H16.5002L20.7002 12L16.5002 17H19.1102L23.3002 12L19.1102 7ZM4.8902 7L0.700195 12L4.8902 17H7.5002L3.3002 12L7.5002 7H4.8902ZM8.0002 20H10.1302L16.0002 4H13.8502L8.0002 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
