import { ReactNode } from "react";
import { Alert, AlertDescription, AlertTitle } from "./alert";
import { LucideIcon } from "lucide-react";

export function BlockAlert({
  title,
  description,
  Icon,
  "data-testid": dataTestId,
}: {
  title?: ReactNode;
  description: ReactNode;
  Icon?: LucideIcon;
  "data-testid"?: string;
}) {
  return (
    <Alert data-testid={dataTestId}>
      {Icon ? <Icon size={20} /> : null}
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
}
