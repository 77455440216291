import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";

import { allowed } from "./lib/allowed";

import type { Checklist as ChecklistType } from "./types";
import { create, copy, map } from "./operations";
import { filterUnsupportedChecklistFields } from "./Editor/filterUnsupportedChecklistFields";

export const Checklist: ChecklistType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  allowed,
  create,
  map,
  copy,
  filterUnsupportedChecklistFields,
  isStandalone: true,
} as const;
