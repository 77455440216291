// Simple function to extract the hostname portion from a URL string
// that doesn't cause exceptions when the URL is not well-formed
// (since values in URL fields may be relative, lack protocol)

export function getHostnameFromUrl(url: string): string | null {
  // If the string contains a '://', capture everything after the protocol
  const protocolSplit = url.split("://");
  const withoutProtocol = protocolSplit.length > 1 ? protocolSplit[1] : url;
  // Remove port specifier, if exists
  const portSplit = withoutProtocol.split(":");
  const withoutPort = portSplit[0];
  // If the remaining string contains a '/', capture everything before the first '/'
  const pathSplit = withoutPort.split("/");
  const hostname = pathSplit[0];

  // Check if it's a relative link
  if (hostname === withoutProtocol && !hostname.includes(".")) {
    return null;
  }

  // Return the captured string
  return hostname;
}
