import type { SupportedCurrencies } from "block-system/blocks/Form/Field/types";
import { renderCurrencyIcon } from "block-system/utilities/currency-helpers";
import { forwardRef } from "react";
import { TextInput, type TextInputProps } from "./TextInput";

type BaseProps = Omit<
  TextInputProps,
  "renderIconBefore" | "inputMode" | "type"
>;

type CurrencyInputProps = BaseProps & {
  currencyFormat: SupportedCurrencies;
};

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ currencyFormat, ...props }, ref) => {
    return (
      <TextInput
        {...props}
        ref={ref}
        renderIconBefore={() => renderCurrencyIcon(currencyFormat)}
        type="text"
      />
    );
  }
);

CurrencyInput.displayName = "CurrencyInput";
