export const strings = {
  NAME: "Stripe Payment",

  DESCRIPTION: "Set up products and payment",

  TITLE_FIELD_LABEL: "Title",
  DESCRIPTION_FIELD_LABEL: "Description",
  VALUE_FIELD_LABEL: "Value",
  BUTTON_LABEL_FIELD_LABEL: "Button label",
  CURRENCY_FIELD_LABEL: "Currency",
  QUANTITY_LIMIT_FIELD_LABEL: "Quantity limit",
  EMOJI_FIELD_LABEL: "Emoji",
  IS_SUBSCRIPTION_FIELD_LABEL: "Is subscription",
  SUBSCRIPTION_INTERVAL_FIELD_LABEL: "Subscription interval",
  ACTION_FIELD_LABEL: "Action",
} as const;
