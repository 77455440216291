import * as loom from "@loomhq/loom-embed";
import { OEmbedInterface } from "@loomhq/loom-embed/dist/.types/oembed";
import React, { useEffect, useState } from "react";

export const LoomVideo = React.memo(
  ({ url, height }: { url: string; height: number }) => {
    const [loomData, setLoomData] = useState<OEmbedInterface>();

    useEffect(() => {
      if (typeof window !== "undefined") {
        loom
          .oembed(url.split("?")[0])
          .then((data) => {
            // Hacky fix because Loom otherwise resizes the width as well when changing height
            data.html = data.html.replace(
              /height:\s\d+%;/,
              `height: ${height}px;`
            );
            setLoomData(data);
          })
          .catch(() => {
            console.error("Invalid Loom URL");
          });
      }
    }, [url, height]);

    return (
      <>
        {loomData?.html ? (
          <div dangerouslySetInnerHTML={{ __html: loomData.html }} />
        ) : null}
      </>
    );
  }
);

LoomVideo.displayName = "LoomVideo";
