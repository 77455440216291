import {
  FileInfo,
  FileUpload as FileUploadType,
  FilesUpload as FilesUploadType,
  Widget,
  WidgetAPI,
} from "@uploadcare/react-widget";
import { forwardRef } from "react";

import type { UploadSignatureOutput, UploadStatusOutput } from "@/utils/trpc";

import { cn } from "@/block-system/brickz/lib/utils";
import {
  AlertCircle,
  X as CloseIcon,
  CloudUpload,
  FileImage,
  FileText,
} from "lucide-react";
import styles from "./FileUploadWidget.styles.module.css";

const Wrapper: React.FunctionComponent<React.ComponentPropsWithRef<"div">> = (
  props
) => {
  return (
    <div
      {...props}
      className={cn(
        "flex h-[80px] items-center justify-between",
        "rounded-md border border-dashed border-input",
        "px-[12px] py-[10px]",
        "focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background",
        styles.container,
        props.className
      )}
    />
  );
};

const Placeholder: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  return (
    <div
      {...props}
      data-testid={"single-file-upload-placeholder"}
      className={cn(
        "flex shrink items-center gap-2",
        "text-sm text-muted-foreground",
        styles.placeholder,
        props.className
      )}
    />
  );
};

type Props = {
  placeholder?: string;
  value?: FileInfo | null;
  allowedFileTypes?: string;
  onChange: (value?: FileInfo) => void;
  maxFileSize?: number;
  blockId: string;
  isEditing?: boolean;
  publicKey: string;
  id: string;
  name: string;
  required: boolean;
  fileUploadsDisabled: boolean;
  uploadSignature: UploadSignatureOutput | undefined;
  uploadStatus: UploadStatusOutput | undefined;
  isUploadStatusError: boolean;
  file: FileInfo | undefined;
  fileSelected: boolean;
  onFileSelect?: (fileInfo: FileUploadType | FilesUploadType | null) => void;
  reset: () => void;
  acceptTypes: string;
  widgetRef: React.RefObject<WidgetAPI>;
  fileExtensionValidator: (fileInfo: FileInfo) => void;
  fileSizeValidator: (fileInfo: FileInfo) => void;
};

type FileUploadWidgetProps = Omit<
  Props,
  "name" | "maxFileSize" | "blockId" | "onChange"
>;

export const FileUploadWidget = forwardRef<
  HTMLDivElement,
  FileUploadWidgetProps
>((props, ref) => {
  const {
    placeholder,
    fileUploadsDisabled,
    uploadSignature,
    uploadStatus,
    isUploadStatusError,
    file,
    fileSelected,
    onFileSelect,
    reset,
    acceptTypes,
    widgetRef,
    value,
    fileExtensionValidator,
    fileSizeValidator,
    publicKey,
    ...restProps
  } = props;

  if (fileUploadsDisabled) {
    return (
      <div className={cn("flex gap-2 rounded-md bg-background p-4")}>
        <div className={cn("flex-none text-primary")}>
          <AlertCircle size={24} />
        </div>
        <div className="flex-1">
          File uploads are temporarily disabled and will return shortly. We
          apologize for the inconvenience.
        </div>
      </div>
    );
  }

  if (!uploadSignature && !restProps.isEditing) {
    return (
      <Wrapper>
        <Placeholder>
          <CloudUpload size={16} />
          {placeholder || "Drag and drop files here"}
        </Placeholder>
      </Wrapper>
    );
  }

  const renderFileIcon = () => {
    switch (file?.mimeType) {
      case "image/jpg":
      case "image/jpeg":
        return <FileImage size={16} />;
      default:
        return <FileText size={16} />;
    }
  };

  return (
    <Wrapper ref={ref}>
      <div
        className={cn("flex items-center justify-between", {
          "grow-0": !!file,
          grow: !file,
        })}
      >
        <Placeholder>
          {!fileSelected && (
            <>
              <CloudUpload size={16} />
              {placeholder || "Drag and drop files here"}
            </>
          )}

          {file ? renderFileIcon() : null}
        </Placeholder>

        {file && uploadStatus?.status === "pending" ? (
          <Placeholder>Verifying...</Placeholder>
        ) : null}
        {file && (uploadStatus?.status === "failure" || isUploadStatusError) ? (
          <Placeholder>Failed to upload.</Placeholder>
        ) : null}
        {Boolean(!file || uploadStatus?.status === "success") && (
          <Widget
            ref={widgetRef}
            publicKey={publicKey}
            secureSignature={uploadSignature?.secureSignature}
            secureExpire={
              uploadSignature?.secureExpire
                ? parseInt(uploadSignature?.secureExpire)
                : undefined
            }
            metadata={uploadSignature?.metadata}
            onFileSelect={onFileSelect}
            tabs="file camera url facebook gdrive gphotos"
            localeTranslations={{
              buttons: { choose: { files: { one: "Browse files" } } },
              errors: {
                // @ts-ignore
                fileType: "File type not supported",
                fileMaximumSize: "File size exceeds max",
              },
              serverErrors: {
                SignatureExpirationError:
                  "Upload failed. Please refresh your browser and retry.",
              },
            }}
            value={value?.cdnUrl ?? ""}
            inputAcceptTypes={acceptTypes}
            validators={[fileExtensionValidator, fileSizeValidator]}
          />
        )}
      </div>

      {file ? (
        <CloseIcon size={16} onClick={reset} style={{ cursor: "pointer" }} />
      ) : null}
    </Wrapper>
  );
});

FileUploadWidget.displayName = "FileUploadWidget";
