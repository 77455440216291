import { BlockCreateProps } from "../__shared__/types";
import { blockType } from "./constants";
import { KanbanBlockSchema } from "./schema";
import { Kanban, KanbanBlock } from "./types";

export function copy(block: KanbanBlock): KanbanBlock {
  return {
    ...block,
    stateId: undefined,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: Kanban["create"] = (
  props?: BlockCreateProps<KanbanBlock>
) => {
  const initialConfig = props?.initialConfig ?? {};
  return KanbanBlockSchema.parse({
    type: blockType,
    config: {
      title: props?.initialConfig?.title ?? "My kanban",
      style: {
        width: "wide",
        alignment: "center",
      },
      ...initialConfig,
    },
  });
};

export const map: Kanban["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...KanbanBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
    stateId: block.blockState?.id,
  };
};
