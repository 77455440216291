import img from "assets/images/content-block-thumbnails/text.png";

import { blockType } from "./constants";
import { strings } from "./strings";
import { BlockImages } from "../__shared__/types";

export const MarkdownTextImages: BlockImages = {
  thumbnail: {
    name: strings.NAME,
    image: img,
    type: blockType,
    keywords: strings.KEYWORDS,
  },
};
