export const strings = {
  NAME: "Button",

  DESCRIPTION: "Add a button to trigger an automation or redirect to a page.",
  ACTION_FIELD_LABEL: "Action",
  LABEL_FIELD_LABEL: "Button label",
  TYPE_FIELD_LABEL: "Button type",
  HEIGHT_FIELD_LABEL: "Button height",
  WIDTH_FIELD_LABEL: "Button width",
  ALIGNMENT_FIELD_LABEL: "Button alignment",
  COLOR_FIELD_LABEL: "Button color",
  CUSTOM_COLOR_BACKGROUND_FIELD_LABEL: "Button custom background color",
  CUSTOM_COLOR_TEXT_FIELD_LABEL: "Button custom text color",
} as const;
