/* eslint-disable @next/next/no-img-element */
import { MediaZeroState } from "./MediaZeroState";
import type { MediaBlock } from "../types";

export function ImageBlock({
  isEditing,
  mediaUrl,
  alt,
}: MediaBlock["config"] & { isEditing?: boolean }) {
  return (
    <>
      {mediaUrl ? (
        <img src={mediaUrl} alt={alt ?? ""} />
      ) : isEditing ? (
        <MediaZeroState title="Image" />
      ) : null}
    </>
  );
}
