import Link from "next/link";
import { cn } from "utils/cn";
import { useShadcnComponentsEnabled } from "@/block-system/brickz/lib/shadcn";
import { LinksCardProps, ShadcnLinksCard } from "./ShadcnLinksCard";

function LegacyLinksCard({
  title,
  description,
  linkText,
  emoji,
  href,
  target,
  onClick,
}: LinksCardProps) {
  return (
    <div
      className={cn(
        "flex min-h-[180px] flex-col rounded-large bg-card shadow-md"
      )}
    >
      <div className="flex flex-1 flex-row gap-5 p-5">
        {emoji ? (
          <div
            className={cn([
              "h-12 w-12 border",
              "flex items-center justify-center",
              "text-[26px]",
              "rounded-small bg-background",
            ])}
          >
            {emoji}
          </div>
        ) : null}
        <div className="flex flex-1 flex-col gap-2">
          <div
            className={cn([
              "font-sans text-lg font-semibold text-card-foreground",
            ])}
          >
            {title}
          </div>
          {description ? (
            <div
              className={cn([
                "font-sans text-sm leading-[1.6] text-card-foreground-subtle",
              ])}
            >
              {description}
            </div>
          ) : null}
        </div>
      </div>
      <Link
        href={href}
        target={target}
        onClick={onClick}
        className={cn([
          "flex items-center justify-center",
          "px-4 py-5",
          "bg-secondary text-secondary-foreground",
          "text-[17px] font-medium leading-5",
          "rounded-b-large",
          "transition-colors",
          "[&:not(:disabled):hover]:brightness-95",
        ])}
      >
        <span>{linkText} -&gt;</span>
      </Link>
    </div>
  );
}

export function LinksCard(props: LinksCardProps) {
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();

  if (shadcnComponentsEnabled) {
    return <ShadcnLinksCard {...props} />;
  }
  return <LegacyLinksCard {...props} />;
}
