import { Page } from "utils/trpc";
import { NextRouter } from "next/router";
import { NavigateAction } from "block-system/types";

export async function navigateToPage(
  navigateAction: NavigateAction,
  pages: Page[],
  router: { push: NextRouter["push"] },
  currentWindowUrl = new URL(window.location.href)
) {
  const page = pages?.find((p: Page) => p.id === navigateAction.config?.pageId);
  if (!page) throw new Error("Missing page");

  const isEmbeddedPage = currentWindowUrl.pathname.includes("/_z/embed/page");
  if (!isEmbeddedPage) {
    return await router.push({ pathname: `/${page.slug}` }, undefined, {});
  }

  return await router.push(`/_z/embed/page/${page.id}`, undefined, {});
}
