import { StandardBlockErrorState } from "block-system/components/BlockErrors/StandardBlockErrorState";
import BlockWrapper from "block-system/components/BlockWrapper";
import { BlockId } from "block-system/types";

export function BlockError(props: { blockId: BlockId }) {
  return (
    <BlockWrapper blockId={props.blockId} block={undefined}>
      <StandardBlockErrorState />
    </BlockWrapper>
  );
}
