import { isNull } from "lodash";
import React from "react";
import { Page } from "utils/trpc";

const CurrentPagesContext = React.createContext<Page[] | null>(null);

export function useCurrentPages() {
  const pages = React.useContext(CurrentPagesContext);

  if (isNull(pages)) {
    throw new Error(
      "useCurrentPages must be used within a CurrentPagesProvider"
    );
  }

  return pages;
}

export function CurrentPagesProvider({
  children,
  pages,
}: {
  children: React.ReactNode;
  pages: Page[];
}) {
  return (
    <CurrentPagesContext.Provider value={pages}>
      {children}
    </CurrentPagesContext.Provider>
  );
}
