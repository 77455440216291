import { z, ZodSchema } from "zod";

import type { Operation } from "server/auth/types";

import type { Table } from "../types";

function fieldNotHiddenSchema(hiddenFields: string[] | undefined): ZodSchema {
  return z.record(z.unknown()).refine((data) => {
    if (!hiddenFields) return true;
    return !Object.keys(data).some((field) =>
      hiddenFields.includes(String(field))
    );
  });
}

export const allowed: Table["allowed"] = (block) => {
  if (!block.config.tableId) return [];

  const operations: Operation[] = [
    {
      type: "query",
      path: "tableTables.get",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
      }),
    },
    {
      type: "query",
      path: "tableFields.list",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
      }),
    },
    {
      type: "query",
      path: "tableFields.getChoices",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
      }),
    },
    {
      type: "query",
      path: "blockTable.queryRecords",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        blockId: z.literal(block.config.id),
      }),
    },
    {
      type: "mutation",
      path: "tableFields.interact",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        fieldId: z
          .number()
          .refine(
            (fieldId) => !block.config.hiddenFields?.includes(String(fieldId))
          ),
      }),
    },
    {
      type: "mutation",
      path: "tableRecords.deliverRecordChanges",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        recordId: z.string(),
      }),
    },
    {
      type: "query",
      path: "blockTable.getRecord",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        tableBlockId: z.literal(block.config.id),
        recordId: z.string(),
      }),
    },
  ];

  if (block.config.canCreateRecords) {
    operations.push({
      type: "mutation",
      path: "blockTable.createRecord",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        tableBlockId: z.literal(block.config.id),
        body: z.object({
          new_records: z
            .object({
              data: fieldNotHiddenSchema(block.config.hiddenFields),
            })
            .array(),
          change_options: z.record(z.unknown()).optional(),
        }),
      }),
    });
  }
  if (block.config.canUpdateRecords) {
    operations.push({
      type: "mutation",
      path: "blockTable.updateRecord",
      validator: z.object({
        tableBlockId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
        body: z.object({
          updated_records: z
            .object({
              data: fieldNotHiddenSchema(block.config.hiddenFields),
            })
            .array(),
          change_options: z.record(z.unknown()).optional(),
        }),
      }),
    });
  }
  if (block.config.canDeleteRecords) {
    operations.push({
      type: "mutation",
      path: "blockTable.deleteRecord",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        tableBlockId: z.literal(block.config.id),
      }),
    });
  }

  return operations;
};
