import { blockHelpers } from "block-system/utilities";
import { usePageContext } from "lib/context/page-context";
import {
  BlockDropResult,
  ContentBlockDragItem,
  DragTypes,
  NewContentBlockDragItem,
} from "lib/react-dnd";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;

  > * {
    transition: transform 0.3s !important;
  }
`;

export function BlockDropzone(props: {
  className?: string;
  children: ReactNode;
  disableDrop?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [mouseClientOffsetY, setMouseClientOffsetY] = useState<
    number | undefined
  >(undefined);

  const { addBlock, mutate } = usePageContext();

  const [{ newBlockIsOver }, dropRef] = useDrop<
    NewContentBlockDragItem,
    BlockDropResult,
    { newBlockIsOver: boolean }
  >({
    accept: [DragTypes.NEW_CONTENT_BLOCK_TYPE],
    drop(item) {
      addBlock?.(item.blockType, { insertAt: hoverIndex });
    },

    collect(monitor) {
      return {
        newBlockIsOver: monitor.isOver(),
      };
    },
    canDrop() {
      return !props.disableDrop;
    },
    hover(item, monitor) {
      if (props.disableDrop) return;
      setMouseClientOffsetY(monitor.getClientOffset()?.y);
    },
  });

  const [
    { movingBlockIsOver, movingBlockOffsetHeight, movingBlockStartingIndex },
    drop2Ref,
  ] = useDrop<
    ContentBlockDragItem | null,
    BlockDropResult,
    {
      movingBlockIsOver: boolean;
      movingBlockOffsetHeight?: number;
      movingBlockStartingIndex?: number;
    }
  >({
    accept: [DragTypes.CONTENT_BLOCK],
    drop(item) {
      if (item && hoverIndex != null) {
        mutate?.((blockState) => {
          const clampedIndex = Math.min(blockState.length - 1, hoverIndex);
          return blockHelpers.moveBlock(blockState, item.blockId, clampedIndex);
        });
      }
    },
    hover(item, monitor) {
      if (props.disableDrop) return;
      setMouseClientOffsetY(monitor.getClientOffset()?.y);
    },
    collect(monitor) {
      const item = monitor.getItem();

      return {
        movingBlockIsOver: monitor.isOver(),
        movingBlockOffsetHeight: item?.offsetHight,
        movingBlockStartingIndex: item?.index,
      };
    },
    canDrop() {
      return !props.disableDrop;
    },
  });

  const blockIsOver = newBlockIsOver || movingBlockIsOver;

  const hoverIndex: number | undefined = useMemo(() => {
    if (
      ref.current &&
      blockIsOver &&
      mouseClientOffsetY &&
      !props.disableDrop
    ) {
      const rect = ref.current.getBoundingClientRect();
      const relativeMousePositionY = mouseClientOffsetY - rect.top;

      let i = 0;

      for (i = 0; i < ref.current.children.length; i++) {
        const child = ref.current.children[i] as HTMLElement;
        if (child.offsetTop + child.offsetHeight > relativeMousePositionY)
          break;
      }

      return i;
    }
  }, [blockIsOver, mouseClientOffsetY, props.disableDrop]);

  useEffect(() => {
    if (ref.current) {
      Array.from(ref.current.children).forEach((child, i) => {
        const typedChild = child as HTMLElement;
        if (hoverIndex !== undefined) {
          const translate: number = (() => {
            if (movingBlockOffsetHeight && movingBlockStartingIndex != null) {
              if (hoverIndex === movingBlockStartingIndex) return 0;
              if (hoverIndex < movingBlockStartingIndex) {
                if (i > movingBlockStartingIndex) return 0;
                if (i < hoverIndex) return 0;
                else return movingBlockOffsetHeight;
              }
              if (hoverIndex > movingBlockStartingIndex) {
                if (i < movingBlockStartingIndex) return 0;
                if (i <= hoverIndex) return -movingBlockOffsetHeight;
              }
            } else {
              if (i >= hoverIndex) return 200;
            }

            return 0;
          })();

          typedChild.style.transform = `translateY(${translate}px)`;
        } else {
          typedChild.style.transform = "";
        }
      });
    }
  }, [hoverIndex, movingBlockOffsetHeight, movingBlockStartingIndex]);

  drop2Ref(dropRef(ref));

  return (
    <Wrapper ref={ref} className={props.className}>
      {props.children}
    </Wrapper>
  );
}
