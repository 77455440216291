import BlockWrapper from "block-system/components/BlockWrapper";
import type { LayoutBlock as LayoutBlockType } from "../types";
import { BlockProps } from "block-system/blocks/__shared__/types";
import { Layout } from "./Layout";
import { useProjectTheme } from "lib/hooks/useProjectTheme";

export const LayoutBlock = (props: BlockProps<LayoutBlockType>) => {
  const theme = useProjectTheme();

  return (
    <BlockWrapper
      block={props.block}
      blockId={props.blockId}
      maxWidth={theme.app.width[props.block.config.style?.width ?? "wide"]}
      alignment={props.block.config.style?.alignment}
    >
      <Layout {...props} />
    </BlockWrapper>
  );
};
