import chatbotImg from "assets/images/content-block-thumbnails/chatbot.png";
import { blockType } from "./constants";
import { strings } from "./strings";
import { BlockImages } from "../__shared__/types";

export const ChatbotImages: BlockImages = {
  thumbnail: {
    name: strings.NAME,
    image: chatbotImg,
    type: blockType,
  },
};
