import { ColorsCSS as Colors, Icon, Text } from "@zapier/design-system";
import { styled } from "lib/theme";

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  height: 300px;
  width: 100%;
  background: ${Colors.neutral100};
  border: 1px solid ${Colors.neutral400};

  &:hover {
    border: 1px solid ${Colors.blue600};
    background: #f0f1fa;
  }
`;

export function LayoutItemZeroState() {
  return (
    <Wrapper>
      <Icon name="formAddCircle" size={32} color="blue600" />
      <Text type="paragraph3">Add component</Text>
    </Wrapper>
  );
}
