import { config } from "@/config";

/**
 * Returns a promise that resolves in the specified time.
 */
export function sleep(
  ms: number,
  dangerouslyUseInProduction = false
): Promise<void> {
  if (config().NODE_ENV === "production" && !dangerouslyUseInProduction)
    return Promise.resolve();

  return new Promise((resolve) => setTimeout(resolve, ms));
}
