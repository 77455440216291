/**
 * This component differs from the one ShadCN CLI generates.
 *
 * We had to preserve the functionality of our existing date-picker component.
 * As such, we decided to keep the existing logic but "enhance it" with ShadCN styles.
 */
import { cn } from "@/utils/cn";
import formatDate from "date-fns/format";
import { CalendarIcon } from "lucide-react";
import { createRef, forwardRef, useState, type HTMLProps } from "react";
import ReactDatePicker from "react-datepicker";
import { TextInput } from "./TextInput";
import styles from "./DatePicker.styles.module.css";

interface Props {
  dateFormat: string;
  includeTime: boolean;
  defaultToNow: boolean;
  value?: Date;
  onChange?: (value: Date | null) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  id: string;
  name: string;
}

export const DatePicker = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onChange,
      dateFormat,
      includeTime,
      placeholder = "Pick a date",
      isDisabled,
      isErrored,
      id,
      name,
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    const datePickerRef = createRef<ReactDatePicker>();

    return (
      <div className={styles["date-picker"]}>
        <ReactDatePicker
          ref={datePickerRef}
          wrapperClassName="DatePicker w-[100%]"
          popperClassName="DatePicker-Popper"
          dateFormat={includeTime ? dateFormat + " HH:mm" : dateFormat}
          placeholderText={placeholder}
          selected={value}
          onChange={(date) => onChange?.(date)}
          calendarContainer={({ children, className }) => {
            return (
              <div className={className} data-testid="react-calendar-container">
                {children}
              </div>
            );
          }}
          /**
           * The `id` we set here gets passed to the `DatePickerCustomInput`
           * by the `ReactDatePicker` library.
           */
          id={id}
          isClearable
          customInput={
            <DatePickerCustomInput
              ref={ref}
              isDisabled={isDisabled}
              isErrored={isErrored}
              placeholder={placeholder}
              active={active}
              date={value}
              includeTime={includeTime}
              name={name}
            />
          }
          showMonthDropdown={true}
          showYearDropdown={true}
          clearButtonClassName={cn("after:!bg-foreground after:content-['×']", {
            "after:opacity-50": isDisabled,
          })}
          customTimeInput={
            <TextInput
              size="small"
              type="time"
              aria-label={"Time"}
              placeholder={"Time"}
              className={"w-full"}
            />
          }
          dropdownMode="select"
          showTimeInput={includeTime}
          shouldCloseOnSelect={false}
          onCalendarOpen={() => setActive(true)}
          onCalendarClose={() => setActive(false)}
          disabled={isDisabled}
        />
        {/* We could not close the date-picker in e2es.
        We have created this invisible button to click on it when we are done setting the value on the date-picker. */}
        <button
          data-testid="close-date-picker"
          type="button"
          className={"sr-only"}
          onClick={() => {
            datePickerRef.current?.setOpen(false);
          }}
        >
          Close date picker
        </button>
      </div>
    );
  }
);

DatePicker.displayName = "DatePicker";

const DatePickerCustomInput = forwardRef<
  HTMLInputElement,
  HTMLProps<HTMLInputElement> & {
    isDisabled?: boolean;
    isErrored?: boolean;
    placeholder?: string;
    active?: boolean;
    date?: Date;
    includeTime: boolean;
    name?: string;
  }
>(
  (
    {
      date,
      includeTime,
      onClick,
      isDisabled,
      isErrored,
      placeholder,
      active,
      id,
      name,
    },
    ref
  ) => {
    const getInputValue = () => {
      if (!date) {
        return "";
      }

      return `${formatDate(date, "MMM d, yyyy")} ${
        includeTime ? formatDate(date, "hh:mm a") : ""
      }`;
    };

    return (
      <TextInput
        ref={ref}
        readOnly={true}
        onClick={onClick}
        isErrored={isErrored}
        isDisabled={isDisabled}
        placeholder={placeholder}
        active={active}
        id={id}
        name={name}
        renderIconBefore={() => {
          return <CalendarIcon className={"text-foreground"} size={12} />;
        }}
        value={getInputValue()}
      />
    );
  }
);

DatePickerCustomInput.displayName = "DatePickerCustomInput";
