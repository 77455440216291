import type { FieldBlockConfig } from "block-system/blocks/Form/Field/types";
import { isValid } from "date-fns";
import _isArray from "lodash/isArray";

import { isNumeric } from "utils/isNumeric";

export const parseUrlParamValue = (
  fieldConfig: FieldBlockConfig,
  value?: string | string[]
) => {
  if (!value) return undefined;
  if (_isArray(value)) return value;

  const { inputType } = fieldConfig;

  switch (inputType) {
    case "date-picker":
      if (isValid(new Date(value))) return new Date(value);
      return undefined;
    case "checkbox":
    case "yes-no":
      if (value === "true" || value === "1") return true;
      return false;
    case "number":
    case "currency":
      if (isNumeric(value)) return value;
      else return undefined;
    case "text":
    case "textarea":
    case "email":
    case "url":
    case "phone-number":
      return value;
    case "dropdown":
      if (!fieldConfig.multiSelect) {
        return value;
      }
      return value.split(",");
    default:
      return undefined;
  }
};
