import { BlockProps } from "block-system/blocks/__shared__/types";
import type { LayoutBlock as LayoutBlockType } from "../types";
import { Suspense } from "react";
import { getLayoutItemColSpan, getOrderedLayoutItems } from "../lib/ordering";
import { LayoutAllowedItem } from "../schema";
import { Block } from "block-system";
import { BlockSpinner } from "block-system/components/BlockSpinner";
import { BlockError } from "components/Blocks/BlockError";
import { BlockId, ContentBlocks } from "block-system/types";
import { usePageContext } from "lib/context/page-context";
import { LayoutItemZeroState } from "./components/LayoutItemZeroState";
import { LayoutParent } from "./components/LayoutParent";

import * as Sentry from "@sentry/nextjs";

export const Layout = (props: BlockProps<LayoutBlockType>) => {
  const orderedItems = getOrderedLayoutItems(props.block);
  const columnCount = props.block.config.columnCount;
  const itemVerticalAlignments = props.block.config.itemVerticalAlignments;

  const { isEditing } = usePageContext();

  const items = orderedItems.map((item) => {
    if (item.block) {
      return {
        child: (
          <LayoutItemBlockWrapper
            block={item.block}
            blockId={`${props.blockId}.${item.index}`}
            blocks={[]}
          />
        ),
        alignment: {
          horizontal: "center" as const,
          vertical: itemVerticalAlignments[item.order] || ("center" as const),
        },
        colspan: getLayoutItemColSpan(
          item.block.type,
          props.block.config.columnCount
        ),
      };
    }

    return {
      child: isEditing ? <LayoutItemZeroState /> : null,
      alignment: {
        horizontal: "center" as const,
        vertical: itemVerticalAlignments[item.order] || ("center" as const),
      },
      colspan: 1,
    };
  });

  return (
    <div>
      {props.block.config.title ? (
        <div className="mb-4 text-2xl font-extrabold">
          {props.block.config.title}
        </div>
      ) : null}
      <LayoutParent items={items} columnCount={columnCount} />
    </div>
  );
};

function LayoutItemBlockWrapper({
  block,
  blockId,
  blocks,
}: {
  block: LayoutAllowedItem;
  blockId: BlockId;
  blocks: ContentBlocks;
}) {
  return (
    <Sentry.ErrorBoundary fallback={() => <BlockError blockId={blockId} />}>
      <Suspense fallback={<BlockSpinner />}>
        <Block block={block} blocks={blocks} blockId={blockId} />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}
