import image from "../../../assets/images/content-block-thumbnails/login-form.png";
import { BlockImages } from "../__shared__/types";
import { blockType } from "./constants";
import { strings } from "./strings";

export const LoginFormImages: BlockImages = {
  thumbnail: {
    name: strings.NAME,
    image,
    type: blockType,
  },
};
