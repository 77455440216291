import { forwardRef } from "react";
import { Label } from "./Label";
import { cn } from "utils/cn";
import { RadioGroup, RadioGroupItem } from "./RadioGroup";

type Props = {
  value?: any;
  onChange?: (value?: any) => void;
  options: { label: string; value: any }[];
};

const INLINE_SELECT_UNCHECKED_VALUE = "__unchecked__";

const InlineSelect = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, options }, ref) => {
    const selectedValue = value ?? INLINE_SELECT_UNCHECKED_VALUE;

    const handleClick = (radioValue: string) => {
      const option = options.find((option) => option.value === radioValue);
      if (
        option === undefined ||
        radioValue === INLINE_SELECT_UNCHECKED_VALUE
      ) {
        return;
      } else if (option.value === selectedValue) {
        onChange?.(undefined);
      } else {
        onChange?.(option.value);
      }
    };

    return (
      <RadioGroup ref={ref} value={selectedValue}>
        {options.map((option, index) => {
          const key = `${option.value}${index}`;

          return (
            <Label
              key={key}
              className={cn(
                "flex cursor-pointer items-center gap-2",
                "h-10 px-2.5 py-3",
                "rounded-md border border-input",
                "text-sm",
                "transition-colors duration-150",
                "focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background"
              )}
            >
              <RadioGroupItem
                value={option.value}
                id={key}
                onClick={() => {
                  handleClick(option.value);
                }}
              />
              <span>{option.label}</span>
            </Label>
          );
        })}
        {/* Radios as per spec cannot be deselected. Since we need that feature, we add a hidden radio.
        When user clicks on a previously selected radio, we change the value to this, which in turn deselects any visible radios  */}
        <div className="hidden">
          <RadioGroupItem value={INLINE_SELECT_UNCHECKED_VALUE} />
        </div>
      </RadioGroup>
    );
  }
);

InlineSelect.displayName = "InlineSelect";

export { InlineSelect };
