export const NEW_RECORD_BUTTON_DEFAULT_COPY = "New record";

export const FILTERABLE_FIELD_TYPES = [
  "string",
  "multiple_string",
  "labeled_string",
  "multiple_labeled_string",
  "text",
  "multiple_text",
  // 'boolean',
  // 'multiple_boolean',
  // 'number',
  // 'multiple_number',
  // 'decimal',
  // 'multiple_decimal',
  // 'datetime',
  // 'multiple_datetime',
  "uuid",
  "multiple_uuid",
  // 'json',
  // 'multiple_json',
  // 'formula',
  // 'button_trigger_zap',
  // 'button_continue_zap',
  "email",
  "multiple_email",
  "link",
];

export const strings = {
  NAME: "Table",
  DESCRIPTION:
    "Display information from a data source that can be searched, filtered, and updated.",
  NEW_RECORD_BUTTON_DEFAULT_COPY,
} as const;

// Arbitrary value to determine when to show the "See more" button
export const MIN_CHAR_LENGTH_FOR_SEE_MORE = 300;
