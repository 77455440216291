import { useTheme } from "lib/theme";
import { BlockProps } from "block-system/blocks/__shared__/types";
import { LinksBlock as LinksBlockType } from "../types";
import BlockWrapper from "block-system/components/BlockWrapper";
import Blocks from "components/Blocks";
import { Links } from "./Links";
import { usePageContext } from "@/lib/context/page-context";

export function LinksBlock(props: BlockProps<LinksBlockType>) {
  const { config } = props.block;
  const theme = useTheme();
  const { isEditing } = usePageContext();

  return (
    <BlockWrapper
      blockId={props.blockId}
      block={props.block}
      maxWidth={
        config.style?.width
          ? theme.app.width[config.style?.width]
          : theme.app.width.wide
      }
      alignment={config.style?.alignment}
    >
      <Links
        title={props.block.config.headerTitle}
        width={config.style?.width}
        isEditing={isEditing}
      >
        <Blocks blocks={props.block.children} bare parentId={props.blockId} />
      </Links>
    </BlockWrapper>
  );
}
