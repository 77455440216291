import embedImg from "../../../assets/images/content-block-thumbnails/embed.png";
import { blockType } from "./constants";
import { strings } from "./strings";
import { BlockImages } from "../__shared__/types";

export const EmbedImages: BlockImages = {
  thumbnail: {
    name: strings.NAME,
    image: embedImg,
    type: blockType,
  },
};
