import { MAX_WIDTH_NAME_TO_CLASSNAMES } from "block-system/components/BlockWrapper/constants";
import type { DividerBlock } from "../types";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { useShadcnComponentsEnabled } from "@/block-system/brickz/lib/shadcn";

const spacingOptions = {
  small: "py-2",
  medium: "py-4",
  large: "py-8",
} as const;

export const Divider = ({
  spacing = "medium",
  width = "medium",
}: DividerBlock["config"]) => {
  const interfacesTheme = useInterfacesTheme();
  const shadcnComponentsEnabled = useShadcnComponentsEnabled();

  return (
    <hr
      className={cn(
        "relative mx-auto w-full border-none px-0 after:absolute after:top-1/2 after:h-0.5 after:w-full after:content-['']",
        {
          "after:rounded-small after:bg-muted":
            interfacesTheme || shadcnComponentsEnabled,
          "after:rounded-[1px] after:bg-zi-gray": !interfacesTheme,
        },
        spacingOptions[spacing],
        MAX_WIDTH_NAME_TO_CLASSNAMES[width]
      )}
    />
  );
};
