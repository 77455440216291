import React from "react";
import { cn } from "utils/cn";

type DivProps = React.ComponentPropsWithoutRef<"div"> & { as?: never };
type FieldsetProps = React.ComponentPropsWithoutRef<"fieldset"> & {
  as: "fieldset";
};

type Props = DivProps | FieldsetProps;

export const FormContainer = ({ as, className, ...props }: Props) => {
  const classes = cn(
    "flex flex-col flex-nowrap gap-y-6",
    "bg-card text-card-foreground shadow-subtle",
    "m-0 p-6",
    "rounded-xl border border-border",
    className
  );

  if (as) {
    return (
      <fieldset
        {...(props as React.ComponentPropsWithoutRef<"fieldset">)}
        className={classes}
      />
    );
  }

  return (
    <div
      {...(props as React.ComponentPropsWithoutRef<"div">)}
      className={classes}
    />
  );
};
