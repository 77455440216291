import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { isNumber } from "lodash";
import { cn } from "utils/cn";

const sizes = {
  small: "h-[20px] w-[20px] border-4",
  medium: "h-[30px] w-[30px] border-6",
  large: "h-[60px] w-[60px] border-8",
} as const;

const colors = {
  light: "text-zi-pageBackground",
  dark: "text-zi-text",
};

const v2Colors = {
  light: "text-muted-foreground",
  dark: "text-foreground",
};

type Props = {
  ariaLabel?: string;
  className?: string;
  containerClassName?: string;
  height?: number | string;
  size?: keyof typeof sizes;
  color?: keyof typeof colors;
};

export function Spinner({
  ariaLabel = "Loading",
  className = "",
  containerClassName = "",
  height = "auto",
  size = "small",
  color = "dark",
}: Props) {
  const parsedHeight = isNumber(height) ? `${height}px` : height;
  const interfacesTheme = useInterfacesTheme();

  return (
    <div
      className={cn(
        "mx-auto my-10 flex items-center justify-center",
        containerClassName
      )}
      style={{ height: parsedHeight }}
    >
      <div
        data-testid="spinner"
        role="status"
        className={cn(
          "aspect-square animate-spin border-current border-r-transparent opacity-70",
          {
            "rounded-[50%]": !interfacesTheme,
            "rounded-full": interfacesTheme,
          },
          sizes[size],
          ...(!interfacesTheme ? colors[color] : v2Colors[color]),
          className
        )}
      >
        <span className="sr-only">{ariaLabel}</span>
      </div>
    </div>
  );
}
