import type { LoginForm as LoginFormType } from "./types";
import {
  blockNameToType,
  blockType,
  blockTypeDbName,
  blockTypeToName,
} from "./constants";
import { create, copy, map } from "./operations";
import { allowed } from "./lib/allowed";
import { strings } from "./strings";

export const LoginForm: LoginFormType = {
  strings,
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  allowed,
  isStandalone: true,
} as const;
