import {
  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,
} from "./constants";

import { strings } from "./strings";

import type { MarkdownText as MarkdownTextType } from "./types";
import { create, copy, map } from "./operations";

export const MarkdownText: MarkdownTextType = {
  strings,

  blockType,
  blockTypeDbName,
  blockTypeToName,
  blockNameToType,

  create,
  map,
  copy,
  isStandalone: true,
} as const;
