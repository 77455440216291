import { ConsumerProject } from "@/server/schemas/projects";
import React from "react";

const CurrentConsumerProjectContext =
  React.createContext<ConsumerProject | null>(null);

export function useCurrentConsumerProject() {
  const project = React.useContext(CurrentConsumerProjectContext);

  if (project === null) {
    throw new Error(
      "useCurrentConsumerProject must be used within a CurrentConsumerProjectProvider.Provider"
    );
  }

  return project;
}

export function CurrentConsumerProjectProvider({
  children,
  project,
}: {
  children: React.ReactNode;
  project: ConsumerProject;
}) {
  return (
    <CurrentConsumerProjectContext.Provider value={project}>
      {children}
    </CurrentConsumerProjectContext.Provider>
  );
}
