import clsx from "clsx";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { ReactNode, useEffect, useRef } from "react";

export function FocusWrapper(props: {
  isFocused: boolean;
  focusedBlockType?: string;
  children?: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const interfacesTheme = useInterfacesTheme();

  useEffect(() => {
    if (props.isFocused && ref.current) {
      ref.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, [props.isFocused]);

  return (
    <div className="relative scroll-mx-0 scroll-my-10" ref={ref}>
      {props.isFocused ? (
        <div
          data-content={props.focusedBlockType}
          className={clsx(
            "pointer-events-none absolute animate-highlight border-2 border-solid before:absolute before:p-[1px_5px_3px_5px] before:text-xs before:content-[attr(data-content)]",
            {
              "rounded-sm border-[hsl(249,_51%,_49%)] before:rounded-br-sm before:bg-[hsl(249,_51%,_49%)] before:text-[hsl(40,_100%,_99%)]":
                !interfacesTheme,
              "rounded-small border-ring before:rounded-br-small before:bg-ring before:text-background":
                interfacesTheme,
            }
          )}
        />
      ) : null}
      {props.children}
    </div>
  );
}
