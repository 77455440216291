import { FormBlock } from "../../../Form/schema";
import { FieldBlock } from "../../types";
import { FieldBlockConditionalLogicItem } from "../../schema";
import { resetDateSecondsAndMillis } from "./resetDateSecondsAndMillis";
import { isValidValueForFieldType } from "./validation";
import { getOperatorsFromGrouping } from "./conditional-grouping";

export const numericFieldTypes: FieldBlock["config"]["inputType"][] = [
  "number",
  "currency",
];

export const stringFieldTypes: FieldBlock["config"]["inputType"][] = [
  "text",
  "textarea",
  "email",
  "phone-number",
  "url",
];

export const booleanFieldTypes: FieldBlock["config"]["inputType"][] = [
  "checkbox",
  "yes-no",
];

export function isConditionallyRendered(field: FieldBlock) {
  return field.config.conditionalLogic.length > 0;
}

export function isConditionallyRequired(
  field: FieldBlock,
  formBlock: FormBlock
) {
  const allConditions = formBlock.children.flatMap(
    (child) => child.config.conditionalLogic ?? []
  );
  return (
    (allConditions.filter((c) => c.fieldId === field.config.id)?.length ?? 0) >
    0
  );
}

export function shouldConditionallyRender(
  field: FieldBlock,
  fieldBlocks: FieldBlock[],
  values: Record<string, any>
) {
  if (
    !field.config.conditionalLogic ||
    field.config.conditionalLogic.length === 0
  ) {
    return true;
  }

  // Not enough fields to check conditions
  if (fieldBlocks.length <= 1) return true;

  const conditionCheckResults = field.config.conditionalLogic.map(
    (condition) => {
      const referredField = fieldBlocks.find(
        (f) => f.config.id === condition.fieldId
      );
      if (!referredField) return false;

      const value = values[referredField.config.name];

      return isConditionPassingForField(referredField, value, condition);
    }
  );

  if (field.config.conditionalLogic.length === 1)
    return conditionCheckResults[0];

  if (!field.config.conditionalLogicGrouping) {
    return false;
  }

  const groupingOperators = getOperatorsFromGrouping(
    field.config.conditionalLogicGrouping
  );
  const shouldRender = conditionCheckResults.reduce(
    (accumulatedResult, currentConditionResult, index) => {
      if (index === 0) return currentConditionResult;
      if (groupingOperators?.[index - 1] === "or") {
        return accumulatedResult || currentConditionResult;
      }
      return accumulatedResult && currentConditionResult;
    },
    conditionCheckResults[0]
  );

  return shouldRender;
}

export function isConditionPassingForNumericFields(
  field: FieldBlock,
  value: any,
  condition: FieldBlockConditionalLogicItem
) {
  if (
    !numericFieldTypes.includes(field.config.inputType) ||
    !isValidValueForFieldType(value, field.config.inputType)
  ) {
    return false;
  }

  switch (condition.operator) {
    case "exact":
      return condition.value === value;
    case "gt":
      return value > condition.value;
    case "gte":
      return value >= condition.value;
    case "lt":
      return value < condition.value;
    case "lte":
      return value <= condition.value;
    case "in":
      if (!Array.isArray(condition.value)) return false;
      return condition.value.includes(value);
    case "isEmpty":
      return value === null || value === undefined;
    case "isNotEmpty":
      return value !== null && value !== undefined;
  }
  return false;
}

export function isConditionPassingForStringFields(
  field: FieldBlock,
  value: unknown,
  condition: FieldBlockConditionalLogicItem
) {
  if (
    !stringFieldTypes.includes(field.config.inputType) ||
    !isValidValueForFieldType(value, field.config.inputType)
  ) {
    return false;
  }

  // Even if we check above making sure value is a valid string or array,
  // Typescript doesn't properly detect that and we need to cast it to string or string[].
  switch (condition.operator) {
    case "exact":
      return condition.value === value;
    case "contains":
      if (typeof value !== "string" || typeof condition.value !== "string")
        return false;
      return value.includes(condition.value);
    case "icontains":
      if (typeof condition.value !== "string" || typeof value !== "string")
        return false;
      return value
        .toLocaleLowerCase()
        .includes(condition.value.toLocaleLowerCase());
    case "in":
      if (!Array.isArray(condition.value)) return false;
      return condition.value.includes(value as string);
    case "startswith":
      return (value as string).startsWith(condition.value as string);
    case "isEmpty":
      return value === null || value === undefined || value === "";
    case "isNotEmpty":
      return value !== null && value !== undefined && value !== "";
  }

  return false;
}

export function isConditionPassingForBooleanFields(
  field: FieldBlock,
  value: unknown,
  condition: FieldBlockConditionalLogicItem
) {
  if (
    !booleanFieldTypes.includes(field.config.inputType) ||
    !isValidValueForFieldType(value, field.config.inputType)
  ) {
    return false;
  }

  switch (condition.operator) {
    case "exact":
      return condition.value === value;
  }

  return false;
}

export function isConditionPassingFroDropdownField(
  field: FieldBlock,
  value: unknown,
  condition: FieldBlockConditionalLogicItem
) {
  if (
    field.config.inputType !== "dropdown" ||
    !isValidValueForFieldType(value, field.config.inputType)
  ) {
    return false;
  }

  const valueIsArray = field.config.multiSelect && Array.isArray(value);

  const comparisonFunctionsForStrings = {
    exact: (fieldValue: string, conditionValue: string) =>
      fieldValue === conditionValue,
    contains: (fieldValue: string, conditionValue: string) =>
      fieldValue.includes(conditionValue),
    icontains: (fieldValue: string, conditionValue: string) =>
      fieldValue
        .toLocaleLowerCase()
        .includes(conditionValue.toLocaleLowerCase()),
    in: (fieldValue: string, conditionValue: string[]) =>
      conditionValue.includes(fieldValue),
    startswith: (fieldValue: string, conditionValue: string) =>
      fieldValue.startsWith(conditionValue),
  };

  const comparisonFunctionsForArray = {
    exact: (fieldValue: string[], conditionValue: string) =>
      fieldValue.length === 1 && fieldValue[0] === conditionValue,
    contains: (fieldValue: string[], conditionValue: string) =>
      fieldValue.includes(conditionValue),
    icontains: (fieldValue: string[], conditionValue: string) =>
      fieldValue.some((v) =>
        v.toLocaleLowerCase().includes(conditionValue.toLocaleLowerCase())
      ),
    in: (fieldValue: string[], conditionValue: string[]) =>
      conditionValue.some((v) => fieldValue.includes(v)),
    startswith: (fieldValue: string[], conditionValue: string) =>
      fieldValue.some((v) => v.startsWith(conditionValue)),
  };

  switch (condition.operator) {
    case "exact":
    case "contains":
    case "icontains":
    case "in":
    case "startswith":
      return valueIsArray
        ? comparisonFunctionsForArray[condition.operator](
            value as string[],
            condition.value as any
          )
        : comparisonFunctionsForStrings[condition.operator](
            value as string,
            condition.value as any
          );
    case "isEmpty":
      return value === "" || value === null || value === undefined;
    case "isNotEmpty":
      return value !== "" && value !== null && value !== undefined;
  }
  return false;
}

export function isConditionPassingForDateField(
  field: FieldBlock,
  value: unknown,
  condition: FieldBlockConditionalLogicItem
) {
  if (
    field.config.inputType !== "date-picker" ||
    !isValidValueForFieldType(value, field.config.inputType)
  ) {
    return false;
  }

  const providedDate = resetDateSecondsAndMillis(new Date(value as string));

  const conditionDate = resetDateSecondsAndMillis(
    new Date(condition.value as string)
  );

  const providedDateOnly = new Date(
    providedDate.getFullYear(),
    providedDate.getMonth(),
    providedDate.getDate()
  );
  const conditionDateOnly = new Date(
    conditionDate.getFullYear(),
    conditionDate.getMonth(),
    conditionDate.getDate()
  );

  type ComparisonFunction = (a: number, b: number) => boolean;

  const comparisonFunctions: Record<string, ComparisonFunction> = {
    exact: (a: number, b: number) => a === b,
    gt: (a: number, b: number) => a > b,
    gte: (a: number, b: number) => a >= b,
    lt: (a: number, b: number) => a < b,
    lte: (a: number, b: number) => a <= b,
  };

  const compare = comparisonFunctions[condition.operator];
  if (!compare) {
    return false;
  }

  return field.config.includeTime
    ? compare(providedDate.getTime(), conditionDate.getTime())
    : compare(providedDateOnly.getTime(), conditionDateOnly.getTime());
}

export function isConditionPassingForFileUploadField(
  field: FieldBlock,
  value: unknown,
  condition: FieldBlockConditionalLogicItem
) {
  if (
    field.config.inputType !== "file-upload" ||
    !isValidValueForFieldType(value, field.config.inputType)
  ) {
    return false;
  }

  switch (condition.operator) {
    case "exact":
      // For file upload, the condition value is either true/false, which matches to uploaded/not uploaded.
      // If the field value is undefined/null file upload field has not been set.
      return condition.value === Boolean(value);
  }

  return false;
}

export function isConditionPassingForField(
  field: FieldBlock,
  value: unknown,
  condition: FieldBlockConditionalLogicItem
) {
  if (numericFieldTypes.includes(field.config.inputType)) {
    return isConditionPassingForNumericFields(field, value, condition);
  }

  if (stringFieldTypes.includes(field.config.inputType)) {
    return isConditionPassingForStringFields(field, value, condition);
  }

  if (booleanFieldTypes.includes(field.config.inputType)) {
    return isConditionPassingForBooleanFields(field, value, condition);
  }

  if (field.config.inputType === "dropdown") {
    return isConditionPassingFroDropdownField(field, value, condition);
  }

  if (field.config.inputType === "date-picker") {
    return isConditionPassingForDateField(field, value, condition);
  }

  if (field.config.inputType === "file-upload") {
    return isConditionPassingForFileUploadField(field, value, condition);
  }

  return false;
}
