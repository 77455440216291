import { parseHandlersInObject } from "lib/utils/parseHandlebars";
import { useMemo } from "react";
/**
 * Parses an object with keys containing handlebar expressions.
 *
 * @example
 * // For the dynamic route "/live/[projectSlug]/[pageSlug]?name=Bob&foobar=bizbang"
 * "Project slug {{ params.projectSlug }}"
 * "Hello {{ params.name }}!"
 */
export function useHandlebarParser<
  T extends { [key: string]: any } | undefined
>(
  config: T,
  datasource: Record<string, any>,
  supportedDynamicFields: (string | number | symbol)[]
): T {
  return useMemo(
    () => parseHandlersInObject(config, datasource, supportedDynamicFields),
    [config, datasource, supportedDynamicFields]
  );
}
