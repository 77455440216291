import { SuccessNotification } from "@/block-system/components/Notification";
import { NotificationAction } from "block-system/types";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import { toast as sonnerToast } from "sonner";

export function showSuccessNotification(
  notificationAction: NotificationAction,
  shadcnEnabled: boolean
) {
  if (shadcnEnabled) {
    sonnerToast.success(
      // This is a workaround because sonner won't show the cancel button if the toast is a JSX element
      // We need the wrapper on the message to add the test id for e2e tests
      // We can remove this once sonner fixes the issue
      // https://github.com/emilkowalski/sonner/issues/445
      () => (
        <div role="status" data-testid="toast-success-notification">
          {notificationAction.config.message}
        </div>
      ),
      {
        cancel: {
          label: <X size={14} aria-label="Close notification" />,
          // onClick is required but we don't want to do anything on toast dismissal
          onClick: () => {},
        },
        cancelButtonStyle: {
          backgroundColor: "transparent",
          color: "inherit",
        },
      }
    );
    return;
  }

  toast.custom(
    (t) => (
      <SuccessNotification
        toast={t}
        message={notificationAction.config.message}
        handleClose={toast.dismiss}
      />
    ),
    { position: notificationAction.config.position ?? "top-center" }
  );
}
