export function matchUrlRegex(url: string, regex: RegExp) {
  const match = url.match(regex);
  return match ? match[1] : undefined;
}

export function prettifyUrl(url: string) {
  const urlObj = new URL(url);
  const port = urlObj.port.length > 0 ? `:${urlObj.port}` : "";

  let prettifiedUrl = urlObj.hostname + port + urlObj.pathname;

  if (prettifiedUrl.startsWith("www.")) {
    prettifiedUrl = prettifiedUrl.substring(4);
  }

  if (prettifiedUrl.endsWith("/")) {
    prettifiedUrl = prettifiedUrl.slice(0, -1);
  }

  return prettifiedUrl;
}
