import { FieldBlockConditionalLogicItem } from "../../schema";
import { FieldBlock } from "../../types";

export function isValidEmptyValueForCondition(
  rule: FieldBlockConditionalLogicItem,
  block: FieldBlock
) {
  const value = rule.value;

  // Empty values are allowed only for isEmpty and isNotEmpty operators
  if (rule.operator !== "isEmpty" && rule.operator !== "isNotEmpty") {
    return false;
  }

  switch (block.config.inputType) {
    case "number":
    case "currency":
    case "dropdown":
    case "text":
    case "textarea":
    case "email":
    case "url":
    case "phone-number":
      return value === "";
    default:
      return false;
  }
}

export function isValidValueForCondition(value: unknown, block: FieldBlock) {
  switch (block.config.inputType) {
    case "checkbox":
    case "yes-no":
      return isValidBooleanValue(value);
    case "number":
    case "currency":
      return isValidNumberValue(value);
    case "date-picker":
      return isValidDateValue(value);
    case "file-upload":
      // File upload also has boolean value on conditions
      return isValidBooleanValue(value);
    default:
      return isValidStringValue(value);
  }
}

function isValidBooleanValue(value: unknown): value is boolean {
  if (typeof value !== "boolean") return false;
  return true;
}

function isValidNumberValue(value: unknown): value is number | number[] {
  if (Array.isArray(value)) {
    return value.every((v) => typeof v === "number");
  } else if (typeof value !== "number") return false;
  return true;
}

function isValidDateValue(value: unknown): value is Date | Date[] {
  if (Array.isArray(value) && value.every((v) => typeof v === "string")) {
    return value.every((v) => new Date(v).toString() !== "Invalid Date");
  } else if (
    typeof value === "string" &&
    new Date(value).toString() !== "Invalid Date"
  ) {
    return true;
  }
  return false;
}

function isValidStringValue(value: unknown): value is string | string[] {
  if (Array.isArray(value)) {
    return value.every((v) => typeof v === "string" && v !== "");
  } else if (typeof value !== "string" || value === "") return false;
  return true;
}
