import BlockWrapper from "block-system/components/BlockWrapper";
import type { LoginFormBlock as LoginFormBlockType } from "../types";
import { BlockProps } from "block-system/blocks/__shared__/types";
import { LoginForm } from "block-system/components/LoginForm";
import { cn } from "utils/cn";
import { Icon } from "@zapier/design-system";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import styled from "styled-components";
import { usePageContext } from "@/lib/context/page-context";
import { useProjectTheme } from "@/lib/hooks/useProjectTheme";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";

const Content = styled.div`
  max-width: 500px;
  margin: 30px auto;
`;

export const LoginFormBlock = ({
  block,
  blockId,
}: BlockProps<LoginFormBlockType>) => {
  const project = useCurrentConsumerProject();
  const { isEditing } = usePageContext();
  const theme = useProjectTheme();

  return (
    <BlockWrapper
      block={block}
      blockId={blockId}
      maxWidth={theme.app.width["narrow"]}
      alignment={"center"}
    >
      {project.authType === "None" ? (
        <LoginBlockEmptyState blockId={blockId} />
      ) : (
        <LoginForm
          stytchOrganizationId={project.stytchOrganizationId}
          projectId={project.id}
          captchaEnabled={project.captchaEnabled}
          authType={project.authType}
          onAuthSuccess={() => window.location.reload()}
          isEditing={isEditing}
        />
      )}
    </BlockWrapper>
  );
};

function LoginBlockEmptyState({ blockId }: { blockId: string }) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <Content>
      <section
        data-testid={`auth-not-configured-${blockId}`}
        className={cn(
          "flex h-60 w-full flex-col items-center justify-center gap-[20px] border",
          {
            "rounded-xl border-gray-200 bg-zi-white": !interfacesTheme,
            "rounded-large border-border bg-card": interfacesTheme,
          }
        )}
      >
        <Icon name="personGroup" size={26} color="neutral800" />
        <h2 className={cn("text-center text-lg font-semibold")}>
          Configure authentication in sidebar
        </h2>
      </section>
    </Content>
  );
}
