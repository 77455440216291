import { LayoutAllowedItems } from "../schema";

/**
 * Can span one column if there are X columns to take in the layout.
 */
const ONE_COLUMN_SPAN = 1;
/**
 * Can span two columns if there are X columns to take in the layout.
 */
const TWO_COLUMN_SPAN = 2;

export const LayoutItemColumnSpan: Record<
  LayoutAllowedItems[0]["type"],
  {
    twoColumn: number;
    threeColumn: number;
  }
> = {
  "kanban-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: TWO_COLUMN_SPAN,
  },
  "table-listing-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: TWO_COLUMN_SPAN,
  },
  "media-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
  "markdown-text-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
  "embed-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
  "chatbot-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
  "form-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
  "links-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: TWO_COLUMN_SPAN,
  },
  "button-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
  "checklist-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: TWO_COLUMN_SPAN,
  },
  "stripe-payment-block": {
    twoColumn: ONE_COLUMN_SPAN,
    threeColumn: ONE_COLUMN_SPAN,
  },
};
