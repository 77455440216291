import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import cuid from "cuid";
import { blockType } from "./constants";
import { FieldBlockSchema } from "./schema";
import { Field, FieldBlock } from "./types";
import { generateFormFieldName } from "lib/utils/generateFieldName";

export const copy: Field["copy"] = (block) => ({
  ...block,
  config: {
    ...block.config,
    id: undefined,
  },
});

export const create: Field["create"] = (
  props?: BlockCreateProps<FieldBlock>
) => {
  return FieldBlockSchema.parse({
    type: blockType,
    config: {
      ...(props?.initialConfig ?? {}),
      id: cuid(),
      name: props?.initialConfig?.name ?? generateFormFieldName(),
      label: props?.initialConfig?.label ?? "Email",
      inputType: props?.initialConfig?.inputType ?? "email",
      required:
        props?.initialConfig?.required !== undefined
          ? props?.initialConfig.required
          : true,
      nameAutoGenerated:
        props?.initialConfig?.nameAutoGenerated !== undefined
          ? props?.initialConfig.nameAutoGenerated
          : true,
    },
  });
};

export const map: Field["map"] = (block) => {
  const config = block.config?.valueOf();
  return {
    type: blockType,
    config: FieldBlockSchema.shape.config.parse({
      id: block.id,
      ...(typeof config === "object" ? config : {}),
    }),
  };
};
