import BlockWrapper from "block-system/components/BlockWrapper";
import type { StripePaymentBlock as PaymentBlockType } from "../types";
import { BlockProps } from "block-system/blocks/__shared__/types";
import { useProjectTheme } from "lib/hooks/useProjectTheme";
import { StripePayment } from "./StripePayment";
import { usePageContext } from "lib/context/page-context";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";

export const StripePaymentBlock = (props: BlockProps<PaymentBlockType>) => {
  const theme = useProjectTheme();

  const stripeConnectedAccount = useStripeConnectedAccount();
  const isPublishedPage = useIsPublishedPage();

  return (
    <BlockWrapper
      block={props.block}
      blockId={props.blockId}
      maxWidth={theme.app.width[props.block.config.style.width || "wide"]}
      alignment={props.block.config.style.alignment}
    >
      <StripePayment
        isPublishedPage={isPublishedPage}
        block={props.block}
        blockId={props.blockId}
        stripeConnectedAccount={stripeConnectedAccount}
      />
    </BlockWrapper>
  );
};

function useStripeConnectedAccount() {
  const { stripeConnectedAccount } = useCurrentConsumerProject();
  return stripeConnectedAccount;
}

function useIsPublishedPage() {
  const { isEditing } = usePageContext();
  return !isEditing;
}
