import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { Toast, toast as reactHotToast } from "react-hot-toast";
import { cn } from "utils/cn";

const CircleCheckIcon = () => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      style={{ minWidth: 26 }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25.5C15.4723 25.5 17.889 24.7669 19.9446 23.3934C22.0002 22.0199 23.6024 20.0676 24.5485 17.7836C25.4946 15.4995 25.7421 12.9861 25.2598 10.5614C24.7775 8.13661 23.587 5.90933 21.8388 4.16117C20.0907 2.41301 17.8634 1.2225 15.4386 0.74019C13.0139 0.257874 10.5005 0.505416 8.21646 1.45151C5.93238 2.39761 3.98015 3.99976 2.60663 6.05538C1.23311 8.11099 0.5 10.5277 0.5 13C0.5 16.3152 1.81696 19.4946 4.16117 21.8388C6.50537 24.183 9.68479 25.5 13 25.5ZM8.2625 11.4875L11.75 14.9875L18.3625 8.36251L20.1375 10.1375L11.75 18.5125L6.4875 13.2625L8.2625 11.4875Z"
        fill={interfacesTheme ? "hsl(var(--primary))" : "#00A589"}
      />
    </svg>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <button>
      <span className={"sr-only"}>Close notification</span>
      <svg
        aria-hidden={true}
        onClick={() => onClick()}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style={{ minWidth: 24, cursor: "pointer" }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.29 6.29001L12 10.59L7.71004 6.29001L6.29004 7.71001L10.59 12L6.29004 16.29L7.71004 17.71L12 13.41L16.29 17.71L17.71 16.29L13.41 12L17.71 7.71001L16.29 6.29001Z"
          fill={interfacesTheme ? "hsl(var(--muted-foreground))" : "#3E547C"}
          fillOpacity={interfacesTheme ? 1 : "0.5"}
        />
      </svg>
    </button>
  );
};

export function SuccessNotification({
  toast,
  message,
  handleClose,
}: {
  toast: Toast;
  message: string;
  handleClose?: (toastId?: string) => void;
}) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      aria-live={"polite"}
      role={"status"}
      data-testid="toast-success-notification"
      data-hidden={!toast.visible}
      className={cn(
        "relative flex h-auto min-h-[70px] w-full items-center border px-5 py-1 shadow-lg data-[hidden=true]:opacity-0 md:w-[600px]",
        {
          "rounded-xl border-[#00a589] bg-zi-white": !interfacesTheme,
          "rounded-large border-primary bg-background": interfacesTheme,
        }
      )}
    >
      <div
        className={cn("absolute left-0 h-[50px] w-[3px]", {
          "rounded-br-[3px] rounded-tr-[3px] bg-[#00a589]": !interfacesTheme,
          "rounded-br-small rounded-tr-small bg-primary": interfacesTheme,
        })}
      />
      <div className="flex grow items-center">
        <CircleCheckIcon />
        <p
          className={cn("ml-2.5 break-words text-base font-semibold", {
            "text-zi-text": !interfacesTheme,
            "text-foreground": interfacesTheme,
          })}
        >
          {message}
        </p>
      </div>
      <CloseButton onClick={() => handleClose && handleClose(toast.id)} />
    </div>
  );
}

export const toast = {
  success(message: string) {
    return reactHotToast.custom(
      (t) => (
        <SuccessNotification
          toast={t}
          message={message}
          handleClose={reactHotToast.dismiss}
        />
      ),
      { position: "top-center" }
    );
  },
  error(message: string) {
    return reactHotToast.error(message, {
      position: "top-center",
      duration: 10_000,
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  },
};
