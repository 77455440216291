import { flushSync } from "react-dom";

const hasViewTransitions = () => {
  return "startViewTransition" in document;
};

export function animateTransition(callback: VoidFunction) {
  if (!hasViewTransitions()) {
    return callback();
  }

  /**
   * Currently, this API is only available on Chromium-based browsers.
   * See https://developer.chrome.com/docs/web-platform/view-transitions
   */
  // @ts-ignore
  document.startViewTransition(() => {
    flushSync(() => {
      callback();
    });
  });
}

export function animateAsyncTransition(callback: () => Promise<unknown>) {
  if (!hasViewTransitions()) {
    return callback();
  }

  /**
   * Currently, this API is only available on Chromium-based browsers.
   * See https://developer.chrome.com/docs/web-platform/view-transitions
   */
  // @ts-ignore
  return document.startViewTransition(async () => {
    return await callback();
  });
}
