import { useMemo } from "react";
import type { StripePaymentBlock as PaymentBlockType } from "./types";
import { TRPCClientError } from "@trpc/client";
import type { Option } from "@/block-system/brickz/components/ui/DropdownSelect";

export function isConfigured(block: PaymentBlockType) {
  const { title, buttonLabel, currency, value, quantityLimit } = block.config;

  const validNumbers = [value, quantityLimit].every((val) => val > 0);
  const validStrings = [title, buttonLabel, currency].every(
    (val) => val.length > 0
  );

  return validNumbers && validStrings;
}

export function useQuantityOptions(quantityLimit: number): Option[] {
  return useMemo(() => {
    const options: Option[] = [];

    for (let i = 1; i <= quantityLimit; i++) {
      const value = `${i}`;

      options.push({ value, label: value });
    }

    return options;
  }, [quantityLimit]);
}

export function isConnectedAccountNotFoundError(error: unknown) {
  const isTRPCClientError = error instanceof TRPCClientError;
  if (!isTRPCClientError) {
    return false;
  }

  return (
    error.data.httpStatus === 404 &&
    error.data.code === "interfaces:stripe:connected-account:not-found"
  );
}

export function isPaymentModeSubscription(
  payment: PaymentBlockType["config"]["payment"]
) {
  return payment.mode === "subscription";
}
