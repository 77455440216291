import { Disclosure, Menu } from "@headlessui/react";
import { ColorsCSS as Colors, Icon } from "@zapier/design-system";
import { transparentize } from "color2k";
import { css, styled } from "lib/theme";
import Link from "next/link";
import { forwardRef } from "react";
import IconInterfaces from "../../../public/icon-interfaces.svg";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import {
  ResponsiveNav as ShadcnResponsiveNav,
  ResponsiveNavProps as ShadcnResponsiveNavProps,
} from "../brickz/components/ui/ResponsiveNav";
import { useShadcnComponentsEnabled } from "../brickz/lib/shadcn";

// FIXME: Ideally this entire component would be written in Tailwind CSS, instead of styled-components.

const StyledDisclosure = styled.nav<{ $hasInterfacesTheme: boolean }>`
  /* If you change the styles here, you will most likely need to change the code in packages/service/e2e/tests/editor-page/custom-theme.e2e.ts */
  ${({ $hasInterfacesTheme, theme }) =>
    $hasInterfacesTheme
      ? css`
          background-color: hsl(var(--card));
          box-shadow: 0 1px 0 hsl(var(--card-foreground) / 0.1);
        `
      : css`
          background-color: ${theme.app.colors.white};
          box-shadow: 0 1px 0 ${({ theme }) => theme.app.colors.lightGray};
        `}
  font-family: ${({ theme }) => theme.app.fontFamily};
`;

const StyledRoot = styled.div`
  margin: 0 auto;
  padding: 0 18px;
  max-width: 80rem;

  @media screen and (min-width: 640px) {
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 2rem;
  }
`;

const StyledRootInner = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 70px;
`;

const StyledNav = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-start;
`;

const StyledDisclosurePanelWrapper = styled.div`
  @media (min-width: 640px) {
    display: none;
  }
`;

const StyledDisclosurePanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0.5rem;
`;

type ResponsiveNavProps = {
  items: {
    name: string;
    href: string;
    current: boolean;
    target?: "_self" | "_blank" | "_parent" | "_top";
  }[];
  profileItems: {
    name: string;
    onClick: () => void;
  }[];
  interfaceName?: string;
  name?: string | null;
  email?: string;
  avatarUrl?: string;
  avatarAltText?: string | null;
  includeLogo: boolean;
  logoHref?: string;
  logoAltText?: string;
  logo?: {
    name: string;
    url: string;
    showLogoOnLoginPage: boolean;
    height: number;
  } | null;
};

export const LegacyResponsiveNav = forwardRef<HTMLElement, ResponsiveNavProps>(
  function ResponsiveNav(props, ref) {
    const interfacesTheme = useInterfacesTheme();

    if (
      !props.name &&
      !props.email &&
      !props.items.length &&
      !props.includeLogo
    ) {
      return null;
    }

    return (
      <Disclosure
        as={StyledDisclosure}
        $hasInterfacesTheme={!!interfacesTheme}
        ref={ref}
        data-testid="builder-page-nav"
      >
        {({ open }) => {
          return (
            <>
              <StyledRoot>
                <StyledRootInner>
                  <LegacyMobileMenuTrigger open={open} />

                  <StyledNav>
                    <LegacyNavLogo
                      logo={props.logo}
                      includeLogo={props.includeLogo}
                      interfaceName={props.interfaceName}
                      logoHref={props.logoHref}
                      logoAltText={props.logoAltText}
                    />

                    <LegacyPagesLinks items={props.items} />
                  </StyledNav>

                  {props.name ? (
                    <LegacyProfileDropdown
                      avatarUrl={props.avatarUrl}
                      avatarAltText={props.avatarAltText}
                      name={props.name}
                      email={props.email}
                      profileItems={props.profileItems}
                    />
                  ) : null}
                </StyledRootInner>
              </StyledRoot>

              <LegacyMobileMenu
                profileItems={props.profileItems}
                items={props.items}
              />
            </>
          );
        }}
      </Disclosure>
    );
  }
);

const StyledNavItems = styled.div`
  display: flex;
  gap: 6px;
`;

const StyledNavItem = styled.a<{
  $current: boolean;
  $hasInterfacesTheme: boolean;
}>`
  padding: 8px 15px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 9999px;
  text-decoration: none;

  transition:
    background-color 0.14159s ease-out,
    color 0.14159s ease-out;

  ${({ $hasInterfacesTheme, $current, theme }) =>
    $hasInterfacesTheme
      ? css`
          ${$current
            ? css`
                background-color: hsl(var(--primary));
                color: hsl(var(--primary-foreground));
              `
            : css`
                background-color: hsl(var(--card));
                color: hsl(var(--card-foreground));

                &:hover {
                  background-color: hsl(var(--card-foreground) / 0.1);
                }
              `}

          &:focus-visible {
            outline: 2px solid hsl(var(--ring));
            outline-offset: 2px;
          }
        `
      : css`
          background-color: ${$current
            ? transparentize(theme.app.colors.primary, 0.95)
            : "transparent"};
          color: ${$current
            ? theme.app.colors.primary
            : transparentize(theme.app.colors.darkGray, 0.1)};

          &:hover {
            color: ${({ theme }) => theme.app.colors.primary};
          }
        `}
`;

function LegacyPagesLinks({ items }: { items: ResponsiveNavProps["items"] }) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <StyledNavItemsWrapper>
      <StyledNavItems>
        {items.map((item) => (
          <Link
            key={item.name}
            href={item.href}
            legacyBehavior
            target={item.target}
          >
            <StyledNavItem
              href={item.href}
              $current={item.current}
              $hasInterfacesTheme={!!interfacesTheme}
              aria-current={item.current ? "page" : undefined}
              target={item.target}
            >
              {item.name}
            </StyledNavItem>
          </Link>
        ))}
      </StyledNavItems>
    </StyledNavItemsWrapper>
  );
}

const StyledDisclosureButtonWrapper = styled.div`
  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;

  @media screen and (min-width: 640px) {
    display: none;
  }
`;

const StyledDisclosureButton = styled.button<{ $hasInterfacesTheme: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px 6px 6px;
  gap: 5px;
  cursor: pointer;

  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out,
    border-color 0.2s ease-out;

  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  border-radius: 5px;

  ${({ $hasInterfacesTheme, theme }) =>
    $hasInterfacesTheme
      ? css`
          border: 1px solid var(--card-foreground-subtle);
          color: var(--card-foreground-subtle);

          &:hover {
            background-color: hsl(var(--card-foreground) / 0.1);
          }

          &:focus {
            outline: none;
            box-shadow: hsl(var(--ring)) 0px 0px 0px 2px;
          }
        `
      : css`
          border: 1px solid
            ${transparentize(theme.app.colors.secondaryText, 0.7)};

          color: ${theme.app.colors.secondaryText};

          &:hover {
            color: ${theme.app.colors.primary};
            background-color: ${transparentize(theme.app.colors.primary, 0.95)};
            border-color: ${transparentize(theme.app.colors.primary, 0.7)};
          }

          &:focus {
            outline: none;
            box-shadow: ${theme.app.colors.primary} 0px 0px 0px 2px;
          }
        `}
`;

function LegacyMobileMenuTrigger({ open }: { open: boolean }) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <StyledDisclosureButtonWrapper>
      <Disclosure.Button
        as={StyledDisclosureButton}
        $hasInterfacesTheme={!!interfacesTheme}
        id="mobile-menu-button"
      >
        {open ? (
          <Icon name="formX" size={20} aria-hidden="true" />
        ) : (
          <Icon name="navMenu" size={20} aria-hidden="true" />
        )}
        Menu
      </Disclosure.Button>
    </StyledDisclosureButtonWrapper>
  );
}

const StyledDisclosureNavItem = styled.a<{
  $current: boolean;
  $hasInterfacesTheme: boolean;
}>`
  display: block;
  padding: 12px 35px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 9999px;
  text-decoration: none;
  width: max-content;
  margin: 0 auto;
  cursor: pointer;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;

  ${({ $hasInterfacesTheme, theme, $current }) =>
    $hasInterfacesTheme
      ? $current
        ? css`
            color: hsl(var(--primary-foreground));
            background-color: hsl(var(--primary));
          `
        : css`
            color: hsl(var(--card-foreground));
            background-color: hsl(var(--card));
            border: 1px solid var(--card-foreground-subtle);

            &:hover {
              background-color: hsl(var(--card-foreground) / 0.1);
            }
          `
      : $current
        ? css`
            color: ${theme.app.colors.primary};
            background-color: ${transparentize(theme.app.colors.primary, 0.95)};
          `
        : css`
            color: ${theme.app.colors.secondaryText};
            background-color: ${theme.app.colors.white};

            :hover {
              color: ${theme.app.colors.primary};
            }
          `}
`;

function LegacyMobileMenu({
  profileItems,
  items,
}: {
  profileItems: ResponsiveNavProps["profileItems"];
  items: ResponsiveNavProps["items"];
}) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <Disclosure.Panel as={StyledDisclosurePanelWrapper}>
      <StyledDisclosurePanel>
        {items.map((item) => (
          <Disclosure.Button
            key={item.name}
            as={StyledDisclosureNavItem}
            $current={item.current}
            $hasInterfacesTheme={!!interfacesTheme}
            href={item.href}
            aria-current={item.current ? "page" : undefined}
          >
            {item.name}
          </Disclosure.Button>
        ))}
        {profileItems.map((profileItem) => (
          <Disclosure.Button
            key={profileItem.name}
            as={StyledDisclosureNavItem}
            $current={false}
            $hasInterfacesTheme={!!interfacesTheme}
            onClick={profileItem.onClick}
          >
            {profileItem.name}
          </Disclosure.Button>
        ))}
      </StyledDisclosurePanel>
    </Disclosure.Panel>
  );
}

const StyledProfileWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 90px;
  align-items: center;

  @media (min-width: 640px) {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding-right: 0;
  }
`;

const StyledMenu = styled.div`
  position: relative;
`;

const StyledMenuItems = styled.div<{ $hasInterfacesTheme: boolean }>`
  position: absolute;
  right: 0;
  z-index: 10;
  padding-top: 8px;
  padding-bottom: 8px;
  transform-origin: top right;
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 15px 25px 0px;

  &:focus {
    outline: none;
  }

  ${({ $hasInterfacesTheme, theme }) =>
    $hasInterfacesTheme
      ? css`
          background-color: hsl(var(--card));
          color: hsl(var(--card-foreground));
          margin-top: 4px;
        `
      : css`
          background-color: ${theme.app.colors.white};
        `}
`;

const StyledConsumerInfo = styled.button<{ $hasInterfacesTheme: boolean }>`
  display: flex;
  align-items: center;
  justify-items: start;
  padding: 6px;
  cursor: pointer;

  border-radius: 8px;

  transition: background-color 0.2s ease-out;

  pointer-events: none;

  @media (min-width: 640px) {
    pointer-events: auto;
  }

  &[data-headlessui-state="open"] > svg {
    transform: rotate(180deg);
  }

  & > span:only-of-type {
    display: none;

    @media (min-width: 768px) {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }
  }

  ${({ $hasInterfacesTheme, theme }) =>
    $hasInterfacesTheme
      ? css`
          color: hsl(var(--card-foreground));
          background-color: hsl(var(--card));

          &[data-headlessui-state="open"],
          &:focus-visible {
            outline: 2px solid hsl(var(--ring));
          }

          & > img {
            grid-area: avatar;
            border-radius: 100%;
            outline: 2px solid hsl(var(--card));
          }

          & > svg {
            display: none;
            transform: rotate(0deg);
            transition: transform 0.2s ease-out;

            & path {
              fill: hsl(var(--card-foreground) / 0.75);
            }

            @media (min-width: 640px) {
              display: block;
              margin-left: 10px;
            }
          }

          & > span:only-of-type > span:first-of-type {
            font-size: 15px;
            line-height: 24px;
            color: hsl(var(--card-foreground));

            transition:
              background-color 0.2s ease-out,
              color 0.2s ease-out;
          }

          & > span:only-of-type > span:last-of-type:not(:only-of-type) {
            font-size: 13px;
            line-height: 20px;
            color: hsl(var(--card-foreground) / 0.75);

            transition:
              background-color 0.2s ease-out,
              color 0.2s ease-out;
          }

          &:hover {
            background-color: hsl(var(--card-foreground) / 0.1);
          }
        `
      : css`
          background-color: ${theme.app.colors.white};

          &:focus-visible {
            outline: 2px solid ${theme.app.colors.primary};
          }

          & > img {
            grid-area: avatar;
            border-radius: 100%;
            outline: 2px solid ${theme.app.colors.superLightGray};
          }

          & > svg {
            display: none;
            transform: rotate(0deg);
            transition: transform 0.2s ease-out;

            & path {
              fill: ${theme.app.colors.secondaryText};
            }

            @media (min-width: 640px) {
              display: block;
              margin-left: 10px;
            }
          }

          & > span:only-of-type > span:first-of-type {
            font-size: 15px;
            line-height: 24px;
            color: ${theme.app.colors.text};
          }

          & > span:only-of-type > span:last-of-type:not(:only-of-type) {
            font-size: 13px;
            line-height: 20px;
            color: ${theme.app.colors.secondaryText};
          }

          &[data-headlessui-state="open"],
          &:hover {
            background-color: ${transparentize(theme.app.colors.primary, 0.95)};
          }
        `}
`;

const StyledMenuItem = styled.a<{
  $active: boolean;
  $hasInterfacesTheme: boolean;
}>`
  display: block;
  padding: 0.5rem 15px;
  color: ${({ theme, $hasInterfacesTheme }) =>
    $hasInterfacesTheme
      ? "hsl(var(--card-foreground))"
      : theme.app.colors.secondaryText};
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;

  ${({ $hasInterfacesTheme, $active, theme }) =>
    $hasInterfacesTheme
      ? $active &&
        css`
          background-color: hsl(var(--card-foreground) / 0.1);
        `
      : $active &&
        css`
          color: ${theme.app.colors.primary};
        `}
`;

function LegacyProfileDropdown({
  avatarUrl,
  avatarAltText,
  name,
  email,
  profileItems,
}: {
  avatarUrl: ResponsiveNavProps["avatarUrl"];
  avatarAltText: ResponsiveNavProps["avatarAltText"];
  name: ResponsiveNavProps["name"];
  email: ResponsiveNavProps["email"];
  profileItems: ResponsiveNavProps["profileItems"];
}) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <StyledProfileWrapper>
      <Menu as={StyledMenu}>
        <div>
          <Menu.Button
            as={StyledConsumerInfo}
            $hasInterfacesTheme={!!interfacesTheme}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={avatarUrl}
              alt={avatarAltText ?? name ?? "User avatar"}
              height={36}
              width={36}
              onError={(event) => {
                const imgEl = event.target as HTMLImageElement;
                if (!/\/icon\-interfaces\.svg$/.test(imgEl.src)) {
                  imgEl.src = "/icon-interfaces.svg";
                }
              }}
            />
            <span>
              <span>{name}</span>
              <span>{email}</span>
            </span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path d="M9 12.975L14.25 8.57999V6.61499L9 11.025L3.75 6.61499V8.57999L9 12.975Z" />
              </g>
            </svg>
          </Menu.Button>
        </div>
        <Menu.Items
          as={StyledMenuItems}
          $hasInterfacesTheme={!!interfacesTheme}
        >
          {profileItems.map((profileItem) => (
            <Menu.Item key={profileItem.name}>
              {({ active }) => (
                <StyledMenuItem
                  $active={active}
                  $hasInterfacesTheme={!!interfacesTheme}
                  onClick={profileItem.onClick}
                >
                  {profileItem.name}
                </StyledMenuItem>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </StyledProfileWrapper>
  );
}

const StyledNavItemsWrapper = styled.div`
  display: none;

  @media (min-width: 640px) {
    display: flex;
    align-items: center;
  }
`;

const StyledNavLogo = styled.img<{ $height: number }>`
  width: auto;
  height: ${({ $height }) => $height}px;
`;

const StyledNavInterfaceName = styled.h4<{ $hasInterfacesTheme: boolean }>`
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.14159s;

  @media (max-width: 480px) {
    max-width: 20ch;
    overflow-x: hidden;
  }

  &:visited {
    color: ${({ $hasInterfacesTheme, theme }) =>
      $hasInterfacesTheme
        ? "hsl(var(--card-foreground))"
        : theme.app.colors.black};
  }
`;

const StyledNavLogosWrapper = styled.a<{
  $disabled: boolean;
  $hasInterfacesTheme: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 4px;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};

  padding-inline: 1.5rem;
  padding-block: 0.5rem;
  margin-left: -1.5rem;

  transition: all 0.2s ease-out;

  ${({ $hasInterfacesTheme, theme }) =>
    $hasInterfacesTheme
      ? css`
          color: hsl(var(--card-foreground));
          text-decoration: none;
          margin-right: var(--zds-space-6);

          &:visited {
            color: hsl(var(--card-foreground));
          }
          &:hover {
            background-color: hsl(var(--card-foreground) / 0.1);
          }
          &:focus-visible {
            outline: none;
            box-shadow: hsl(var(--ring)) 0px 0px 0px 2px;
          }
        `
      : css`
          color: ${Colors.black};
          text-decoration: none;

          &:visited {
            color: ${Colors.black};
          }
          &:hover {
            background: ${transparentize(theme.app.colors.primary, 0.95)};
          }
          &:focus {
            outline: none;
            box-shadow: ${theme.app.colors.primary} 0px 0px 0px 2px;
          }
        `}
`;

function LegacyNavLogo({
  logo,
  logoHref,
  includeLogo,
  logoAltText,
  interfaceName,
}: {
  logo: ResponsiveNavProps["logo"];
  logoHref: ResponsiveNavProps["logoHref"];
  logoAltText: ResponsiveNavProps["logoAltText"];
  includeLogo: ResponsiveNavProps["includeLogo"];
  interfaceName: ResponsiveNavProps["interfaceName"];
}) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <Link href={logoHref ? logoHref : "#"} legacyBehavior>
      <StyledNavLogosWrapper
        $disabled={!logoHref}
        $hasInterfacesTheme={!!interfacesTheme}
        href={logoHref}
      >
        {includeLogo ? (
          <StyledNavLogo
            $height={logo?.height ?? 24}
            src={logo?.url ?? "/icon-interfaces.svg"}
            alt={logoAltText}
          />
        ) : (
          <IconInterfaces
            width={logo?.height ?? 24}
            height={logo?.height ?? 24}
          />
        )}
        {!includeLogo && interfaceName ? (
          <StyledNavInterfaceName $hasInterfacesTheme={!!interfacesTheme}>
            {interfaceName}
          </StyledNavInterfaceName>
        ) : null}
      </StyledNavLogosWrapper>
    </Link>
  );
}

export const ResponsiveNav = forwardRef<HTMLElement, ShadcnResponsiveNavProps>(
  (props, ref) => {
    const shadcnComponentsEnabled = useShadcnComponentsEnabled();
    if (shadcnComponentsEnabled) {
      return <ShadcnResponsiveNav {...props} />;
    }

    return (
      <LegacyResponsiveNav
        ref={ref}
        items={props.navItems}
        profileItems={props.profileMenuItems}
        interfaceName={props.interfaceName}
        name={props.user.name}
        email={props.user.email}
        avatarUrl={props.user.avatarUrl}
        avatarAltText={props.user.avatarAltText}
        includeLogo={props.includeLogo}
        logoHref={props.logoHref}
        logoAltText={props.logoAltText}
        logo={props.logo}
      />
    );
  }
);

ResponsiveNav.displayName = "ResponsiveNav";
