import BlockWrapper from "block-system/components/BlockWrapper";
import { Embed } from "./Embed";
import { BlockProps } from "../../__shared__/types";
import type { EmbedBlock as EmbedBlockType } from "../types";
import { useProjectTheme } from "lib/hooks/useProjectTheme";

export const EmbedBlock = (props: BlockProps<EmbedBlockType>) => {
  const theme = useProjectTheme();

  return (
    <BlockWrapper
      block={props.block}
      blockId={props.blockId}
      maxWidth={theme.app.width[props.block.config.width]}
    >
      <Embed {...props.block.config} />
    </BlockWrapper>
  );
};
