import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import { blockType } from "./constants";
import { LinkCardSchema } from "./schema";
import { LinksCard, LinksCardBlock } from "./types";

export function copy(block: LinksCardBlock): LinksCardBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: LinksCard["create"] = (
  props?: BlockCreateProps<LinksCardBlock>
) => {
  return LinkCardSchema.parse({
    type: blockType,
    config: {
      ...(props?.initialConfig ?? {}),
      title: props?.initialConfig?.title ?? "New card",
      description:
        props?.initialConfig?.description ?? "Enter your description here.",
      linkText: props?.initialConfig?.linkText ?? "Link",
      url: props?.initialConfig?.url ?? "https://zapier.com",
    },
  });
};

export const map: LinksCard["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...LinkCardSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
