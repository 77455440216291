import "zod-openapi/extend";

import { ChatbotBlock, ChatbotBlockSchema } from "./types";
import { z } from "zod";
import { BlockCreateProps } from "../__shared__/types";
import { Block, BlockType } from "@prisma/client";

export function copy(block: ChatbotBlock) {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export function create(props?: BlockCreateProps<ChatbotBlock>): ChatbotBlock {
  return {
    type: "chatbot-block",
    config: ChatbotBlockSchema.shape.config
      .extend({
        chatbotId: z.string().cuid().optional(),
        isConnectedChatbot: z
          .boolean()
          .default(false)
          .openapi({ effectType: "input" }),
      })
      .parse({
        ...(props?.initialConfig ?? {}),
        displayDisclosure: !!props?.isStaff,
      }),
  };
}

export function map<TBlock extends Block>(block: TBlock): ChatbotBlock {
  if (block.type !== BlockType.Chatbot) {
    throw new Error(`Expected Chatbot block, got: ${block}`);
  }

  const chatbotConfig = ChatbotBlockSchema.shape.config.parse(block.config);

  return {
    type: "chatbot-block",
    config: { ...chatbotConfig, id: block.id },
  };
}
