import { z, ZodSchema } from "zod";

import type { Operation } from "server/auth/types";

import type { Kanban, KanbanBlock } from "../types";

export const allowed: Kanban["allowed"] = (block) => {
  if (!block.config.tableId) return [];

  const operations: Operation[] = [
    {
      type: "query",
      path: "blockKanban.get",
      validator: z.object({
        id: z.literal(block.stateId),
      }),
    },
    {
      type: "query",
      path: "blockKanban.getCardDetails",
      validator: z.object({
        kanbanId: z.literal(block.config.id),
        recordId: z.string(),
      }),
    },
  ];

  if (block.config.canMoveCards) {
    operations.push({
      type: "mutation",
      path: "blockKanban.moveCard",
      validator: z.object({
        id: z.literal(block.stateId),
      }),
    });
  }

  if (
    block.config.canCreateCards ||
    block.config.canUpdateCards ||
    block.config.canDeleteCards
  ) {
    operations.push({
      type: "query",
      path: "blockKanban.getFields",
      validator: z.object({
        kanbanId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
      }),
    });
  }

  if (block.config.canCreateCards || block.config.canUpdateCards) {
    const cardDetailFields = block.config.cardDetailFields;
    if (cardDetailFields !== undefined) {
      operations.push({
        type: "query",
        path: "tableFields.getChoices",
        validator: z.object({
          tableId: z.literal(block.config.tableId),
          fieldId: z
            .union([z.string(), z.number()])
            .refine((f) => cardDetailFields.includes(f), "fieldId"),
        }),
      });
      operations.push({
        type: "query",
        path: "tableRecords.getChoicesByFieldId",
        validator: z.object({
          tableId: z.literal(block.config.tableId),
          fieldId: z
            .union([z.string(), z.number()])
            .refine((f) => cardDetailFields.includes(f), "fieldId"),
        }),
      });
    }
  }

  if (block.config.canUpdateCards || block.config.canDeleteCards) {
    operations.push({
      type: "query",
      path: "blockKanban.getRecord",
      validator: z.object({
        kanbanId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
      }),
    });
  }

  if (block.config.canCreateCards) {
    operations.push({
      type: "mutation",
      path: "blockKanban.createRecord",
      validator: z.object({
        kanbanId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
        body: z.object({
          new_records: z
            .object({
              data: fieldInCardDetails(block.config),
            })
            .array(),
          change_options: z.record(z.unknown()).optional(),
        }),
      }),
    });
  }

  if (block.config.canUpdateCards) {
    operations.push({
      type: "mutation",
      path: "blockKanban.updateRecord",
      validator: z.object({
        kanbanId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
        body: z.object({
          updated_records: z
            .object({
              data: fieldInCardDetails(block.config),
            })
            .array(),
          change_options: z.record(z.unknown()).optional(),
        }),
      }),
    });
  }

  if (block.config.canDeleteCards) {
    operations.push({
      type: "mutation",
      path: "blockKanban.deleteRecord",
      validator: z.object({
        kanbanId: z.literal(block.config.id),
        tableId: z.literal(block.config.tableId),
      }),
    });
  }

  if (block.config.cardDescriptionFieldId) {
    operations.push({
      type: "mutation",
      path: "tableFields.interact",
      validator: z.object({
        tableId: z.literal(block.config.tableId),
        fieldId: z.literal(block.config.cardDescriptionFieldId),
      }),
    });
  }

  return operations;
};

function fieldInCardDetails(config: KanbanBlock["config"]): ZodSchema {
  const detailFields = config.cardDetailFields
    ? config.cardDetailFields.map((f) => `f${f}`)
    : [];
  return z.record(z.unknown()).refine((data) => {
    return Object.keys(data).every((f) => detailFields.includes(f));
  });
}
