import { BlockType } from "@prisma/client";
import { APIFieldLatest } from "server/services/tables/types/tables-latest";

export const blockType = "table-listing-block";
export const blockTypeDbName = BlockType.TableListing;

export const blockTypeToName = {
  [blockType]: blockTypeDbName,
} as const;

export const blockNameToType = {
  [blockTypeDbName]: blockType,
} as const;

export const ALLOWED_TABLE_SORT_BY_FIELD_TYPES: APIFieldLatest["type"][] = [
  "text",
  "number",
  "datetime",
  "decimal",
  "string",
  "email",
];
