import type { Operation } from "server/auth/types";
import type { LoginForm } from "../types";

export const allowed: LoginForm["allowed"] = (block) => {
  if (!block.config.id) return [];

  const operations: Operation[] = [];

  return operations;
};
