import { ProjectAuthType } from "@prisma/client";
import { ManagedConsumerAuthForm } from "./ConsumerAuthForm/ManagedConsumerAuthForm";
import { ProjectPasswordAuthForm } from "./ProjectPasswordAuthForm";
import { StytchConsumerAuthForm } from "./ConsumerAuthForm/StytchConsumerAuthForm";
import { styled } from "lib/theme";
import { RichTextStyleWrapper } from "block-system/components";

export type ActiveProjectAuthType = Exclude<ProjectAuthType, "None">;

type Props = {
  projectId: string;
  stytchOrganizationId: string | null | undefined;
  authType: ActiveProjectAuthType;
  onAuthSuccess: () => void;
  captchaEnabled: boolean;
  isEditing: boolean;
};

export function LoginForm({
  projectId,
  authType,
  stytchOrganizationId,
  onAuthSuccess,
  captchaEnabled,
  isEditing,
}: Props) {
  switch (authType) {
    case ProjectAuthType.Password:
      return (
        <ProjectPasswordAuthForm
          projectId={projectId}
          onAuthSuccess={onAuthSuccess}
          captchaEnabled={captchaEnabled}
          isEditing={isEditing}
        />
      );
    case ProjectAuthType.Stytch: {
      if (!stytchOrganizationId) {
        throw new Error(
          `Interface auth type is "Stytch" but organization is not set`
        );
      }

      return (
        <StytchConsumerAuthForm
          captchaEnabled={captchaEnabled}
          stytchOrganizationId={stytchOrganizationId}
          isEditing={isEditing}
        />
      );
    }

    case ProjectAuthType.Consumer: {
      return (
        <ManagedConsumerAuthForm
          projectId={projectId}
          onAuthSuccess={onAuthSuccess}
          captchaEnabled={captchaEnabled}
          isEditing={isEditing}
        />
      );
    }

    default:
      const _exhaustiveCheck: never = authType;
      return _exhaustiveCheck;
  }
}

export const Content = styled.div`
  max-width: 400px;
  margin: 30px auto;
`;

export const RichText = styled(RichTextStyleWrapper)`
  text-align: center;
`;
