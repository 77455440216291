import BlockWrapper from "block-system/components/BlockWrapper";
import { useTheme } from "lib/theme";
import type { DividerBlock as DividerBlockType } from "../types";
import type { BlockProps } from "../../__shared__/types";
import { Divider } from "./Divider";

export const DividerBlock = (props: BlockProps<DividerBlockType>) => {
  const theme = useTheme();

  const maxWidth = props.block.config?.width
    ? theme.app.width[props.block.config?.width]
    : theme.app.width.medium;

  return (
    <BlockWrapper
      block={props.block}
      blockId={props.blockId}
      maxWidth={maxWidth}
    >
      <Divider {...props.block.config} />
    </BlockWrapper>
  );
};
