import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import { blockType } from "./constants";
import { StripePaymentBlockSchema } from "./schema";
import { StripePayment, StripePaymentBlock } from "./types";
import { blockActionTypeDbToJson } from "server/models/util/blockAction/blockActionTypeDbToJson";
import {
  NavigateActionSchema,
  NotificationActionSchema,
  OpenExternalUrlActionSchema,
} from "block-system/types";
import { FormBlockAction } from "../Form/Form/schema";
import { BlockAction } from "@prisma/client";

export function copy(block: StripePaymentBlock): StripePaymentBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: StripePayment["create"] = (
  props?: BlockCreateProps<StripePaymentBlock>
) => {
  return StripePaymentBlockSchema.parse({
    type: blockType,
    config: props?.initialConfig ?? {},
  });
};

export const map: StripePayment["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...StripePaymentBlockSchema.shape.config.parse(block.config),
      id: block.id,
      triggers: (block.actions ?? []).map(mapFormAction),
    },
  };
};

export function mapFormAction(action: BlockAction): FormBlockAction {
  const actionType = blockActionTypeDbToJson(action.type);
  switch (actionType) {
    case "notification":
      return {
        type: actionType,
        config: {
          ...NotificationActionSchema.shape.config.parse(action.config),
          id: action.id,
        },
      };
    case "navigate":
      return {
        type: actionType,
        config: {
          ...NavigateActionSchema.shape.config.parse(action.config),
          id: action.id,
        },
      };
    case "openExternalUrl":
      return {
        type: actionType,
        config: {
          ...OpenExternalUrlActionSchema.shape.config.parse(action.config),
          id: action.id,
        },
      };
    default:
      const _exhaustiveCheck: never = actionType;
      return _exhaustiveCheck;
  }
}
