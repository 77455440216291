import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import { blockType } from "./constants";
import { LoginFormBlockSchema } from "./schema";
import { LoginForm, LoginFormBlock } from "./types";
import {
  NavigateActionSchema,
  NotificationActionSchema,
  OpenExternalUrlActionSchema,
} from "block-system/types";

export function copy(block: LoginFormBlock): LoginFormBlock {
  return {
    ...block,
    config: {
      ...block.config,
      id: undefined,
    },
  };
}

export const create: LoginForm["create"] = (
  props?: BlockCreateProps<LoginFormBlock>
) => {
  return LoginFormBlockSchema.parse({
    type: blockType,
    config: props?.initialConfig ?? {},
  });
};

export const map: LoginForm["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...LoginFormBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
  };
};
