import { BlockId, ContentBlock, ContentBlocks } from "block-system/types";
import * as immutable from "object-path-immutable";

type BlockPath = string;

// -------------------------------- Utilities ----------------------------------

export function getObjectPath(blockId: BlockId): BlockPath {
  return blockId.replace(/\./g, ".children.");
}

export function getParentIdFromId(blockId: BlockId): BlockId | undefined {
  const lastIndex = blockId.lastIndexOf(".");
  if (lastIndex === -1) return undefined;
  return blockId.substring(0, lastIndex);
}

export function getPositionFromId(blockId: BlockId): number {
  const lastIndex = blockId.lastIndexOf(".");
  const indexStr =
    lastIndex === -1 ? blockId : blockId.substring(lastIndex + 1);
  return parseInt(indexStr, 10);
}

export function joinIds(
  parentId: BlockId | undefined,
  selfId: string
): BlockId {
  return parentId ? parentId + "." + selfId : selfId;
}

// --------------------------- Block manipulators ------------------------------

export function getBlock(
  blocks: ContentBlocks,
  blockId: BlockId
): ContentBlock {
  const path = getObjectPath(blockId);
  return immutable.get(blocks, path);
}

export function getSiblings(
  blocks: ContentBlocks,
  blockId: BlockId
): ContentBlocks | undefined {
  const parentId = getParentIdFromId(blockId);
  if (parentId === undefined) return blocks;
  const parent = getBlock(blocks, parentId);
  if ("children" in parent) return parent.children;
}

export function deleteBlock(
  blocks: ContentBlocks,
  blockId: BlockId
): ContentBlocks {
  const path = getObjectPath(blockId);
  return immutable.del(blocks, path);
}

export function setBlock(
  blocks: ContentBlocks,
  blockId: BlockId,
  block: ContentBlock
): ContentBlocks {
  const path = getObjectPath(blockId);
  return immutable.set(blocks, path, block);
}

export function insertBlock(
  blocks: ContentBlocks,
  blockId: BlockId | undefined,
  block: ContentBlock,
  index?: number
): ContentBlocks {
  const path = blockId ? getObjectPath(blockId) + ".children" : undefined;

  return immutable.insert(blocks, path, block, index);
}

export function moveBlock(
  blocks: ContentBlocks,
  blockId: BlockId,
  to: number
): ContentBlocks {
  const movingBlock = getBlock(blocks, blockId);
  const parentId = getParentIdFromId(blockId);
  // const from = getPositionFromId(blockId);

  const siblings = getSiblings(blocks, blockId);

  if (to < 0 || !siblings || to >= siblings.length)
    throw new Error(
      "The position you are trying to move the block is out of range."
    );

  let newBlocks = blocks;

  // delete block
  newBlocks = deleteBlock(newBlocks, blockId);

  // insert block
  newBlocks = insertBlock(newBlocks, parentId, movingBlock, to);

  return newBlocks;
}
